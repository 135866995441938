import {Entity} from './entity.model';
import {ItemDescription} from './item-description.model';
import {ItemStatus} from './item-status.model';
import {User} from './user.model';
import {Picture} from './picture.model';
import {ItemType} from './item-type.model';
import {Category} from './category.model';
import {Family} from './family.model';
import {Address} from './address.model';
import {LightItem} from './light-item.model';
import {ItemSituation} from './item-situation.model';
import {Person} from './person.model';

export class Item extends Entity {
  itemDescription: ItemDescription;
  description: string;
  label: string;
  comment: string;
  location: string;
  internalCode: string;
  serialNumber: string;
  parent: Item | number;
  buildingDate: string;
  buyingDate: string;
  serviceDate: string;
  expirationDate: string;
  code: string;
  lastUseDate: string;
  maintenanceDate: string;
  checkDate: string;
  status: ItemStatus;
  user: User | number;
  picture: Picture;
  itemType: ItemType;
  category: Category;
  family: Family;
  address: Address;
  situation: ItemSituation;
  active: boolean;

  company: LightItem;
  site: LightItem;
  building: LightItem;
  local: LightItem;
  container: LightItem;

  assignedTo: Person;
  link: LightItem;
}
