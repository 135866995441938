import {Entity} from './entity.model';
import {AccidentType} from './accident-type.model';
import {ItemLocation} from './item-location.model';
import {Person} from './person.model';
import {User} from './user.model';
import {Icon} from './icon.model';

export class Accident extends Entity {
  status: number;
  insurerReference: string;
  stage: number;
  type: AccidentType;
  description: string;
  date: string;
  location: ItemLocation;
  onTheWayToWork: boolean;
  placeDescription: string;
  manager: Person;
  comment: string;
  closed: boolean;
  insurer: Person;
  closedDate: string;
  closedBy: User;
  closedComment: string;
  active: boolean;
  icon: Icon;
}
