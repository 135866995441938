import {ChangeDetectorRef, Component, Inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DashboardService} from '../../../core/api/dashboard.service';
import {Dashboard} from '../../../core/models/dashboard.model';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {of} from 'rxjs';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {Entity} from '../../../core/models/entity.model';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';

@Component({
  selector: 'esomus-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent implements OnInit {
  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;
  @Input() id: number;
  @Input() params: Params;
  @Input() type: string;
  @Input() rightKeyword: string;

  dashboard: Dashboard;
  options: TableOptions;
  headButtons: any;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private dashboardService: DashboardService,
    private cd: ChangeDetectorRef,
    private i18n: I18n,
    private routeNameService: RouteNameService,
  ) { }

  ngOnInit() {
    if (!this.params) {
      this.params = {};
    }

    if (this.type) {
      this.params['type'] = this.type;
    }
    if (this.rightKeyword) {
      this.params['rightKeyword'] = this.rightKeyword;
    }

    this.dashboardService.find(this.id, '', this.params).subscribe((result: Dashboard) => {
      this.dashboard = result;
      this.cd.detectChanges();

      if (result.view) {
        this.doc.querySelector(`#${this.getDashboardID()}`).innerHTML = result.view;
      } else if (result.data) {
        let data = result.data;
        let defs = null;
        let rows = null;
        let buttons = null;
        this.headButtons = null;

        if (data.hasOwnProperty('columnDefs')) {
          defs = data['columnDefs'];
        }
        if (data.hasOwnProperty('rows')) {
          rows = data['rows'];
        }
        if (data.hasOwnProperty('buttons')) {
          buttons = data['buttons'];
        }
        if (data.hasOwnProperty('headButtons')) {
          this.headButtons = data['headButtons'];
        }

        this.options = {
          columnDefs: [],
          findDataCb: () => of(rows),
          actions: null
        };

        for (let key in defs) {
          let def = defs[key];
          let type = null;
          switch (def.type) {
            case 'TEXTE':
              break;
            case 'BOOL':
              type = ColumnType.CHECKBOX;
              break;
            case 'NOMBRE':
              break;
          }
          this.options.columnDefs.push({
            name: this.i18n({value: def.label, id: def.label}), type,
            prop: key
          });
        }

        if (buttons && buttons.length > 0) {
          this.options.actions = {
            custom: []
          };

          for (let btn of buttons) {
            this.options.actions.custom.push({
              icon: btn['icons'],
              label: null,
              customCb: (entity: Entity) => {
                let params = {};
                for (let key in btn['parameters']) {
                  let param = btn['parameters'][key];
                  params[key] = entity[param];
                }
                this.routeNameService.goTo(btn['routeName'], params);
              }
            });
          }
        }

        if (this.headButtons && this.headButtons.length > 0) {
          this.options.headTemplate = this.headerTable;
        }
      }

      this.cd.detectChanges();
    });
  }

  getDashboardID() {
    return `dashboard-${this.id}`;
  }

  headerButtonAction(opt: any) {
    if (opt.hasOwnProperty('externalURI') && opt['externalURI']) {
      window.open(opt['routeName']);
    } else {
      this.routeNameService.goTo(opt['routeName'], opt['parameters']);
    }
  }
}
