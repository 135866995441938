<div class="toolbar-button" fxLayout="row" fxLayoutAlign="start center" (esomusClickOutside)="onClickOutside()">
  <button class="icon-button" type="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen">
    <mat-icon class="icon">notifications_active</mat-icon>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="card">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">
          <div class="name">Notifications</div>
          <div class="extra">You have {{ notifications.length }} new notifications.</div>
        </div>
        <button type="button" mat-icon-button>
          <mat-icon class="icon">settings</mat-icon>
        </button>
      </div>
      <esomus-scrollbar class="content">
        <div *ngFor="let notification of notifications; let last = last" fxLayout="column" [@listFade]="true">
          <div class="notification" (click)="markAsRead(notification)" [ngClass]="notification.colorClass"
            [class.read]="notification.read" fxLayout="row" fxLayoutAlign="start center" matRipple>
            <mat-icon class="icon">{{ notification.icon }}</mat-icon>
            <div class="title" fxLayout="column">
              <div class="name">{{ notification.name }}</div>
              <div class="time">{{ notification.time }}</div>
            </div>
            <span fxFlex></span>
            <button (click)="dismiss(notification, $event)" type="button" mat-icon-button>
              <mat-icon class="close">close</mat-icon>
            </button>
          </div>
          <div *ngIf="!last" class="divider"></div>
        </div>
        <div class="no-notifications" *ngIf="notifications.length === 0" [@listFade]="true">
          All fine! No notifications right now.
        </div>
      </esomus-scrollbar>
      <div class="footer" fxLayout="row" fxLayoutAlign="center center">
        <div class="action">Mark all as read</div>
      </div>
    </div>
  </div>
</div>
