import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {DocumentType} from '../models/document-type.model';

const crudPath: CRUDPath = {
  many: `/document_types/`,
  single: (id: number) => `/document_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService extends CRUD<DocumentType>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
