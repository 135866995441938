import {Component, Input, OnInit} from '@angular/core';
import {Item} from '../../../core/models/item.model';
import {RouteNameService} from '../../../core/services/route-name.service';

@Component({
  selector: 'esomus-equipment-panel',
  templateUrl: './equipment-panel.component.html',
  styleUrls: ['./equipment-panel.component.sass']
})
export class EquipmentPanelComponent implements OnInit {

  @Input() equipment: Item;
  @Input() panelDisabled: boolean;
  @Input() expanded = true;
  @Input() fromEquipment = false;

  label: string;
  isEquipment: boolean;
  isContainer: boolean;
  isVehicle: boolean;

  constructor(
    private routeNameService: RouteNameService,
  ) {
  }

  ngOnInit() {
    this.isContainer = this.equipment.itemType.value === 6;
    this.isVehicle = this.equipment.itemType.value === 7;
    this.isEquipment = this.equipment.itemType.value === 8;

    if (this.isContainer) {
      this.label = 'Conteneur';
    } else if (this.isVehicle) {
      this.label = 'Véhicule';
    } else {
      this.label = 'Equipement';
    }
  }

  editEquipment() {
    this.routeNameService.goTo('equipment_id', {id: this.equipment.id});
  }

  getScreenID() {
    switch (true) {
      case this.isEquipment:
        return 7;
      case this.isContainer:
        return 5;
      case this.isVehicle:
        return 6;
    }
    return null;
  }

  getContainerURL() {
    return this.routeNameService.path('equipment_view', {id: this.equipment.container.id});
  }

  getBuildingURL() {
    return this.equipment.building ? this.routeNameService.path('building_view', {id: this.equipment.building.id}) : null;
  }

  getLocalURL() {
    return this.equipment.local ? this.routeNameService.path('local_view', {id: this.equipment.local.id}) : null;
  }

  isInContainer() {
    return this.equipment.container && [6, 7].indexOf(this.equipment.container.itemType.value) !== -1;
  }

  getSituationColor() {
    switch (this.equipment.situation.value) {
      case 1:
        return 'success';
      case 2:
        return 'primary';
      case 3:
        return 'success';
      case 4:
        return 'success';
      case 5:
        return 'danger';
    }
    return 'danger';
  }

  getStatusColor() {
    if (this.equipment.status === null) {
      return null;
    }

    switch (this.equipment.status.value) {
      case 0:
        return 'success';
      default:
        return 'danger';
    }
  }
}
