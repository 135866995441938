import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {environment} from '../../../environments/environment';
import {ItemData} from '../models/item-data.model';
import {Entity} from '../models/entity.model';
import {Data} from '../models/data.model';

const crudPath: CRUDPath = {
  many: `/data/`,
  single: (id: number) => `/data`
};

@Injectable({
  providedIn: 'root'
})
export class ItemDataService extends CRUD<ItemData>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  putData(entity: Entity, itemID: number, formID: number) {
    return this.put(entity, `/items/${itemID}/forms/${formID}`);
  }

  getData(itemID: number, formID: number) {
    return this.http.get<Array<Data>>(`${environment.api.url}/items/${itemID}/forms/${formID}${this.crudPath.many}`);
  }
}
