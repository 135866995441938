import {Entity} from './entity.model';
import {Item} from './item.model';
import {Person} from './person.model';
import {Role} from './role.model';

export class Contact extends Entity {
  mainId: number;
  origin: number;
  role: Role;
  contact: Person;
  item: Item;
}
