<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true" *ngIf="building && site">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Batîment : Création
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-line-data flex="100" fxFlex="100" boldValue="Site"
                                [textValue]="site.label"></esomus-line-data>
              <esomus-ng-select *ngIf="familyOptions" [options]="familyOptions"
                                [control]="entityForm.get('family')"
                                i18n-label="@@family" label="Famille" fxFlex="100"
                                (valueChange)="updateCategory()"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="categoryOptions" [options]="categoryOptions"
                                [control]="entityForm.get('category')"
                                i18n-label="@@category" label="Catégorie" fxFlex="100"
                                (valueChange)="updateItemCategory()"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="itemDescriptionOptions" [options]="itemDescriptionOptions"
                                [control]="entityForm.get('itemDescription')"
                                i18n-label="@@itemDescription" label="Fiche" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                            i18n-label="@@name" label="Nom" flex="100"
                            fxFlex="100"></esomus-input>
              <div class="btn-group-right" fxFlex="100">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
