import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {AccidentService} from '../../../core/api/accident.service';
import {Accident} from '../../../core/models/accident.model';
import {AccidentHelper} from '../../../core/services/accident-helper.service';
import {of, Subject} from 'rxjs';
import {AccidentTypeService} from '../../../core/api/accident-type.service';
import {PersonService} from '../../../core/api/person.service';
import {ItemLocation} from '../../../core/models/item-location.model';
import {takeUntil} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material';

@Component({
  selector: 'esomus-accident',
  templateUrl: './accident.component.html',
  styleUrls: ['./accident.component.sass']
})
export class AccidentComponent implements OnInit, OnDestroy {
  accident: Accident;

  entityForm: FormGroup;
  fieldType = FieldType;
  formOptions: EntityOption;

  // statusOptions: EntityOption;
  stageOptions: EntityOption;
  typeOptions: EntityOption;
  managerOptions: EntityOption;
  insurerOptions: EntityOption;

  onTheWayToWork: boolean;

  companyEmitter: EventEmitter<any>;
  private destroyed$: Subject<void>;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentService: AccidentService,
    private accidentTypeService: AccidentTypeService,
    private personService: PersonService,
  ) {
    this.destroyed$ = new Subject<void>();
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      // status: [null, [Validators.required]],
      insurerReference: [null],
      stage: [null, [Validators.required]],
      description: [null, [Validators.required]],
      placeDescription: [null],
      comment: [null],
      insurer: [null],
    });

    // this.statusOptions = {get: () => of(AccidentHelper.getAccidentStatusOptions()), propName: 'label'};
    this.stageOptions = {get: () => of(AccidentHelper.getAccidentStageOptions()), propName: 'label'};
    this.insurerOptions = {get: () => this.personService.findCompanies({type: 'accident'}), propName: 'fullName'};

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAccident(id);
    } else {
      this.accident = new Accident();
      this.accident.status = 0;
      this.accident.location = new ItemLocation();
      this.accident.location.company = null;
      this.accident.location.site = null;
      this.accident.location.building = null;
      this.accident.location.local = null;
      this.onTheWayToWork = false;

      this.entityForm.addControl('location', this.fb.group({}));
      this.entityForm.addControl('onTheWayToWork', this.fb.control(null));
      this.entityForm.addControl('date', this.fb.control(null, [Validators.required]));
      this.entityForm.addControl('type', this.fb.control(null, [Validators.required]));
      this.typeOptions = {get: () => this.accidentTypeService.findAll('', {type: 'accident'}), propName: 'description'};

      this.companyEmitter = new EventEmitter<any>();
      this.companyEmitter.pipe(takeUntil(this.destroyed$)).subscribe((value: any) => {
        if (this.entityForm.contains('manager')) {
          this.entityForm.removeControl('manager');
          this.managerOptions = null;
          this.cd.detectChanges();
        }

        if (value === null) {
          return;
        }

        this._manager(value);
      });

      this.cd.detectChanges();
    }
  }

  private _manager(value: any) {
    this.entityForm.addControl('manager', this.fb.control(this.accident.manager ? this.accident.manager.id : null, [Validators.required]));
    this.managerOptions = {
      get: () => this.personService.getCompanyPersons(value, {type: 'accident'}),
      propName: 'fullName', autoSelect: true
    };
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private _getAccident(id: number) {
    this.accidentService.find(id).subscribe((result: Accident) => {
      this.accident = result;

      this.onTheWayToWork = this.accident.onTheWayToWork;
      this._manager(this.accident.location.company.id);

      FormHelper.initValues(this.accident, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let types = {};

    if (!this.accident.id) {
      types['date'] = {type: FieldType.DATE};
      types['location'] = {type: FieldType.GROUP};
    }

    const entity = FormHelper.buildEntity(this.accident, this.entityForm, types) as Accident;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.accident.id) ? this.accidentService.put(entity) : this.accidentService.post(entity),
      (result: Accident) => {
        this.toastrService.open((this.accident.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('accidents');
      }
    );
  }

  getAccidentDeleteURL() {
    return this.accidentService.delete(this.accident.id);
  }

  getAccidentSuccessURL() {
    return this.routeNameService.path('accidents');
  }

  getAccidentStatusLabel() {
    return AccidentHelper.getAccidentStatusLabel(this.accident.status);
  }

  changeOnTheWayToWork(event: MatCheckboxChange) {
    this.onTheWayToWork = event.checked;
    this.cd.detectChanges();
  }
}
