import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Entity} from '../../../../core/models/entity.model';

@Component({
  selector: 'esomus-line-data',
  templateUrl: './line-data.component.html',
  styleUrls: ['./line-data.component.scss']
})
export class LineDataComponent implements OnInit {

  @Input() boldFlex: string;
  @Input() textFlex: string;
  @Input() flex: 'initial';

  @Input() skipBold: boolean;
  @Input() skipText: boolean;

  @Input() boldValue: string;
  @Input() textValue: string;
  @Input() boolValue: boolean;
  @Input() textURL: string;
  @Input() textType: string;
  @Input() textClass: string;

  @Input() emptyText: boolean;

  @Input() alignment: string;

  @Input() items: Array<Entity>;
  @Input() prop: string;

  breakpoint: string;

  constructor() {
  }

  ngOnInit() {
    if (!this.textClass) {
        this.textClass = '';
    }
    this.textClass += ' nl2br';
    if (!this.boldFlex) {
      this.boldFlex = this.skipText ? '100' : '50';
    }
    if (!this.textFlex) {
      this.textFlex = this.skipBold ? '100' : '50';
    }

    this.breakpoint = (this.skipBold || this.skipText) ? '100' : '50';

    if (this.textValue && this.textType) {
      switch (this.textType) {
        case 'date':
          this.textValue = moment(this.textValue).format('DD/MM/YYYY');
          break;
        case 'calendar':
          this.textValue = moment(this.textValue).format('DD/MM/YYYY');
          break;
      }
    }

    if (!this.textValue) {
      if (this.boolValue !== null && this.boolValue !== undefined) {
        this.textValue = this.boolValue ? 'Oui' : 'Non';
      } else {
        this.emptyText = true;
        switch (this.textType) {
          case 'date':
          case 'calendar':
            this.textValue = '__/__/____';
            break;
          default:
            this.textValue = '---';
            break;
        }
      }
    } else {
      this.emptyText = false;
    }
  }
}
