import {Entity} from './entity.model';
import {AccidentType} from './accident-type.model';
import {ItemLocation} from './item-location.model';
import {Person} from './person.model';
import {User} from './user.model';
import {Icon} from './icon.model';
import {Role} from './role.model';
import {Effect} from './effect.model';
import {Zone} from './zone.model';
import {Impact} from './impact.model';

export class AccidentPerson extends Entity {
  active: boolean;
  witness: boolean;
  intern: boolean;
  person: Person;
  role: Role;
  days: number;
  comment: string;
  effect: Effect;
  zones: Array<Zone>;
  impact: Impact;
}
