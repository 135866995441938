import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {ActionAnomaly} from '../models/action-anomaly.model';
import {Params} from '@angular/router';

const crudPath: CRUDPath = {
  many: `/actions_anomalies/`,
  single: (id: number) => `/actions_anomalies/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ActionAnomalyService extends CRUD<ActionAnomaly>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllByItem(itemID: number, params: Params = {}) {
    return this.findAll(`/items/${itemID}`, params);
  }

  findAllByAnomaly(anomalyID: number, params: Params = {}) {
    return this.findAll(`/anomalies/${anomalyID}`, params);
  }

  findAllByAccident(accidentID: number, params: Params = {}) {
    return this.findAll(`/accidents/${accidentID}`, params);
  }

  findAllByTask(taskID: number, params: Params = {}) {
    return this.findAll(`/tasks/${taskID}`, params);
  }
}
