import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageBoxComponent} from './components/image-box/image-box.component';
import {MaterialModule} from '../material.module';
import {ItemBreadcrumbComponent} from './components/item-breadcrumb/item-breadcrumb.component';
import {BreadcrumbsModule} from '../elastic-ui/breadcrumbs/breadcrumbs.module';
import {LineDataComponent} from './components/line-data/line-data.component';
import {RouterModule} from '@angular/router';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {LineMembershipComponent} from './components/line-membership/line-membership.component';
import {PipeModule} from '../pipes/pipe.module';
import {InfoBoxComponent} from './components/info-box/info-box.component';
import {DeleteButtonComponent} from './components/delete-button/delete-button.component';
import {ImgComponent} from './components/img/img.component';
import {ImgCellComponent} from './components/img-cell/img-cell.component';
import {EnableButtonComponent} from './components/enable-button/enable-button.component';

@NgModule({
  declarations: [ImageBoxComponent, ItemBreadcrumbComponent, LineDataComponent, BackButtonComponent, LineMembershipComponent, InfoBoxComponent, DeleteButtonComponent, ImgComponent, ImgCellComponent, EnableButtonComponent],
  exports: [
    ImageBoxComponent,
    ItemBreadcrumbComponent,
    LineDataComponent,
    BackButtonComponent,
    LineMembershipComponent,
    InfoBoxComponent,
    DeleteButtonComponent,
    EnableButtonComponent,
    ImgComponent,
    ImgCellComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    BreadcrumbsModule,
    RouterModule,
    PipeModule,
  ]
})
export class EsomusUiModule {
}
