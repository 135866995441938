<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Accidents / Incidents
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table [options]="accidentsTable"></esomus-table>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Statistiques
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-dashboard-item id="0" type="rightKeyword" rightKeyword="ACCIDENT.STATISTIQUE"
      [params]="accidentDashboardParams"
    ></esomus-dashboard-item>
  </mat-expansion-panel>
</mat-accordion>
