import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../core/api/dashboard.service';
import {Dashboard} from '../../../core/models/dashboard.model';

@Component({
  selector: 'esomus-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.sass']
})
export class DashboardViewComponent implements OnInit {

  dashboards: Array<Dashboard>;

  constructor(
    private dashboardService: DashboardService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.dashboardService.findAllDashboards().subscribe((result: Array<Dashboard>) => {
      this.dashboards = result;
      this.cd.detectChanges();
    });
  }
}
