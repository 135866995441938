<mat-accordion multi="true" *ngIf="local">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Local : {{local.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-line-membership fxFlex="100" [item]="local" [showAll]="true"></esomus-line-membership>
        <div fxLayoutAlign="center center">
          <button mat-flat-button (click)="editLocal()" color="success">
            <mat-icon>edit</mat-icon> Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="4" [itemID]="local.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="local.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50" textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="local.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data flex="50" fxFlex="100" boldValue="Libellé"
                              [textValue]="local.description"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Commentaire"
                              [textValue]="local.comment"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Catégorie"
                              [textValue]="local.category.label"
                              [textURL]="getCategoryURL()"></esomus-line-data>
            <esomus-line-data flex="50" fxFlex="100" boldValue="Fiche"
                              [textValue]="local.itemDescription.label"
                              [textURL]="getItemDescriptionURL()"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-equipment-table [parentID]="local.id" [expanded]="true" label="Equipements"></esomus-equipment-table>
  <mat-expansion-panel *ngIf="local.category.form ||local.itemDescription.form ">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="local.category.form" [fetchDataURL]="getFetchDataURL(local.category.form.id)"></esomus-form-display>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="local.itemDescription.form" [fetchDataURL]="getFetchDataURL(local.itemDescription.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [parent]="local" parentType="item"></esomus-document-table>
  <esomus-contact-table [parent]="local" [parentType]="'item'"></esomus-contact-table>
</mat-accordion>
