import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Accident} from '../models/accident.model';

const crudPath: CRUDPath = {
  many: `/accidents/`,
  single: (id: number) => `/accidents/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AccidentService extends CRUD<Accident> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
