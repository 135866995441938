<mat-accordion multi="true" *ngIf="equipment">
  <esomus-equipment-panel [equipment]="equipment"></esomus-equipment-panel>
  <esomus-action-anomaly-table [expanded]="isEquipment"
                               [actionAddRouteName]="'equipment_action_add'"
                               [equipmentID]="equipment.id"></esomus-action-anomaly-table>
  <esomus-equipment-table *ngIf="isContainer || isVehicle" [parentID]="equipment.id"
                          [expanded]="true" label="Contenu"></esomus-equipment-table>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information spécifique
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="Famille"
                              [textValue]="equipment.family.label"
                              [textURL]="getFamilyURL()"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Catégorie"
                              [textValue]="equipment.category.label"
                              [textURL]="getCategoryURL()"></esomus-line-data>
            <esomus-line-data *ngIf="isEquipment" fxFlex="100" boldValue="Durée d'utilisation"
                              [textValue]="equipment.itemDescription.duration !== null ? (equipment.itemDescription.duration.toString() + ' mois') : null"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" [fxFlex]="100/3">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="N° de série"
                              [textValue]="equipment.serialNumber"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Code interne"
                              [textValue]="equipment.internalCode"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" [fxFlex]="100/3">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="Date de construction"
                              [textValue]="equipment.buildingDate"
                              textType="date"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Date d'achat"
                              [textValue]="equipment.buyingDate" textType="date"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Mise en service"
                              [textValue]="equipment.serviceDate"
                              textType="date"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Denier contrôle"
                              [textValue]="equipment.checkDate" textType="date"></esomus-line-data>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
          <esomus-line-data fxFlex="100" boldValue="Fiche"
                            [textValue]="equipment.itemDescription.label"
                            [textURL]="getItemDescriptionURL()"></esomus-line-data>
        </div>
        <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
          <esomus-line-data fxFlex="100" boldValue="Lié à"
                            [textValue]="equipment.link ? equipment.link.name : null"
                            [textURL]="getLinkURL()"></esomus-line-data>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="equipment.category.form || equipment.itemDescription.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information personalisée
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="equipment.category.form"
                               [fetchDataURL]="getFetchDataURL(equipment.category.form.id)"></esomus-form-display>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="equipment.itemDescription.form"
                               [fetchDataURL]="getFetchDataURL(equipment.itemDescription.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-obligation-item-description-panel
    [itemDescription]="equipment.itemDescription"></esomus-obligation-item-description-panel>
  <esomus-equipment-table [expanded]="false" [link]="equipment"
                          addLabel="Lier un équipement" [parentID]="equipment.id" parent="link"
                          label="Equipements liés"></esomus-equipment-table>
  <esomus-document-table [parent]="equipment" parentType="item"></esomus-document-table>
  <esomus-contact-table [parent]="equipment" [parentType]="'item'"></esomus-contact-table>
</mat-accordion>
