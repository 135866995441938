import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute} from '@angular/router';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {ItemDescription} from '../../../core/models/item-description.model';
import {ItemDescriptionDataService} from '../../../core/api/item-description-data.service';

@Component({
  selector: 'esomus-item-description-view',
  templateUrl: './item-description-view.component.html',
  styleUrls: ['./item-description-view.component.sass']
})
export class ItemDescriptionViewComponent implements OnInit {

  itemDescription: ItemDescription;
  isEquipment: boolean;
  isBuilding: boolean;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private itemDescriptionService: ItemDescriptionService,
    private cd: ChangeDetectorRef,
    private itemDescriptionDataService: ItemDescriptionDataService,
  ) {
  }

  ngOnInit() {
    const itemDescriptionID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getItemDescription(itemDescriptionID);
  }

  private _getItemDescription(id: number) {
    this.itemDescriptionService.find(id)
      .subscribe((itemDescription: ItemDescription) => {
        this.itemDescription = itemDescription;
        this.isEquipment = [6, 7, 8].indexOf(this.itemDescription.itemType.value) !== -1;
        this.isBuilding = this.itemDescription.itemType.value === 3;

        this.cd.detectChanges();
      });
  }

  getFamilyURL() {
    return this.routeNameService.path('family_view', {id: this.itemDescription.family.id});
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', {familyID: this.itemDescription.family.id, id: this.itemDescription.category.id});
  }

  editItemDescription() {
    return this.routeNameService.goTo('item_description_id', {id: this.itemDescription.id});
  }

  getFetchDataURL(formID: number) {
    return this.itemDescriptionDataService.getData(this.itemDescription.id, formID);
  }

  getScreenID() {
    switch (this.itemDescription.itemType.value) {
      case 1:
        return 11;
      case 2:
        return 12;
      case 3:
        return 13;
      case 4:
        return 14;
      case 6:
        return 15;
      case 7:
        return 16;
      case 8:
        return 17;
    }
  }
}
