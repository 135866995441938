<mat-accordion multi="true" *ngIf="accident">
  <esomus-accident-panel [accident]="accident" [expanded]="true"></esomus-accident-panel>
  <mat-expansion-panel *ngIf="accident.type.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="accident.type.form"
                               [fetchDataURL]="getFetchDataURL(accident.type.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [parent]="accident" parentType="accident"></esomus-document-table>
  <esomus-accident-person-panel [expanded]="true"
                                [accident]="accident"></esomus-accident-person-panel>
  <esomus-action-anomaly-table *ngIf="false" [expanded]="true" [showAnomalyBtn]="false"
                               label="Actions correctrices"
                               [actionAddRouteName]="'accident_action_add'"
                               [accidentID]="accident.id"></esomus-action-anomaly-table>
  <esomus-document-table [parent]="accident" parentType="closedAccident"
                         label="Documents de clôture"></esomus-document-table>
</mat-accordion>
