import {Component, OnInit} from '@angular/core';
import {Entity} from '../../../core/models/entity.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from 'src/app/core/services/route-name.service';
import {TableOptions} from 'src/app/shared/form-builder/components/table/table.component';
import {ItemDescriptionService} from '../../../core/api/item-description.service';


@Component({
  selector: 'esomus-item-description-list',
  templateUrl: './item-description-list.component.html',
  styleUrls: ['./item-description-list.component.sass']
})
export class ItemDescriptionListComponent implements OnInit {

  itemDescriptionTable: TableOptions;

  constructor(
    private i18n: I18n,
    private routeName: RouteNameService,
    private itemDescriptionService: ItemDescriptionService
  ) {

    this.itemDescriptionTable = {
      columnDefs: [
        {
          name: this.i18n({ value: 'Libellé', id: 'label' }),
          prop: 'label',
        }
      ],
      findDataCb: () => this.itemDescriptionService.findAll(),
      actions: {
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter une fiche', id: 'btnAddItemDescription' }),
            createCb: () => this.routeName.goTo('item_description_add')
          },
        ],
        readCb: (entity: Entity) => this.routeName.goTo('item_description_view', { id: entity.id }),
        deleteCb: (entity: Entity) => this.itemDescriptionService.delete(entity.id)
      }
    };
  }

  ngOnInit() {
  }
}
