import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Entity} from '../../../core/models/entity.model';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {CRUDType} from '../../../core/api/crud';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  submitSubject: Subject<FormEvent>;

  constructor(
    private toastrService: ToastrService
  ) {
    this.submitSubject = new Subject<FormEvent>();
  }

  toastrSuccess(crudType: CRUDType) {
    if (crudType === CRUDType.CREATE) {
      this.toastrService.open(ToastrMessageType.CREATE);
    } else {
      this.toastrService.open(ToastrMessageType.UPDATE);
    }
  }

  catchForm(formID: string, type?: FormEventType): Observable<FormEvent> {
    return this.submitSubject.asObservable()
      .pipe(
        filter((e: FormEvent) => {
          if (type && e.eventType !== type) {
            return false;
          }
          return formID === e.formID;
        })
      );
  }

  getFormData(formID: string) {
    this._sendFormEvent(formID, FormEventType.REQUEST_DATA);
  }

  sendFormData(formID: string, data?: Entity) {
    this._sendFormEvent(formID, FormEventType.RESPONSE_DATA, data);
  }

  sendFormError(formID: string, error: any) {
    this._sendFormEvent(formID, FormEventType.RESPONSE_ERROR, error);
  }

  createForm(formID: string) {
    this._sendFormEvent(formID, FormEventType.CREATE);
  }

  updateForm(formID: string, data: Entity) {
    this._sendFormEvent(formID, FormEventType.UPDATE, data);
  }

  deleteForm(formID: string, id: number) {
    this._sendFormEvent(formID, FormEventType.DELETE, id);
  }

  private _sendFormEvent(formID: string, formEvent: FormEventType, data?: any) {
    this.submitSubject.next({
      formID,
      eventType: formEvent,
      data
    });
  }
}

export class FormEvent {
  formID: string;
  eventType: FormEventType;
  data?: any;
}

export enum FormEventType {
  REQUEST_FIELD_DATA,
  RESPONSE_FIELD_DATA,
  REQUEST_DATA,
  RESPONSE_DATA,
  SUBMIT_DATA,
  CREATE,
  UPDATE,
  DELETE,
  RESPONSE_ERROR,
  //
  // == FORM & FIELD STATUS
  TOGGLE_FORM_VISIBILITY,
  TOGGLE_FIELD_VISIBILITY
}
