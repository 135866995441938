import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Task} from '../models/task.model';
import {TaskAssignment} from '../models/task-assignment.model';

const crudPath: CRUDPath = {
  many: `/task_assignments/`,
  single: (id: number) => `/task_assignments/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class TaskAssignmentService extends CRUD<TaskAssignment>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllByTask(taskID: number) {
    return this.findAll(`/tasks/${taskID}`);
  }

  postFromParent(task: Task, entity: TaskAssignment) {
    return this.post(entity, `/tasks/${task.id}`);
  }
}
