import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Accident} from '../models/accident.model';
import {AccidentPerson} from '../models/accident-person.model';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';

const crudPath: CRUDPath = {
  many: `/persons/`,
  single: (id: number) => `/persons/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AccidentPersonService extends CRUD<AccidentPerson> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllAccidentPersons(accidentID: number, params?: Params): Observable<Array<AccidentPerson>> {
    return super.findAll(`/accidents/${accidentID}`, params);
  }

  findAccidentPerson(accidentID: number, id: number, params?: Params): Observable<AccidentPerson> {
    return super.find(id, `/accidents/${accidentID}`, params);
  }

  postAccidentPerson(accidentID: number, entity: AccidentPerson): Observable<AccidentPerson> {
    return super.post(entity, `/accidents/${accidentID}`);
  }

  putAccidentPerson(accidentID: number, entity: AccidentPerson): Observable<AccidentPerson> {
    return super.put(entity, `/accidents/${accidentID}`);
  }

  deleteAccidentPerson(accidentID: number, id: number): Observable<void> {
    return super.delete(id, `/accidents/${accidentID}`);
  }
}
