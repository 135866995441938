import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'esomus-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.sass']
})
export class SearchViewComponent implements OnInit {

  searchText: string;
  params: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      this.searchText = this.activatedRoute.snapshot.paramMap.get('searchText');
      this.params = {
        searchText: this.searchText
      };
    });
  }
}
