import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {UserLogged} from '../models/user-logged.model';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {RouteNameService} from '../services/route-name.service';
import {ParameterService} from './parameter.service';

const apiUrl = environment.api.url;
const requestPath = {
  login: `${apiUrl}/login`
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: UserLogged = null;
  private token: string = null;

  constructor(
    private http: HttpWrapperService,
    private router: RouteNameService,
    private jwtService: JwtHelperService,
    private paramService: ParameterService
  ) {
  }

  getToken() {
    if (this.token === null) {
      this.token = localStorage.getItem('token');
    }
    if (this.jwtService.isTokenExpired(this.token)) {
      this.logout();
      return null;
    }
    return this.token;
  }

  getUser() {
    if (this.user) {
      return this.user;
    }
    const token = this.getToken();
    console.log(token);
    if (token !== null && this.user === null) {
      this.user = this.jwtService.decodeToken(token);
    }
    return this.user;
  }

  login(username: string, password: string): Observable<UserLogged> {
    const body = {
      username,
      password
    };
    return this.http.post<Token>(requestPath.login, body)
      .pipe(
        map<Token, UserLogged>((token: Token) => {
          localStorage.setItem('token', token.token);
          this.token = token.token;
          return this.jwtService.decodeToken(this.token) as UserLogged;
        }),
        tap((user: UserLogged) => {
          this.user = user;
        })
      );
  }

  logout(withRedirection?: boolean) {
    this.token = null;
    this.user = null;
    // this.paramService.clear();
    localStorage.removeItem('token');
    if (withRedirection) {
      this.router.goTo('login');
    }
  }
}

export interface Token {
  token: string;
}
