import {Component, OnInit} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {FamilyService} from '../../../core/api/family.service';
import {Observable} from 'rxjs';
import {Family} from '../../../core/models/family.model';

@Component({
  selector: 'esomus-family-list',
  templateUrl: './family-list.component.html',
  styleUrls: ['./family-list.component.sass']
})
export class FamilyListComponent implements OnInit {

  families$: Observable<Family[]>;

  constructor(
    private i18n: I18n,
    private routeName: RouteNameService,
    private familyService: FamilyService
  ) {

  }

  ngOnInit() {
    this._getFamilies();
  }

  private _getFamilies() {
    this.families$ = this.familyService.findAll();
  }

  createFamily() {
    this.routeName.goTo('family_add');
  }

}
