import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {AnomalyType} from '../models/anomaly-type.model';

const crudPath: CRUDPath = {
  many: `/anomaly_types/`,
  single: (id: number) => `/anomaly_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AnomalyTypeService extends CRUD<AnomalyType>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
