<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <esomus-dashboard-print-selector screenID="21"></esomus-dashboard-print-selector>
    </div>
    <esomus-table [searchReceiver]="search" [options]="taskTable"></esomus-table>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #headerTable>
  <mat-checkbox (change)="definitionChange($event)">Voir canevas</mat-checkbox>
</ng-template>
