import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ItemDescription} from '../../../core/models/item-description.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {ItemTypeService} from '../../../core/api/item-type.service';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {CategoryService} from '../../../core/api/category.service';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {FamilyService} from '../../../core/api/family.service';
import {ItemType} from '../../../core/models/item-type.model';
import {of} from 'rxjs';
import {RouteNameService} from '../../../core/services/route-name.service';
import {FormService} from '../../../core/api/form.service';
import {Category} from '../../../core/models/category.model';
import {Family} from '../../../core/models/family.model';
import {FormsMap, FormsMappingMap} from '../../../forms/components/form-component/form-component.component';
import {ItemDescriptionDataService} from '../../../core/api/item-description-data.service';
import {Entity} from '../../../core/models/entity.model';
import {ObligationService} from '../../../core/api/obligation.service';

@Component({
  selector: 'esomus-item-description',
  templateUrl: './item-description.component.html',
  styleUrls: ['./item-description.component.sass']
})
export class ItemDescriptionComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;
  itemDescription: ItemDescription;
  isEquipment: boolean;
  isBuilding: boolean;
  itemTypes: Array<ItemType>;

  forms: FormsMap;
  formsMapping: FormsMappingMap;

  formOptions: EntityOption;
  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  itemTypeOptions: EntityOption;
  obligationOptions: EntityOption;

  category: Category;

  formsValidation: boolean[];
  nbForms: number;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private itemDescriptionService: ItemDescriptionService,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private formService: FormService,
    private itemDescriptionDataService: ItemDescriptionDataService,
    private obligationService: ObligationService
  ) {
  }

  ngOnInit() {
    this.formOptions = {get: () => this.formService.findAll(), propName: 'name'};

    this.entityForm = this.fb.group({
      label: [null, [Validators.required]],
      comment: [null],
      'picture.upload': [],
      code: [null],
      type: [null],
      obligationRisk: [null],
      obligationEquipments: [null],
    });

    const familyID = parseInt(this.activatedRoute.snapshot.queryParamMap.get('familyID'), 10);
    const categoryID = parseInt(this.activatedRoute.snapshot.queryParamMap.get('categoryID'), 10);
    const itemDescriptionID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(familyID) && !isNaN(categoryID)) {
      this._generateEmptyItemDescription();
      this._getCategory(familyID, categoryID);
    } else if (!isNaN(itemDescriptionID)) {
      this._getItemDescription(itemDescriptionID);
    } else {
      this.itemTypeService.findAll().subscribe((result: Array<ItemType>) => {
        this.itemTypes = result;

        this.itemTypeOptions = {get: () => of(this.itemTypes), propName: 'label'};
        this.entityForm.addControl('itemType', this.fb.control(null));

        this.cd.detectChanges();
      });

      this._generateEmptyItemDescription();
    }
  }

  private _generateEmptyItemDescription() {
    this.itemDescription = new ItemDescription();
    this.itemDescription.ceCertification = false;
    this.itemDescription.controllable = false;
    this.itemDescription.model = null;
    this.itemDescription.duration = null;

    this.entityForm.addControl('form', this.fb.control(null));
    this._generateObligations();
  }

  private _generateObligations() {
    if (!this.itemDescription.obligations) {
      this.itemDescription.obligations = [];
    }

    this.entityForm.addControl('obligations', this.fb.control(null));
    this.obligationOptions = {get: () => this.obligationService.findAll(), propName: 'label'};
    this.entityForm.get('obligations').setValue(this.itemDescription.obligations);
  }

  private _getCategory(familyID: number, categoryID: number) {
    this.categoryService.findOneCategory(familyID, categoryID).subscribe((result: Category) => {
      if (!this.itemDescription.id) {
        this.category = result;
        const itemType = (this.category.family as Family).itemType;
        this.entityForm.addControl('family', this.fb.control(familyID));
        this.entityForm.addControl('category', this.fb.control(categoryID));
        this.entityForm.addControl('itemType', this.fb.control(itemType ? itemType.id : null));

        this._handledType(itemType);

        this.cd.detectChanges();
      }
    });
  }

  private _handledType(itemType: ItemType) {
    if (itemType === null) {
      return;
    }

    if (itemType.value === 8 || itemType.value === 6 || itemType.value === 7) {
      this.entityForm.addControl('controllable', this.fb.control(this.itemDescription.controllable));
      this.entityForm.addControl('ceCertification', this.fb.control(this.itemDescription.ceCertification));
      this.entityForm.addControl('model', this.fb.control(this.itemDescription.model));
      this.entityForm.addControl('duration', this.fb.control(this.itemDescription.duration));
      this.entityForm.addControl('type', this.fb.control(this.itemDescription.type));
      this.isEquipment = true;
    } else if (itemType.value === 3) {
      this.entityForm.addControl('controllable', this.fb.control(this.itemDescription.controllable));
      this.isEquipment = false;
    }
  }

  private _getItemDescription(itemDescriptionID: number) {
    this.itemDescriptionService.find(itemDescriptionID).subscribe((result: ItemDescription) => {
      this.itemDescription = result;

      this._generateObligations();

      if (!this.itemDescription.form) {
        this.entityForm.addControl('form', this.fb.control(null));
      }

      const itemType = this.itemDescription.itemType;

      this._handledType(itemType);

      if (this.itemDescription.category.form || this.itemDescription.form) {
        this.forms = {};
        this.formsMapping = {};
      }

      FormHelper.initValues(this.itemDescription, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    this.nbForms = 1;
    this.formsValidation = [];

    for (const key in this.forms) {
      this.nbForms++;
      if (this.forms[key].invalid) {
        return;
      }
    }

    for (const key in this.formsMapping) {
      const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});

      FormHelper.submitForm(
        this.cd,
        this.forms[key],
        this.itemDescriptionDataService.putData(entityData, this.itemDescription.id, parseInt(key.substr(5), 10)),
        (result: Entity) => {
          this.formsValidation.push(true);
          this._handleFormsSubmit(result);
        }
      );
    }

    const entity = FormHelper.buildEntity(this.itemDescription, this.entityForm, {
      'picture.upload': {type: FieldType.FILE, multiple: false},
      obligations: {type: FieldType.SELECT, multiple: true}
    }) as ItemDescription;


    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.itemDescriptionService.put(entity) : this.itemDescriptionService.post(entity)),
      (result: Entity) => {
        this.formsValidation.push(true);
        this._handleFormsSubmit(result);
      }
    );
  }

  private _handleFormsSubmit(result: Entity) {
    if (this.formsValidation.length === this.nbForms) {
      this.toastrService.open((this.itemDescription.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
      if (this.itemDescription.id || (!this.itemDescription.id && !this.itemDescription.form && !this.category.form)) {
        this.routeNameService.goTo('item_description_view', {id: (this.itemDescription.id ? this.itemDescription.id : result.id)});
      } else {
        this.routeNameService.goTo('item_description_id', {id: result.id});
      }
    }
  }

  updateFamily() {
    const itemTypeID = this.entityForm.get('itemType').value;

    if (this.entityForm.contains('family')) {
      this.entityForm.removeControl('family');
    }
    this.familyOptions = null;
    if (!itemTypeID) {
      return;
    }
    const itemType = this.itemTypes.filter((item: ItemType) => {
      return item.id === itemTypeID;
    }).pop();

    if (this.entityForm.contains('controllable')) {
      this.entityForm.removeControl('controllable');
    }
    if (this.entityForm.contains('ceCertification')) {
      this.entityForm.removeControl('ceCertification');
    }
    if (this.entityForm.contains('model')) {
      this.entityForm.removeControl('model');
    }
    if (this.entityForm.contains('duration')) {
      this.entityForm.removeControl('duration');
    }

    this._handledType(itemType);

    this.cd.detectChanges();
    this.entityForm.addControl('family', this.fb.control(null));
    this.familyOptions = {get: () => this.familyService.findAllByItemItem(itemType.value), propName: 'label'};
    this.cd.detectChanges();
  }

  updateCategory() {
    const familyID = this.entityForm.get('family').value;

    if (this.entityForm.contains('category')) {
      this.entityForm.removeControl('category');
    }
    this.categoryOptions = null;
    if (!familyID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('category', this.fb.control(null));
    this.categoryOptions = {get: () => this.categoryService.findAllByParent(familyID), propName: 'label'};
    this.cd.detectChanges();
  }

  getFetchDataURL(formID: number) {
    return this.itemDescriptionDataService.getData(this.itemDescription.id, formID);
  }

  getItemDescriptionDeleteURL() {
    return this.itemDescriptionService.delete(this.itemDescription.id);
  }

  getItemDescriptionEnableURL() {
    return this.itemDescriptionService.enable(this.itemDescription.id);
  }

  getItemDescriptionSuccessURL() {
    return this.routeNameService.path('item_description_view', {id: this.itemDescription.id});
  }
}
