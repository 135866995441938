<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{label ? label: 'Suivi / action'}} : {{nbItems}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <esomus-table [options]="itemTable" [searchReceiver]="search" (getLength)="updateNbItems($event)"></esomus-table>
</mat-expansion-panel>

<ng-template #typeCell let-row>
  <ng-container [ngSwitch]="row.category">
    <span *ngSwitchCase="'anomaly'">Anomalie</span>
    <span *ngSwitchCase="'accident'">Accident</span>
    <span *ngSwitchDefault>{{row.category}}</span>
  </ng-container>
</ng-template>

<ng-template #fromAnomalyCell let-row>
    <mat-icon *ngIf="row.anomalyID">error</mat-icon>
    <mat-icon *ngIf="row.accidentID">warning</mat-icon>
    <mat-icon *ngIf="row.taskID">list</mat-icon>
</ng-template>

<ng-template #labelCell let-row>
    <span>{{row.label}}</span>
</ng-template>

<ng-template #actionAnomalyCell let-row>
  <ng-container [ngSwitch]="row.category">
    <mat-icon *ngSwitchCase="'anomaly'">error</mat-icon>
    <mat-icon *ngSwitchCase="'accident'">warning</mat-icon>
    <mat-icon *ngSwitchDefault>build</mat-icon>
  </ng-container>
</ng-template>

<ng-template #statusCell let-row>
  <span>{{taskHelper.getStatusLabel(row.status)}}</span>
</ng-template>

<ng-template #headerActionAnomalyTable>
  <esomus-ng-select *ngIf="cOptions" [options]="cOptions"
                    [control]="form.get('c')" (valueChange)="cChange()"
                    i18n-label="@@c" label="Clôturé" fxFlex></esomus-ng-select>
  <ng-container *ngIf="taskID">
    <esomus-ng-select *ngIf="buildingOptions" [options]="buildingOptions"
                      [control]="form.get('building')" (valueChange)="buildingChange()"
                      i18n-label="@@building" label="Bâtiment" fxFlex></esomus-ng-select>
    <esomus-submit *ngIf="currentBuildingID" fxFlex label="Appliquer" (submit)="closeActions()"></esomus-submit>
  </ng-container>
</ng-template>
