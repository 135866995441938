<mat-accordion multi="true">
  <mat-expansion-panel *ngIf="documentsTable" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Gestion documentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <esomus-dashboard-print-selector screenID="10"></esomus-dashboard-print-selector>
    </div>
    <esomus-table *ngIf="documentsTable"
                  [options]="documentsTable" [searchReceiver]="documentTableEmitter"></esomus-table>
  </mat-expansion-panel>
</mat-accordion>

<esomus-document-version *ngIf="currentDocumentID" [previousID]="currentDocumentID"
                         [documentTableEmitter]="documentTableEmitter"></esomus-document-version>

<ng-template #tableHeader>
  <esomus-ng-select *ngIf="documentTypeOptions" [options]="documentTypeOptions"
                    (valueChange)="submitSearch()"
                    [control]="searchForm.get('documentType')" i18n-label="@@category"
                    label="Catégorie" fxFlex="100"></esomus-ng-select>
  <mat-checkbox [checked]="true" (change)="documentActiveChange($event)">Afficher les actifs</mat-checkbox>
</ng-template>
