import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Person} from '../models/person.model';
import {environment} from '../../../environments/environment';
import {Entity} from '../models/entity.model';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {Document} from '../models/document.model';
import {Params} from '@angular/router';

const crudPath: CRUDPath = {
  many: `/persons/`,
  single: (id: number) => `/persons/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class PersonService extends CRUD<Person> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  getCompanyPersons(companyID: number, params?: Params) {
    return this.http.get<Array<Person>>(`${environment.api.url}${this.crudPath.many}companies/${companyID}`, params);
  }

  findCompanies(params?: Params) {
    return this.http.get<Array<Person>>(`${environment.api.url}${this.crudPath.many}companies`, params);
  }

  getMembers(containerID: number) {
    return this.http.get<Array<Person>>(`${environment.api.url}${this.crudPath.single(containerID)}/members`);
  }

  postMember(containerID: number, memberID: number) {
    return this.http.post<Entity>(`${environment.api.url}${this.crudPath.single(containerID)}/members/${memberID}`, null);
  }

  deleteMember(containerID: number, memberID: number) {
    return this.http.delete<Entity>(`${environment.api.url}${this.crudPath.single(containerID)}/members/${memberID}`);
  }

  findRemainingMembers(containerID: number) {
    return this.http.get<Array<Person>>(`${environment.api.url}${this.crudPath.single(containerID)}/remaining`);
  }

  download(dID: number, subUrl: string = ''): Observable<HttpResponse<Blob>> {
    return this.http.getBlob(`${environment.api.url}${subUrl}${crudPath.single(dID)}/download`);
  }

  link(dID: number, subUrl: string = '', data: any = null): Observable<Document> {
    return this.http.post<Document>(`${environment.api.url}${subUrl}${crudPath.single(dID)}/link`, null, data);
  }

  unlink(dID: number, subUrl: string = ''): Observable<Document> {
    return this.http.delete<Document>(`${environment.api.url}${subUrl}${crudPath.single(dID)}/unlink`);
  }
}
