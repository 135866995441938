import {Component, OnInit} from '@angular/core';
import {ColumnSortDirection, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {RoleService} from '../../../core/api/role.service';

@Component({
  selector: 'esomus-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.sass']
})
export class RoleListComponent implements OnInit {

  rolesTable: TableOptions;

  constructor(
    private roleService: RoleService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.rolesTable = {
      columnDefs: [
        {
          prop: 'description', width: '40%',
          name: this.i18n({value: 'Description', id: 'description'})
        },
        {
          prop: 'type', width: '15%%',
          name: this.i18n({value: 'Type', id: 'type'})
        },
        {
          prop: 'taskType.label', width: '40%',
          name: this.i18n({value: 'Type de tâche', id: 'taskType'})
        },
      ],
      findDataCb: (searchData: Params) => {
        return this.roleService.findAll('', searchData);
      },
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un role', id: 'btnAddRole'}),
            createCb: () => this.routeName.goTo('role_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('role_id', {id: entity.id});
        },
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }
}
