import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {Entity} from '../../../core/models/entity.model';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute, Params} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ContactService} from '../../../core/api/contact.service';
import {Contact} from '../../../core/models/contact.model';

@Component({
  selector: 'esomus-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.sass']
})
export class ContactTableComponent implements OnInit {

  nbContacts: number;
  contactsTable: TableOptions;
  showContactModal: boolean;

  @Input() parentType: string;
  @Input() parent: Entity;

  contactTableEmitter: EventEmitter<any> = new EventEmitter<any>();
  private url: Observable<any>;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private cd: ChangeDetectorRef,
    private contactService: ContactService,
  ) {
  }

  ngOnInit() {
    let visibleCb = null;

    switch (this.parentType) {
      case 'item':
        visibleCb = (contact: Contact) => contact.origin === 2;
        break;
      case 'itemDescription':
        visibleCb = (contact: Contact) => contact.origin === 1;
        break;
    }

    this.contactsTable = {
      columnDefs: [
        {
          prop: 'contact.fullName', width: '30%',
          name: this.i18n({value: 'Nom', id: 'name'})
        },
        {
          prop: 'role.description', width: '20%',
          name: this.i18n({value: 'Rôle', id: 'role'})
        },
        {
          prop: 'contact.phone', width: '20%',
          name: this.i18n({value: 'Tel.', id: 'phone'})
        },
        {
          prop: 'contact.mail', width: '20%',
          name: this.i18n({value: 'Mail', id: 'mail'})
        },
        {
          prop: 'contact.intern', valueCb: (entity: Contact) => entity.contact.intern ? 'I' : 'E',
          name: this.i18n({value: 'IE', id: 'ie'}), width: '5%'
        },
      ],
      findDataCb: (searchData: Params) => {
        return this.contactService.getParent(this.parent.id, this.parentType);
      },
      actions: {
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un contact', id: 'btnAddContact'}),
            createCb: () => {
              this.showContactModal = false;
              this.cd.detectChanges();
              this.showContactModal = true;
              this.cd.detectChanges();
            }
          },
        ],
        readCb: (entity: Contact) => this.routeNameService.goTo('person_view', {id: entity.contact.id}),
        custom: [
          {
            icon: 'link_off',
            visibleCb,
            customCb: (entity: Entity) => {
              this.url = this.contactService.unlinkParent(this.parent.id, entity.id, this.parentType);

              switch (this.parentType) {
                case 'item':
                case 'itemDescription':
                  this._remove();
                  break;
              }
            }
          },
        ],
        columnWidth: '5%'
      }
    };
  }

  updateNbContacts(nbContacts: number) {
    this.nbContacts = nbContacts;
  }

  private _remove() {
    this.url.subscribe(() => {
      this.url = null;
      this.contactTableEmitter.emit();
    });
  }
}
