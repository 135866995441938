import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AddressService} from '../../../core/api/address.service';
import {Address} from '../../../core/models/address.model';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {of} from 'rxjs';
import {FormHelper} from '../../../core/services/form-helper.service';
import {AddressHelper} from '../../../core/services/address-helper.service';
import {Family} from '../../../core/models/family.model';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';

@Component({
  selector: 'esomus-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.sass']
})
export class AddressModalComponent implements OnInit {

  @Input() personID: number;
  @Input() addressID: number;
  @Input() tableEmitter: EventEmitter<any>;

  @ViewChild('modal', { static: true }) modal: TemplateRef<any>;
  modalRef: MatDialogRef<AddressModalComponent>;
  fieldType = FieldType;
  address: Address;
  entityForm: FormGroup;
  typeOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private addressService: AddressService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.addressID === null) {
      this.address = new Address();

      this._generateForm();
    } else {
      this.addressService.findByParent(this.personID, this.addressID).subscribe((result: Address) => {
        this.address = result;

        this._generateForm();
      });
    }
  }

  private _generateForm() {
    this.typeOptions = {get: () => of(AddressHelper.getTypeOptions()), propName: 'label', autoSelect: true};

    this.entityForm = this.fb.group({
      line1: [null],
      locality: [null],
      zipCode: [null],
      coordinates: [null],
      country: [null],
      type: [null],
      description: [null],
      phone: [null],
      mail: [null],
    });

    if (this.address.id) {
      FormHelper.initValues(this.address, this.entityForm);
    }

    this.cd.detectChanges();

    this.modalRef = this.dialog.open(this.modal);
  }

  closeModal() {
    this.modalRef.close();
    this.modalRef = null;
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.address, this.entityForm, {}) as Address;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.addressService.putByParent(this.personID, entity) : this.addressService.postByParent(this.personID, entity)),
      (result: Family) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);

        this.closeModal();
        this.tableEmitter.emit();
      }
    );
  }
}
