<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Fiches
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <esomus-table [options]="itemDescriptionTable"></esomus-table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
