import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  static duration = 5000;
  static horizontal: MatSnackBarHorizontalPosition = 'end';
  static vertical: MatSnackBarVerticalPosition = 'top';

  constructor(
    private snackBar: MatSnackBar,
    private i18n: I18n
  ) { }

  openCustom(type: ToastrType, message: string) {

    let panelClass = '';
    switch (type) {
      case ToastrType.SUCCESS:
        panelClass = 'toastr-success';
        break;
      case ToastrType.ERROR:
        panelClass = 'toastr-error';
        break;
    }

    this.snackBar.open(message, 'OK', {
      duration: ToastrService.duration,
      panelClass,
      horizontalPosition: ToastrService.horizontal,
      verticalPosition: ToastrService.vertical
    });
  }

  open(messageType: ToastrMessageType) {
    switch (messageType) {
      case ToastrMessageType.CREATE:
        this.openCustom(ToastrType.SUCCESS, this.i18n({ value: 'Créé avec succès !', id: 'toastrCreateSuccess' }));
        break;
      case ToastrMessageType.UPDATE:
        this.openCustom(ToastrType.SUCCESS, this.i18n({ value: 'Modifié avec succès !', id: 'toastrUpdateSuccess' }));
        break;
      case ToastrMessageType.DELETE:
        this.openCustom(ToastrType.SUCCESS, this.i18n({ value: 'Supprimé avec succès !', id: 'toastrDeleteSuccess' }));
        break;
      case ToastrMessageType.DELETE_ERROR:
        this.openCustom(ToastrType.ERROR, this.i18n({ value: 'Suppression impossible !', id: 'toastrDeleteError' }));
        break;
      case ToastrMessageType.ACCESS_DENIED:
        this.openCustom(ToastrType.ERROR, this.i18n({
          value: 'Accès refusé',
          id: 'toastrAccessDenied'
        }));
        break;
      case ToastrMessageType.ERROR_500:
        this.openCustom(ToastrType.ERROR, this.i18n({
          value: 'Erreur interne. Veuillez contacter un administrateur.',
          id: 'toastr500Error'
        }));
        break;
    }
  }
}

export enum ToastrType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export enum ToastrMessageType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  ERROR_500 = '500',
  ACCESS_DENIED = 'access_denied',
  DELETE_ERROR = 'delete_error',
}
