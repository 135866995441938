<mat-accordion multi="true">
  <mat-expansion-panel class="mat-edit" expanded="true" *ngIf="listComponent">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Liste: {{listComponent.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-dashboard-print-selector *ngIf="listComponent.id" screenID="26" [itemID]="listComponent.id"></esomus-dashboard-print-selector>
      </div>
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <esomus-input [autofocus]="true" [type]="fieldType.TEXT" [control]="entityForm.get('name')" i18n-label="@@name"
                        label="Nom" flex="50" fxFlex="100"></esomus-input>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div class="btn-group-left" fxFlex="50">
<!--            <esomus-delete-button *ngIf="listComponent.id && listComponent.active"-->
<!--                                  [url]="getCategoryDeleteURL()"-->
<!--                                  [successUrl]="getCategorySuccessURL()"></esomus-delete-button>-->
          </div>
          <div class="btn-group-right" fxFlex="50">
            <esomus-back-button></esomus-back-button>
            <esomus-submit (submit)="submit()"></esomus-submit>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true" *ngIf="valueTable">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Valeurs : {{nbValues}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="valueTable" (getLength)="updateNbValues($event)" [options]="valueTable"></esomus-table>
  </mat-expansion-panel>
</mat-accordion>
