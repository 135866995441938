import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {Action} from '../../../core/models/action.model';
import {ActionService} from '../../../core/api/action.service';
import {TaskHelper} from '../../../core/services/task-helper.service';
import {ActionDataService} from '../../../core/api/action-data.service';

@Component({
  selector: 'esomus-action-view',
  templateUrl: './action-view.component.html',
  styleUrls: ['./action-view.component.sass']
})
export class ActionViewComponent implements OnInit {

  action: Action;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private actionService: ActionService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private actionDataService: ActionDataService,
  ) {
  }

  ngOnInit() {
    const actionID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    console.log(actionID);

    this._getAction(actionID);
  }

  private _getAction(actionID: number) {
    this.actionService.find(actionID).subscribe((action: Action) => {
      this.action = action;

      this.cd.detectChanges();
    });
  }

  getEquipment() {
    if (this.action.anomaly) {
      return this.action.anomaly.item;
    } else if (this.action.item) {
      return this.action.item;
    }
    return null;
  }

  editAction() {
    this.routeNameService.goTo('action_id', {id: this.action.id});
  }

  encode() {
    this.routeNameService.goTo('action_realisation', {id: this.action.id});
  }

  getOrigin() {
    return TaskHelper.getOrigin(this.action);
  }

  getTaskOrigin() {
    return `${TaskHelper.getOrigin(this.action)} (${this.action.task.description})`;
  }

  getTaskURL() {
    return this.routeNameService.path('task_view', {id: this.action.task.id});
  }

  getEmergencyLabel() {
    return TaskHelper.getLabel(this.action.emergency);
  }

  getStatus() {
    return TaskHelper.getStatusLabel(this.action.status);
  }

  getFetchDataURL(formID: number) {
    return this.actionDataService.getData(this.action.id, formID);
  }
}
