import {Entity} from './entity.model';
import {Item} from './item.model';
import {Form} from './form.model';
import {Person} from './person.model';
import {Anomaly} from './anomaly.model';
import {TaskType} from './task-type.model';
import {Task} from './task.model';
import {Picture} from './picture.model';
import {Accident} from './accident.model';

export class Action extends Entity {
  description: string;
  comment: string;
  emergency: number;
  form: Form;
  person: Person;
  anomaly: Anomaly;
  taskType: TaskType;
  closed: boolean;
  deadlineDate: string;
  executionDate: string;
  plannedDate: string;
  executionComment: string;
  executionPicture: Picture;
  status: number;
  linkType: number;
  item: Item;
  task: Task;
  accident: Accident;
}
