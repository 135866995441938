<mat-accordion multi="true" class="mat-edit">
  <mat-expansion-panel expanded="true" *ngIf="family">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Famille: {{family.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-img *ngIf="family.id" fxFlex="100" [picture]="family.picture"></esomus-img>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div fxLayout="column" fxFlexOffset="80" fxFlex="20" fxFlex.xs="100">
                <esomus-info-box *ngIf="family.id" [active]="family.active"></esomus-info-box>
              </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('label')"
                            i18n-label="@@label" label="Libellé" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('code')"
                            i18n-label="@@esomusCode" label="Code esomus" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="itemTypeOptions" [options]="itemTypeOptions"
                                [control]="entityForm.get('itemType')" i18n-label="@@itemTypeTheme"
                                label="Thématique" [fxFlex]="50" flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!itemTypeOptions" fxFlex="50" boldValue="Thématique"
                                [textValue]="family.itemType.label"></esomus-line-data>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div class="btn-group-left" fxFlex="50">
            <esomus-delete-button *ngIf="family.id && family.active" [url]="getFamilyDeleteURL()"
                                  [successUrl]="getFamilySuccessURL()"></esomus-delete-button>
          </div>
          <div class="btn-group-right" fxFlex="50">
            <esomus-back-button></esomus-back-button>
            <esomus-submit (submit)="submit()"></esomus-submit>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
