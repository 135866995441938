import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {QuickpanelComponent} from './components/quickpanel/quickpanel.component';
import {ScrollbarComponent} from './components/scrollbar/scrollbar.component';
import {ScrollbarService} from './components/scrollbar/scrollbar.service';
import {SettingsComponent} from './components/settings/settings.component';
import {SidenavCollapseDirective} from './components/sidenav/sidenav-collapse.directive';
import {SidenavItemComponent} from './components/sidenav/sidenav-item/sidenav-item.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {LayoutComponent} from './layout.component';
import {ToolbarUserButtonComponent} from './components/toolbar/toolbar-user-button/toolbar-user-button.component';
import {ToolbarNotificationsComponent} from './components/toolbar/toolbar-notifications/toolbar-notifications.component';
import {ToolbarComponent} from './components/toolbar/toolbar/toolbar.component';
import {ClickOutsideDirective} from './utils/click-outside.directive';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {MaterialModule} from '../../material.module';
import {MediaReplayService} from './utils/media-replay.service';
import {BreadcrumbsModule} from '../breadcrumbs/breadcrumbs.module';
import {menuProvider} from './components/sidenav/menu.provider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipeModule} from '../../pipes/pipe.module';
import {FormBuilderModule} from '../../form-builder/form-builder.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    BreadcrumbsModule,
    PipeModule,
    StoreModule.forRoot(reducers),
    FormBuilderModule,
  ],
  declarations: [
    LayoutComponent,
    SidenavCollapseDirective,
    SidenavComponent,
    SidenavItemComponent,
    SettingsComponent,
    ScrollbarComponent,
    QuickpanelComponent,
    ToolbarUserButtonComponent,
    ToolbarNotificationsComponent,
    ToolbarComponent,
    ClickOutsideDirective,
  ],
  exports: [
    LayoutComponent
  ],
  providers: [
    ScrollbarService,
    MediaReplayService,
    menuProvider
  ]
})
export class LayoutModule {
}
