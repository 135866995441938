<mat-sidenav-container class="layout-container" [ngClass]="cardElevation$ | async">

  <mat-sidenav class="settings-sidenav" (openedStart)="openSettings()" (closedStart)="closeSettings()"
    [opened]="settingsOpen$ | async" mode="over" position="end" #options>
    <esomus-settings></esomus-settings>
  </mat-sidenav>

  <div class="layout alpha" fxLayout="column" [class.boxed]="layoutBoxed$ | async">

    <mat-sidenav-container fxFlex>

      <!-- <mat-sidenav class="quickpanel-container" (openedStart)="openQuickpanel()" (closedStart)="closeQuickpanel()"
        [opened]="quickpanelOpen$ | async" [position]="quickpanelAlign" [mode]="quickpanelMode">
        <esomus-quickpanel></esomus-quickpanel>
      </mat-sidenav> -->

      <mat-sidenav class="sidenav-container" (openedStart)="openSidenav()" (closedStart)="closeSidenav()"
        [opened]="sidenavOpen$ | async" [position]="sidenavAlign$ | async" [mode]="sidenavMode$ | async"
        [disableClose]="sidenavDisableClose$ | async" [esomusSidenavCollapse]="sidenavCollapsed$ | async">

        <esomus-sidenav [collapsed]="sidenavCollapsed$ | async" (toggledSidenavCollapse)="toggleSidenavCollapse()">
        </esomus-sidenav>

      </mat-sidenav>

      <div class="toolbar-container" fxLayout="column">
        <esomus-toolbar [sidenavCollapsed]="sidenavCollapsed$ | async" [quickpanelOpen]="quickpanelOpen$ | async"
          (toggledSidenav)="toggleSidenav()" (toggledQuickpanel)="toggleQuickpanel()">
        </esomus-toolbar>

        <div class="content-container" fxFlex>
          <div class="responsive-padding">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </mat-sidenav-container>

  </div>

  <!-- <div id="settings" class="options-toggle mat-elevation-z4" matRipple (click)="toggleSettings()" fxLayout="row"
    fxLayoutAlign="center center">
    <mat-icon>settings</mat-icon>
  </div> -->

</mat-sidenav-container>
