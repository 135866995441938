import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Entity} from '../../../core/models/entity.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {EquipmentService} from '../../../core/api/equipment.service';
import {Params} from '@angular/router';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Person} from '../../../core/models/person.model';
import {Item} from '../../../core/models/item.model';

@Component({
  selector: 'esomus-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.sass']
})
export class EquipmentTableComponent implements OnInit {
  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('familyCategoryCell', { static: true }) familyCategoryCell: TemplateRef<any>;

  @Input() parent: string;
  @Input() parentID: number;
  @Input() label: string;
  @Input() expanded = true;
  @Input() addParam: object;
  @Input() addLabel: string;

  @Input() person: Person;
  @Input() link: Item;

  equipmentTable: TableOptions;

  nbEquipments: number;

  showModalPerson: boolean;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private equipmentService: EquipmentService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!this.addParam) {
      this.addParam = {};
    }

    this.equipmentTable = {
      columnDefs: [
        {
          name: this.i18n({value: 'Photo', id: 'picture'}),
          type: ColumnType.PICTURE, prop: 'picture', width: '10%'
        },
        {
          name: this.i18n({ value: 'Nom', id: 'name' }),
          prop: 'label', width: '25%'
        },
        {
          name: this.i18n({ value: 'Modèle', id: 'model' }),
          prop: 'itemDescription.model', width: '15%'
        },
        {
          name: this.i18n({ value: 'Type', id: 'type' }),
          prop: 'itemDescription.type', width: '15%'
        },
      ],
      findDataCb: (searchData: Params) => {
        let params = {active: searchData.active !== undefined ? searchData.active : true};
        return this.equipmentService.getAllFromParent(this.parentID, this.parent, params);
      },
      actions: {
        custom: [],
        create: [
          {
            btnName: this.addLabel ? this.addLabel : 'Ajouter un équipement',
            createCb: () => {
              switch (this.parent) {
                case 'person':
                  this.showModalPerson = false;
                  this.cd.detectChanges();
                  this.showModalPerson = true;
                  this.cd.detectChanges();
                  break;
                case 'link':
                  this.routeNameService.goTo('equipment_link', {id: this.parentID}, this.addParam);
                  break;
                default:
                  this.routeNameService.goTo('equipment_add', {parentID: this.parentID}, this.addParam);
                  break;
              }
            }
          },
        ],
        readCb: (entity: Entity) => this.routeNameService.goTo('equipment_view', { id: entity.id }),
        columnWidth: '5%'
      },
      headTemplate: this.headerTable
    };

    if (this.parent === 'site') {
      this.equipmentTable.columnDefs.push(
        {
          name: this.i18n({ value: 'Bâtiment', id: 'building' }),
          prop: 'building.name', width: '20%'
        }
      );
    } else {
      this.equipmentTable.columnDefs.push(
        {
          name: this.i18n({ value: 'Famille / Catégorie', id: 'familyCategory' }), width: '20%',
          type: ColumnType.TEMPLATE, template: this.familyCategoryCell, prop: 'templateFamilyCategory'
        }
      );
    }

    this.equipmentTable.columnDefs.push(
      {
        name: this.i18n({ value: 'En ordre', id: 'inOrder' }),
        prop: 'inOrder', width: '20%', type: ColumnType.CHECKBOX,
      }
    );

    if (this.parent === 'link') {
      this.equipmentTable.actions.custom.push({
          icon: 'cancel', customCb: (item: Entity) => 1
      });
    }
  }

  updateNbEquipments(nbEquipments: number) {
    this.nbEquipments = nbEquipments;
  }

  equipmentChange(event: MatCheckboxChange) {
    this.search.emit({
      active: event.checked
    });
  }
}
