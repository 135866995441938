<mat-expansion-panel expanded="false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Adresses : {{nbAddresses}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <esomus-table *ngIf="addressTableOptions" [options]="addressTableOptions"
                  (getLength)="updateNbAdresses($event)"
                  [searchReceiver]="tableEmitter"></esomus-table>
  </div>
</mat-expansion-panel>

<esomus-address-modal *ngIf="showModal" [personID]="personID" [addressID]="currentAddressID"
                      [tableEmitter]="tableEmitter"></esomus-address-modal>
