import {Component, Input, OnInit} from '@angular/core';
import {Task} from '../../../core/models/task.model';
import {RouteNameService} from '../../../core/services/route-name.service';
import {TaskHelper} from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-task-panel',
  templateUrl: './task-panel.component.html',
  styleUrls: ['./task-panel.component.sass']
})
export class TaskPanelComponent implements OnInit {

  @Input() task: Task;
  @Input() expanded: boolean;
  @Input() showBtns: boolean;
  @Input() fromProgramming = false;
  @Input() fromTask = false;

  constructor(
    private routeNameService: RouteNameService
  ) {
    this.expanded = true;
    this.showBtns = true;
  }

  ngOnInit() {
  }

  editTask() {
    this.routeNameService.goTo('task_id', {id: this.task.id});
  }

  getEmergencyLabel() {
    return TaskHelper.getLabel(this.task.emergency);
  }

  getTaskViewURL() {
    return this.routeNameService.path('task_view', {id: this.task.id});
  }
}
