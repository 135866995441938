<mat-accordion multi="true" *ngIf="accidentPerson">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Personne
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
        <div fxLayout="column" [fxFlex]="100/3">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="Témoin"
                              [boolValue]="accidentPerson.witness"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Personne"
                              [textValue]="accidentPerson.person.fullName"></esomus-line-data>
            <esomus-line-data *ngIf="!accidentPerson.witness" fxFlex="100" boldValue="Impact"
                              [textValue]="accidentPerson.impact ? accidentPerson.impact.description : null"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" [fxFlex]="100/3">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="Interne"
                              [boolValue]="accidentPerson.intern"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Fonction"
                              [textValue]="accidentPerson.role.description"></esomus-line-data>
            <esomus-line-data *ngIf="!accidentPerson.witness" fxFlex="100" boldValue="Jours"
                              [textValue]="accidentPerson.days.toString()"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" [fxFlex]="100/3">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="Effet"
                              [textValue]="accidentPerson.effect.description"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Organes" [items]="accidentPerson.zones"
                              prop="description"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" [fxFlex]="100 * 2 / 3">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-line-data fxFlex="100" boldValue="Détails" boldFlex="25" textFlex="75"
                              [textValue]="accidentPerson.comment"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
