import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {Family} from '../../../core/models/family.model';
import {Category} from '../../../core/models/category.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {CategoryService} from '../../../core/api/category.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {FormService} from '../../../core/api/form.service';
import {FamilyService} from '../../../core/api/family.service';

@Component({
  selector: 'esomus-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.sass']
})
export class CategoryComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;
  family: Family;
  category: Category;
  private subURL: string;
  formOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    private formService: FormService,
    private cd: ChangeDetectorRef,
    private familyService: FamilyService
  ) {
  }

  ngOnInit() {
    this.formOptions = {get: () => this.formService.findAll(), propName: 'name'};

    this.entityForm = this.fb.group({
      label: [null],
      comment: [null],
      'picture.upload': [],
      code: [null],
    });

    const familyID = parseInt(this.activatedRoute.snapshot.paramMap.get('familyID'), 10);
    this.subURL = `/families/${familyID}`;
    const categoryID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    if (!isNaN(categoryID)) {
      this._getCategory(categoryID);
    } else {
      this.familyService.find(familyID).subscribe((result: Family) => {
        this.category = new Category();
        this.category.family = result;
        this.entityForm.addControl('form', this.fb.control(null));
        this.cd.detectChanges();
      });
    }
  }

  private _getCategory(categoryID: number) {
    this.categoryService.find(categoryID, this.subURL)
      .subscribe((category: Category) => {
        this.category = category;

        if (!this.category.form) {
          this.entityForm.addControl('form', this.fb.control(null));
        }

        FormHelper.initValues(this.category, this.entityForm);
        let family = this.category.family as Family;
        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }
    let entity = FormHelper.buildEntity(this.category, this.entityForm, {
      'picture.upload': {type: FieldType.FILE, multiple: false}
    }) as Category;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.categoryService.put(entity, this.subURL) : this.categoryService.post(entity, this.subURL)),
      (result: Category) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        const familyID = typeof this.category.family === 'object' ? this.category.family.id : this.category.family;
        this.routeNameService.goTo('category_view', {id: result.id, familyID});
      }
    );
  }

  getCategoryDeleteURL() {
    return this.categoryService.delete(this.category.id, this.subURL);
  }

  getCategorySuccessURL() {
    const familyID = typeof this.category.family === 'object' ? this.category.family.id : this.category.family;
    return this.routeNameService.path('family_view', {id: familyID});
  }
}
