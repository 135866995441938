import {Component, OnInit} from '@angular/core';
import {ColumnSortDirection, ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {AnomalyTypeService} from '../../../core/api/anomaly-type.service';
import {AnomalyType} from '../../../core/models/anomaly-type.model';
import {TaskHelper} from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-anomaly-type-list',
  templateUrl: './anomaly-type-list.component.html',
  styleUrls: ['./anomaly-type-list.component.sass']
})
export class AnomalyTypeListComponent implements OnInit {

  anomalyTypesTable: TableOptions;

  constructor(
    private anomalyTypeService: AnomalyTypeService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.anomalyTypesTable = {
      columnDefs: [
        {
          prop: 'label', width: '45%',
          name: this.i18n({value: 'Libellé', id: 'label'})
        },
        {
          prop: 'severity', width: '20%', valueCb: (item: AnomalyType) => TaskHelper.getSeverityLabel(item.severity),
          name: this.i18n({value: 'Gravité', id: 'severity'})
        },
        {
          prop: 'emergency', width: '20%', valueCb: (item: AnomalyType) => TaskHelper.getLabel(item.emergency),
          name: this.i18n({value: 'Urgence', id: 'emergency'})
        },
        {
          prop: 'handled', type: ColumnType.CHECKBOX, width: '10%',
          name: this.i18n({value: 'Géré', id: 'handled'})
        },
      ],
      findDataCb: (searchData: Params) => this.anomalyTypeService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un type d\'anomalie', id: 'btnAddAnomalyType'}),
            createCb: () => this.routeName.goTo('anomaly_type_add')
          }
        ],
        // readCb: (entity: Entity) => {
        //   this.routeName.goTo('anomaly_type_view', {id: entity.id});
        // },
        updateCb: (entity: Entity) => {
          this.routeName.goTo('anomaly_type_id', {id: entity.id});
          // this.userService.find(entity.id).subscribe((user: User) => {
          //   this.formService.updateForm(UsersComponent.userFormID, user);
          // });
        },
        // deleteCb: (entity: Entity) => this.userService.delete(entity.id)
      },
      defaultSortActive: 'label',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }

}
