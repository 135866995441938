<mat-accordion multi="true" class="mat-edit" *ngIf="zone">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Zone: {{zone.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-dashboard-print-selector *ngIf="zone.id" screenID="36" [itemID]="zone.id"></esomus-dashboard-print-selector>
      </div>
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.NUMBER"
                            [control]="entityForm.get('order')"
                            i18n-label="@@order" label="Ordre" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <esomus-delete-button *ngIf="zone.id && zone.active"
                                      [url]="getZoneDeleteURL()"
                                      [successUrl]="getZoneSuccessURL()">
                </esomus-delete-button>
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
