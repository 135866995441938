import {Entity} from './entity.model';
import {Family} from './family.model';
import {Picture} from './picture.model';
import {EsomusCode} from './esomus-code.model';
import {Form} from './form.model';

export class Category extends Entity {
  label: string;
  comment: string;
  nbItemDescriptions: number;
  code: string;
  family: Family | number;
  picture: Picture;
  esomusCode: EsomusCode;
  form: Form;

  active: boolean;
}
