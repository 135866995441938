import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../core/api/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {Dashboard} from '../../../core/models/dashboard.model';

@Component({
  selector: 'esomus-dashboard-redirection',
  templateUrl: './dashboard-redirection.component.html',
  styleUrls: ['./dashboard-redirection.component.sass']
})
export class DashboardRedirectionComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

      this.dashboardService.find(id).subscribe((result: Dashboard) => {
        window.document.documentElement.innerHTML = result.view;
      });
    });
  }

}
