<div [fxFlex]="flex">
  <ng-container *ngIf="!skipBold">
    <p [fxFlex.xs]="breakpoint" [fxFlex.md]="breakpoint" class="bold text-right m-r-10" [fxFlex]="boldFlex">
      {{boldValue}} :
    </p>
  </ng-container>
  <ng-container *ngIf="!skipText">
    <ul *ngIf="items && items.length > 0" [fxFlex.xs]="breakpoint" [fxFlex.md]="breakpoint" [class]="textClass + ' ul-line-data'" [fxFlex]="textFlex">
      <li *ngFor="let item of items">
        {{ item[prop] }}
      </li>
    </ul>
    <p *ngIf="(!items) && (!textURL || emptyText)" [fxFlex.xs]="breakpoint" [fxFlex.md]="breakpoint" [class]="textClass" [fxFlex]="textFlex">
      <span>{{textValue}}</span>
    </p>
    <p *ngIf="(!items) && (textURL && !emptyText)" [fxFlex.xs]="breakpoint" [fxFlex.md]="breakpoint" [class]="textClass" [fxFlex]="textFlex">
      <a class="link" [routerLink]="textURL">{{textValue}}</a>
    </p>
  </ng-container>
</div>
