import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit} from '@angular/core';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute, Params} from '@angular/router';
import {DashboardService} from '../../../core/api/dashboard.service';
import {Dashboard} from '../../../core/models/dashboard.model';

@Component({
  selector: 'esomus-dashboard-print-selector',
  templateUrl: './dashboard-print-selector.component.html',
  styleUrls: ['./dashboard-print-selector.component.scss']
})
export class DashboardPrintSelectorComponent implements OnInit {

  @Input() screenID: number;
  @Input() itemID: number;
  @Input() visible = true;

  showBtn: boolean;

  printOptions: EntityOption;
  entityForm: FormGroup;

  params: Params;

  constructor(
    private el: ElementRef,
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private dashboardService: DashboardService
  ) {
  }

  ngOnInit() {
    if (!this.visible) {
      return;
    }

    this.showBtn = false;

    this.entityForm = this.fb.group({
      print: [null]
    });
    this.params = {
      type: 'print'
    };
    if (this.itemID) {
      this.params['id'] = this.itemID;
    }
    if (this.screenID) {
      this.params['screenID'] = this.screenID;
    }

    this.printOptions = {get: () => this.dashboardService.findByScreens(this.screenID, this.params), propName: 'label'};
  }

  triggerButton() {
    this.showBtn = this.entityForm.get('print').value !== null;
    this.cd.detectChanges();
  }

  updatePrint() {
    let value = this.entityForm.get('print').value;
    if (!value) {
      return;
    }

    this.dashboardService.findWithParams(value, this.params).subscribe((result: Dashboard) => {
      let win = window.open();
      win.document.body.innerHTML = result.view;
    });

    this.entityForm.get('print').setValue(null);
    this.triggerButton();
  }
}
