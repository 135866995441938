<mat-expansion-panel [expanded]="expanded" *ngIf="task">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <a *ngIf="fromProgramming" class="link-header" [routerLink]="getTaskViewURL()">Tâche : {{task.description}}</a>
      <ng-container *ngIf="!fromProgramming">Tâche : {{task.description}}</ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <div *ngIf="showBtns && !fromProgramming" fxLayoutAlign="center center">
        <button mat-flat-button (click)="editTask()" color="success">
          <mat-icon>edit</mat-icon> Editer
        </button>
      </div>
      <esomus-dashboard-print-selector [visible]="fromTask" screenID="20" [itemID]="task.id"></esomus-dashboard-print-selector>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxLayout="column" fxFlex="30" fxFlex.lt-md="100">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <span *ngIf="task.definition">Canevas</span>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-line-data fxFlex="100" flex="50" boldValue="Défini par"
                            [textValue]="task.person ? task.person.fullName : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" flex="50" boldValue="Type"
                            [textValue]="task.taskType ? task.taskType.label : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" flex="50" boldValue="Description"
                            [textValue]="task.description"></esomus-line-data>
          <esomus-line-data fxFlex="100" flex="50" boldValue="Commentaire"
                            [textValue]="task.comment"></esomus-line-data>
          <esomus-line-data fxFlex="50" boldValue="Urgence"
                            [textValue]="getEmergencyLabel()"></esomus-line-data>
          <esomus-line-data *ngIf="!task.definition" fxFlex="50" boldValue="Date butoir"
                            [textValue]="task.deadlineDate" textType="date"></esomus-line-data>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20" fxFlex.lt-md="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">

        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
