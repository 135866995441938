<form>
  <mat-accordion multi="true" class="mat-edit" *ngIf="anomaly">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Anomalie: {{anomaly.id ? 'Edition' : 'Création'}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-img *ngIf="anomaly.id" fxFlex="100" [picture]="anomaly.picture"></esomus-img>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-line-data fxFlex="50" flex="100" boldValue="Origine anomalie"
                                [textValue]="anomaly.item.label"
                                [textURL]="getItemURL()"></esomus-line-data>
              <!--              <esomus-line-data fxFlex="50" flex="100" boldValue="Signalé par"-->
              <!--                                [textValue]="null"></esomus-line-data>-->
              <esomus-ng-select *ngIf="personOptions" [options]="personOptions"
                                [control]="entityForm.get('person')"
                                i18n-label="@@reportedBy" label="Signalé par"
                                [fxFlex]="50" flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="anomalyTypeOptions" [options]="anomalyTypeOptions"
                                [control]="entityForm.get('anomalyType')"
                                i18n-label="@@anomalyType" label="Type d'anomalie"
                                [fxHide]="anomaly.anomalyType" [fxFlex]="100"
                                flex="50" (valueChange)="updateForm()"></esomus-ng-select>
              <esomus-line-data *ngIf="anomaly.anomalyType" fxFlex="100" flex="50"
                                boldValue="Type anomalie"
                                [textValue]="anomaly.anomalyType.label"></esomus-line-data>
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@name" label="Nom" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-ng-select *ngIf="statusOptions" [options]="statusOptions"
                                [control]="entityForm.get('status')"
                                i18n-label="@@status" label="Statut"
                                [fxFlex]="50" flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!anomaly.id || anomaly.closed" fxFlex="50" flex="100"
                                boldValue="Statut"
                                [textValue]="getAnomalyStatus()"></esomus-line-data>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
              <esomus-ng-select *ngIf="severityOptions" [options]="severityOptions"
                                [control]="entityForm.get('severity')"
                                i18n-label="@@severity" label="Gravité"
                                [fxFlex]="100" flex="50"></esomus-ng-select>
              <esomus-ng-select *ngIf="emergencyOptions" [options]="emergencyOptions"
                                [control]="entityForm.get('emergency')"
                                i18n-label="@@emergency" label="Urgence"
                                [fxFlex]="100" flex="50"></esomus-ng-select>
              <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('deadlineDate')"
                            i18n-label="@@deadlineDate" label="Date butoir" flex="50"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="anomaly && anomaly.id && anomaly.form && forms" expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Information complémentaire
        </mat-panel-title>
      </mat-expansion-panel-header>
      <esomus-form-component
        [formID]="anomaly.form.id" [forms]="forms" [formsMapping]="formsMapping"
        [fetchDataURL]="getFetchDataURL(anomaly.form.id)"></esomus-form-component>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</form>

<ng-template #formModal>
  <form>
    <h3 mat-dialog-title>
      <span>Valider le changement de statut</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <p>Le changement de statut de l'anomalie peut impacter {{anomaly.nbActions}} action(s).
          Voulez-vous continuer ?</p>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="confirmAnomaly()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeAnomalyModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
