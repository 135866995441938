import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ItemType} from '../../../core/models/item-type.model';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {Item} from '../../../core/models/item.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ItemTypeService} from '../../../core/api/item-type.service';
import {CategoryService} from '../../../core/api/category.service';
import {FamilyService} from '../../../core/api/family.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {BuildingService} from '../../../core/api/building.service';
import {LocalService} from '../../../core/api/local.service';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {FormHelper} from '../../../core/services/form-helper.service';

@Component({
  selector: 'esomus-local-add',
  templateUrl: './local-add.component.html',
  styleUrls: ['./local-add.component.sass']
})
export class LocalAddComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;

  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  itemDescriptionOptions: EntityOption;

  local: Item;
  building: Item;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private buildingService: BuildingService,
    private localService: LocalService,
    private itemDescriptionService: ItemDescriptionService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: []
    });

    const buildingID = parseInt(this.activatedRoute.snapshot.paramMap.get('buildingID'), 10);

    this._getBuilding(buildingID);

    this.itemTypeService.findByValue(4).subscribe((result: ItemType) => {
      this.itemType = result;
      this.entityForm.addControl('itemType', this.fb.control(this.itemType.id));

      this.updateFamily();
    });
  }

  private _getBuilding(buildingID: number) {
    this.buildingService.find(buildingID).subscribe((building: Item) => {
      this.building = building;
      this.local = new Item();
      this.local.parent = building;

      this.cd.detectChanges();
    });
  }

  submit() {
    const entity = FormHelper.buildEntity(this.local, this.entityForm, {}) as Item;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.localService.postFromParent(entity, this.building.id),
      (result: Item) => {
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('local_id', {id: result.id});
      }
    )
  }

  updateFamily() {
    if (this.entityForm.contains('family')) {
      this.entityForm.removeControl('family');
    }
    this.familyOptions = null;

    this.cd.detectChanges();
    this.entityForm.addControl('family', this.fb.control(null));
    this.familyOptions = {get: () => this.familyService.findAllByItemItem(this.itemType.value), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }

  updateCategory() {
    const familyID = this.entityForm.get('family').value;

    if (this.entityForm.contains('category')) {
      this.entityForm.removeControl('category');
    }
    this.categoryOptions = null;
    if (!familyID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('category', this.fb.control(null));
    this.categoryOptions = {get: () => this.categoryService.findAllByParent(familyID), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }

  updateItemCategory() {
    const categoryID = this.entityForm.get('category').value;

    if (this.entityForm.contains('itemDescription')) {
      this.entityForm.removeControl('itemDescription');
    }
    this.itemDescriptionOptions = null;
    if (!categoryID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('itemDescription', this.fb.control(null));
    this.itemDescriptionOptions = {get: () => this.itemDescriptionService.findByCategory(categoryID), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }
}
