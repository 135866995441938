import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Anomaly} from '../../../core/models/anomaly.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {AnomalyDataService} from '../../../core/api/anomaly-data.service';
import {AnomalyService} from '../../../core/api/anomaly.service';

@Component({
  selector: 'esomus-anomaly-view',
  templateUrl: './anomaly-view.component.html',
  styleUrls: ['./anomaly-view.component.sass']
})
export class AnomalyViewComponent implements OnInit {

  anomaly: Anomaly;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private anomalyService: AnomalyService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private anomalyDataService: AnomalyDataService,
  ) { }

  ngOnInit() {
    const anomalyID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getAnomaly(anomalyID);
  }

  private _getAnomaly(anomalyID: number) {
    this.anomalyService.find(anomalyID).subscribe((anomaly: Anomaly) => {
      this.anomaly = anomaly;

      this.cd.detectChanges();
    });
  }

  getFetchDataURL(formID: number) {
    return this.anomalyDataService.getData(this.anomaly.id, formID);
  }
}
