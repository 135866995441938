import {Entity} from './entity.model';
import {Category} from './category.model';
import {ObligationType} from './obligation-type.model';

export class Obligation extends Entity {
  label: string;
  active: boolean;
  required: boolean;
  negation: boolean;
  category: Category;
  obligationType: ObligationType;
}
