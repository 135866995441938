import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Accident} from '../../../core/models/accident.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AccidentService} from '../../../core/api/accident.service';
import {UserService} from '../../../core/api/user.service';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {AccidentDataService} from '../../../core/api/accident-data.service';

@Component({
  selector: 'esomus-accident-view',
  templateUrl: './accident-view.component.html',
  styleUrls: ['./accident-view.component.sass']
})
export class AccidentViewComponent implements OnInit {

  accident: Accident;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private accidentService: AccidentService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentDataService: AccidentDataService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      const accidentID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

      this._getAccident(accidentID);
    });
  }

  private _getAccident(id: number) {
    this.accidentService.find(id).subscribe((accident: Accident) => {
      this.accident = accident;

      this.cd.detectChanges();
    });
  }

  getFetchDataURL(formID: number) {
    return this.accidentDataService.getData(this.accident.id, formID);
  }
}
