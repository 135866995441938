import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {AccidentType} from '../models/accident-type.model';

const crudPath: CRUDPath = {
  many: `/accident_types/`,
  single: (id: number) => `/accident_types/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AccidentTypeService extends CRUD<AccidentType> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
