<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{label}} : {{nbEquipments}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <esomus-table [options]="equipmentTable" [searchReceiver]="search"
                (getLength)="updateNbEquipments($event)"></esomus-table>
</mat-expansion-panel>

<ng-template #familyCategoryCell let-row>
  <span>{{row.family.label}} / {{row.category.label}}</span>
</ng-template>

<ng-template #headerTable>
  <mat-checkbox [checked]="true" (change)="equipmentChange($event)">Afficher les actifs
  </mat-checkbox>
</ng-template>

<esomus-equipment-create-modal *ngIf="showModalPerson"
                               [assignedTo]="person"></esomus-equipment-create-modal>
