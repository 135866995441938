import {Params} from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Entity} from '../models/entity.model';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {toJSONWithoutMs} from '../utils.function';

export abstract class CRUD<K extends Entity> {

  protected http: HttpWrapperService;
  protected crudPath: CRUDPath;

  constructor(
    http: HttpWrapperService,
    crudPath: CRUDPath
  ) {
    this.http = http;
    this.crudPath = crudPath;
  }

  /**
   * GET all
   */
  findAll(subUrl: string = '', params?: Params): Observable<Array<K>> {
    return this.http.get<Array<K>>(`${environment.api.url}${subUrl}${this.crudPath.many}`, params);
  }

  /**
   * GET by parameters
   */
  findBy(parameters: Params, subUrl: string = ''): Observable<Array<K>> {
    if (this.crudPath.filter) {
      return this.http.get<Array<K>>(`${environment.api.url}${subUrl}${this.crudPath.filter}`, parameters);
    }
    return of([]);
  }

  /**
   * GET one
   */
  find(id: number, subUrl: string = '', params?: Params): Observable<K> {
    return this.http.get<K>(`${environment.api.url}${subUrl}${this.crudPath.single(id)}`, params);
  }

  /**
   * POST
   * @param body K
   */
  post(entity: K, subUrl: string = ''): Observable<K> {
    const body = this._createFormData(entity);
    return this.http.post<K>(`${environment.api.url}${subUrl}${this.crudPath.many}`, body);
  }

  /**
   * POST
   * @param body K
   */
  enable(id: number, subUrl: string = ''): Observable<K> {
    return this.http.post<K>(`${environment.api.url}${subUrl}${this.crudPath.single(id)}/enable`, null);
  }

  /**
   * PUT
   * @param body K
   */
  put(entity: K, subUrl: string = ''): Observable<K> {
    const body = this._createFormData(entity);
    // For PHP Symfony
    body.set('_method', 'PUT');
    return this.http.put<K>(`${environment.api.url}${subUrl}${this.crudPath.single(entity.id)}`, body);
  }

  /**
   * DELETE
   * @param url string
   */
  delete(id: number, subUrl: string = ''): Observable<void> {
    return this.http.delete<void>(`${environment.api.url}${subUrl}${this.crudPath.single(id)}`);
  }

  protected _createFormData(entity: K) {
    const body = new FormData();

    this._appendProperties(body, entity, null);

    return body;
  }

  private _appendProperties(body: FormData, entity: any, baseProp: string|null) {
    for (const property in entity) {
      if (entity.hasOwnProperty(property)) {
        const value = entity[property];
        if (value instanceof File) {
          body.append(this._generatePropName(baseProp, property, null), value, value.name);
        } else if (value instanceof Date) {
          body.append(this._generatePropName(baseProp, property, null), toJSONWithoutMs(value));
        } else if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            if (typeof value[i] === 'object') {
              this._appendProperties(body, value[i], this._generatePropName(baseProp, property, i));
            } else {
              body.append(this._generatePropName(baseProp, property, i), `${value[i]}`);
            }
          }
        } else if (value !== null && typeof value === 'object') {
          this._appendProperties(body, value, this._generatePropName(baseProp, property, null));
        } else {
          body.append(this._generatePropName(baseProp, property, null), `${entity[property]}`);
        }
      }
    }
  }

  private _generatePropName(baseProp: string|null, property: string, i: number|null): string {
    let name: string;

    if (baseProp === null) {
      name = property;
    } else {
      name = `${baseProp}[${property}]`;
    }

    if (i !== null && i !== undefined) {
      name += `[${i}]`;
    }

    return name;
  }
}

export interface CRUDPath {
  many: string;
  single: (id: number) => string;
  filter?: string;
}

export enum CRUDType {
  CREATE = 'C',
  READ = 'R',
  UPDATE = 'U',
  DELETE = 'D'
}
