import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'esomus-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.sass']
})
export class BackButtonComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  click() {
    this.location.back();
  }
}
