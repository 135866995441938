import { Component, OnInit } from '@angular/core';
import {ColumnSortDirection, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {ImpactService} from '../../../core/api/impact.service';
import {Impact} from '../../../core/models/impact.model';
import {AccidentHelper} from '../../../core/services/accident-helper.service';

@Component({
  selector: 'esomus-impact-list',
  templateUrl: './impact-list.component.html',
  styleUrls: ['./impact-list.component.sass']
})
export class ImpactListComponent implements OnInit {
  impactsTable: TableOptions;

  constructor(
    private impactService: ImpactService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.impactsTable = {
      columnDefs: [
        {
          prop: 'description', width: '65%',
          name: this.i18n({value: 'Description', id: 'description'})
        },
        {
          prop: 'severity', width: '30%', valueCb: (item: Impact) => AccidentHelper.getSeverityLabel(item.severity),
          name: this.i18n({value: 'Gravité', id: 'severity'})
        },
      ],
      findDataCb: (searchData: Params) => this.impactService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un impact', id: 'btnAddImpact'}),
            createCb: () => this.routeName.goTo('impact_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('impact_id', {id: entity.id});
        },
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }
}
