import {Injectable} from '@angular/core';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Parameter} from '../models/parameter.model';
import {CRUDPath} from './crud';
import {ParameterEnum} from '../enum/parameter.enum';
import {from, Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {map, mergeMap, switchMap, tap} from 'rxjs/operators';
import * as localForage from 'localforage';


const crudPath: CRUDPath = {
  many: `/parameters/`,
  single: (id: number) => `/parameters/${id}`,
  filter: `/parameters/`
};

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  private paramStore: LocalForage;

  constructor(
    private http: HttpWrapperService
  ) {
    this.paramStore = localForage.createInstance({
      name: 'EsomusStorage',
      storeName: 'Parameters'
    });

  }

  /**
   * GET list of parameters
   */
  getParameters(paramName: ParameterEnum): Observable<Parameter[]> {

    return from(this.paramStore.getItem<Parameter[]>(paramName))
      .pipe(
        mergeMap((value: Parameter[]) => {
          if (value) {
            return of(value);
          }
          return this.http.get<ParameterPayload>(`${environment.api.url}${crudPath.filter}`, {
            param: paramName
          }).pipe(
            map((parameter: ParameterPayload) => {
              return parameter[paramName];
            }),
            tap((parameters: Parameter[]) => {
              this.paramStore.setItem<Parameter[]>(paramName, parameters);
            })
          );
        })
      );
  }

  /**
   * GET one parameter
   */
  getParameter(paramName: ParameterEnum, paramID: number): Observable<Parameter> {
    return this.getParameters(paramName)
      .pipe(
          switchMap((params: Parameter[]) => {
            return of(params.find((param: Parameter) => param.id === paramID));
          })
      );
  }

  clear() {
    this.paramStore.clear();
  }
}

export interface ParameterPayload {
  [paramName: string]: Parameter[];
}
