import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {Item} from '../../../core/models/item.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {CategoryService} from '../../../core/api/category.service';
import {ItemStatusService} from '../../../core/api/item-status.service';
import {UserService} from '../../../core/api/user.service';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {SiteService} from '../../../core/api/site.service';
import {FormsMap, FormsMappingMap} from '../../../forms/components/form-component/form-component.component';
import {ItemDataService} from '../../../core/api/item-data.service';

@Component({
  selector: 'esomus-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.sass']
})
export class SiteComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;

  site: Item;

  selectOptions: any;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];

  nbForms: number;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private siteService: SiteService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private itemStatusService: ItemStatusService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private itemDataService: ItemDataService
  ) {
    this.selectOptions = {};
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [],
      comment: [],
      'picture.upload': [],
      code: [],
      'address.country': [],
      'address.locality': [],
      'address.zipCode': [],
      'address.line1': [],
      'address.coordinates': [],
    });

    const siteID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getSite(siteID);
  }

  private _getSite(id: number) {
    this.siteService.find(id).subscribe((site: Item) => {
      this.site = site;

      if (this.site.category.form || this.site.itemDescription.form) {
        this.forms = {};
        this.formsMapping = {};
      }

      FormHelper.initValues(this.site, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    this.nbForms = 1;
    this.formsValidation = [];

    for (const key in this.forms) {
      this.nbForms++;
      if (this.forms[key].invalid) {
        return;
      }
    }

    for (const key in this.formsMapping) {
      const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});
      FormHelper.submitForm(
        this.cd,
        this.forms[key],
        this.itemDataService.putData(entityData, this.site.id, parseInt(key.substr(5), 10)),
        () => {
          this.formsValidation.push(true);
          this._handleFormsSubmit();
        }
      );
    }

    let entity = FormHelper.buildEntity(this.site, this.entityForm, {
      'picture.upload': {type: FieldType.FILE, multiple: false}
    }) as Item;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.siteService.put(entity),
      () => {
        this.formsValidation.push(true);
        this._handleFormsSubmit();
      }
    );
  }


  private _handleFormsSubmit() {
    if (this.formsValidation.length === this.nbForms) {
      this.toastrService.open(ToastrMessageType.UPDATE);
      this.entityForm.reset();
      this.routeNameService.goTo('site_view', {id: this.site.id});
    }
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.site.id, formID);
  }

}
