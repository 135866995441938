import {Inject, Injectable} from '@angular/core';
import {ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MENU_SIDENAV, SidenavMenu} from './menu.provider';
import {SidenavItem} from './sidenav-item/sidenav-item.model';
import {RouteNameService} from '../../../../../core/services/route-name.service';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenavMenu: SidenavMenu;

  private itemsMap: ItemsMap;

  /**
   * Array of itemID
   */
  private itemsOpened: string[];

  private itemsRoute: ItemsRoute;

  private currentRouteName: string;

  private static _sortMenuItem(itemA: SidenavItem, itemB: SidenavItem) {
    // if (itemA.position < itemB.position) {
    //     return -1;
    // }
    // if (itemA.position > itemB.position) {
    //     return 1;
    // }
    return 0;
  }

  private static _addItemOpened(item: SidenavItem, itemsOpened: string[]) {
    item.isOpen = true;
    if (!itemsOpened.includes(item.id)) {
      itemsOpened.push(item.id);
    }
    if (item.parent) {
      SidenavService._addItemOpened(item.parent, itemsOpened);
    }
  }

  constructor(
    @Inject(MENU_SIDENAV) private menu: SidenavMenu,
    private router: Router,
    private routeNameService: RouteNameService
  ) {
    this.itemsMap = {};
    this.itemsRoute = {};
    this.itemsOpened = [];
    this.sidenavMenu = this._createSubItem(menu, null);

    this.router.events
      .pipe(filter((e: any) => e instanceof ActivationEnd || e instanceof NavigationEnd))
      .subscribe((e: ActivationEnd | NavigationEnd) => {
        if (e instanceof ActivationEnd) {
          const routeName = e.snapshot.data.routeName;
          if (routeName) {
            this.currentRouteName = routeName;
          }
          return;
        }
        if (!this.currentRouteName || !this.itemsRoute[this.currentRouteName]) {
          return;
        }

        // Remove previous items opened
        for (const itemID of this.itemsOpened) {
          this.itemsMap[itemID].isOpen = false;
        }
        this.itemsOpened = [];

        // Add new items
        for (const itemID of this.itemsRoute[this.currentRouteName]) {
          SidenavService._addItemOpened(this.itemsMap[itemID], this.itemsOpened);
        }
      });
  }

  getSidenavItems() {
    return Object.values(this.itemsMap);
  }

  getSidenavMenu() {
    return this.sidenavMenu;
  }

  /**
   * Return boolean => open/close
   */
  toggleItem(itemID: string) {
    const itemIndex = this.itemsOpened.findIndex((id: string) => id === itemID);
    // open
    if (itemIndex === -1) {
      this.itemsMap[itemID].isOpen = true;
      this.itemsOpened.push(itemID);
      return true;
    }
    this.itemsMap[itemID].isOpen = false;

    this.itemsOpened.splice(itemIndex, 1);
    return false;
  }

  private _createSubItem(menuItems: SidenavMenu, parent: SidenavItem) {
    let menuTemp = [];
    for (const menuItem of menuItems) {

      let sidenavItem = new SidenavItem(menuItem);

      sidenavItem.parent = parent;
      sidenavItem.id = `${(parent) ? parent.id : 0}${menuTemp.length}`;

      // if no position
      if (!sidenavItem.position) {
        sidenavItem.position = menuTemp.length;
      }

      // RouteName => Path
      if (sidenavItem.routeName) {
        sidenavItem.route = this.routeNameService.path(sidenavItem.routeName);
      }

      if (Array.isArray(sidenavItem.subItems)) {
        sidenavItem.subItems = this._createSubItem(sidenavItem.subItems, sidenavItem);
      } else {
        sidenavItem.subItems = [];
      }

      // add in map
      this.itemsMap[sidenavItem.id] = sidenavItem;
      if (this.itemsRoute[sidenavItem.routeName] === undefined) {
        this.itemsRoute[sidenavItem.routeName] = [];
      }
      this.itemsRoute[sidenavItem.routeName].push(sidenavItem.id);
      menuTemp.push(sidenavItem);
    }

    return menuTemp.sort(SidenavService._sortMenuItem);
  }
}

interface ItemsMap {
  [itemID: string]: SidenavItem;
}

interface ItemsRoute {
  [route: string]: string[];
}
