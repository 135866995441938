import {Params} from '@angular/router';

export class AddressHelper {

  constructor() {
  }

  static getTypeOptions() {
    return [
      {id: 0, label: 'Personnelle'}, {id: 1, label: 'Travail'},
    ];
  }

  static getTypeLabel(type: number) {
    let value = this.getTypeOptions().find((item: Params) => item.id === type);

    if (value) {
      return value.label;
    }

    return null;
  }
}
