import {Component, EventEmitter, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {TaskService} from '../../../core/api/task.service';
import {Entity} from '../../../core/models/entity.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Params} from '@angular/router';
import {Task} from '../../../core/models/task.model';
import {TaskHelper} from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.sass']
})
export class TaskListComponent implements OnInit {

  taskTable: TableOptions;
  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private taskService: TaskService,
  ) {
  }

  ngOnInit() {
    this.taskTable = {
      columnDefs: [
        {
          name: this.i18n({value: 'Clôture', id: 'closed'}),
          prop: 'closed', type: ColumnType.CHECKBOX, width: '5%'
        },
        {
          name: this.i18n({value: 'Canevas', id: 'definition'}),
          prop: 'definition', type: ColumnType.CHECKBOX, width: '5%'
        },
        {
          name: this.i18n({value: 'Type', id: 'type'}),
          prop: 'taskType.label', width: '15%'
        },
        {
          name: this.i18n({value: 'Description', id: 'description'}),
          prop: 'description', width: '30%'
        },
        {
          name: this.i18n({value: 'Date butoir', id: 'deadlineDate'}),
          prop: 'deadlineDate', width: '20%', type: ColumnType.DATE
        },
        {
          name: this.i18n({value: 'Urgence', id: 'emergency'}),
          prop: 'emergency', width: '10%', valueCb: (task: Task) => TaskHelper.getLabel(task.emergency)
        },
        {
          name: this.i18n({value: 'Statut', id: 'status'}),
          prop: 'status', width: '10%', valueCb: (task: Task) => TaskHelper.getTaskStatusLabel(task.status)
        },
      ],
      findDataCb: (searchData: Params) => {
        const params = {};
        if (searchData.hasOwnProperty('definition') && searchData['definition']) {
          params['definition'] = true;
        }
        return this.taskService.findAll('', params);
      },
      actions: {
        create: [
          {
            btnName: 'Ajouter une tâche',
            createCb: () => {
              this.routeNameService.goTo('task_add');
            }
          },
        ],
        readCb: (entity: Entity) => this.routeNameService.goTo('task_view', {id: entity.id}),
      },
      headTemplate: this.headerTable
    };
  }


  definitionChange(event: MatCheckboxChange) {
    this.search.emit({
      definition: event.checked
    });
  }
}
