<div class="toolbar" fxLayout="row">

  <div fxLayout="row" fxLayoutAlign="start center" fxHide.gt-sm>
    <button type="button" mat-icon-button (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="logo-contrast" *ngIf="sidenavCollapsed" fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-sm>
    <img class="logo" alt="logo" src="assets/img/esomus-logo.png">
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="start center" fxFlex fxLayoutGap="5px grid">
    <form fxLayout="column" fxFlex="50">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="start center" fxFlex fxLayoutGap="5px grid">
        <esomus-input [type]="fieldType.TEXT" fxFlex="50" flex="100" label="Rechercher" i18n-label="@@search"
                      [control]="searchForm.get('searchText')"></esomus-input>
        <div fxFlex>
          <button type="submit" color="accent" mat-flat-button (click)="search()"><mat-icon>search</mat-icon></button>
        </div>
      </div>
    </form>
    <div fxLayout="column" fxFlex="50">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.gt-sm="end center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset.gt-sm="24px">
          <div fxFlex>
            <span><small>{{env.version}}</small></span>
          </div>
          <!-- <esomus-search fxShow fxHide.gt-sm></esomus-search> -->

          <esomus-toolbar-notifications></esomus-toolbar-notifications>

          <esomus-toolbar-user-button></esomus-toolbar-user-button>

          <!-- <button mat-button class="quickpanel-button icon-button" [class.open]="quickpanelOpen"
            (click)="toggleQuickpanel()">
            <mat-icon class="icon">format_align_right</mat-icon>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</div>
