import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Observable} from 'rxjs';
import {Params} from '@angular/router';
import {Address} from '../models/address.model';

const crudPath: CRUDPath = {
  many: `/addresses/`,
  single: (id: number) => `/addresses/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class AddressService extends CRUD<Address> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllByPerson(personID: number, params?: Params): Observable<Array<Address>> {
    return super.findAll(`/persons/${personID}`, params);
  }

  findByParent(personID: number, addressID: number, params?: Params): Observable<Address> {
    return super.find(addressID, `/persons/${personID}`);
  }

  postByParent(personID: number, entity: Address): Observable<Address> {
    return super.post(entity, `/persons/${personID}`);
  }

  putByParent(personID: number, entity: Address): Observable<Address> {
    return super.put(entity, `/persons/${personID}`);
  }
}
