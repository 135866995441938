<form>
  <mat-accordion class="mat-edit" multi="true">
    <mat-expansion-panel expanded="true" *ngIf="itemDescription">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Fiche: {{itemDescription.id ? 'Edition' : 'Création'}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <div fxFlex="100" class="image-container">
                <esomus-img *ngIf="itemDescription.id" fxFlex="100"
                            [picture]="itemDescription.picture"></esomus-img>
              </div>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('code')"
                            i18n-label="@@esomusCode"
                            label="Code esomus" fxFlex="100" flex="100"></esomus-input>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div fxLayout="column" fxFlexOffset="80" fxFlex="20" fxFlex.xs="100">
                <esomus-info-box *ngIf="itemDescription.id"
                                 [active]="itemDescription.active"></esomus-info-box>
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-ng-select *ngIf="itemTypeOptions" [options]="itemTypeOptions"
                                [control]="entityForm.get('itemType')"
                                i18n-label="@@itemType" label="Thématique" fxFlex="100"
                                (valueChange)="updateFamily()"
                                flex="50"></esomus-ng-select>
              <esomus-ng-select *ngIf="familyOptions" [options]="familyOptions"
                                [control]="entityForm.get('family')"
                                i18n-label="@@family" label="Famille" fxFlex="100"
                                (valueChange)="updateCategory()"
                                flex="50"></esomus-ng-select>
              <esomus-ng-select *ngIf="categoryOptions" [options]="categoryOptions"
                                [control]="entityForm.get('category')"
                                i18n-label="@@category" label="Catégorie" fxFlex="100"
                                flex="50"></esomus-ng-select>
              <ng-container *ngIf="category">
                <esomus-line-data flex="50" fxFlex="100" boldValue="Thématique"
                                  [textValue]="category.family.itemType ? category.family.itemType.label : null"></esomus-line-data>
                <esomus-line-data flex="50" fxFlex="100" boldValue="Famille"
                                  [textValue]="category.family.label"></esomus-line-data>
                <esomus-line-data flex="50" fxFlex="100" boldValue="Catégorie"
                                  [textValue]="category.label"></esomus-line-data>
              </ng-container>
              <ng-container *ngIf="itemDescription.id">
                <esomus-line-data flex="50" fxFlex="100" boldValue="Thématique"
                                  [textValue]="itemDescription.itemType ? itemDescription.itemType.label : null"></esomus-line-data>
                <esomus-line-data flex="50" fxFlex="100" boldValue="Famille"
                                  [textValue]="itemDescription.family.label"></esomus-line-data>
                <esomus-line-data flex="50" fxFlex="100" boldValue="Catégorie"
                                  [textValue]="itemDescription.category.label"></esomus-line-data>
              </ng-container>
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('label')"
                            i18n-label="@@label"
                            label="Libellé" fxFlex="100" flex="50"></esomus-input>
              <esomus-ng-select *ngIf="entityForm.get('form')" [options]="formOptions"
                                [control]="entityForm.get('form')"
                                i18n-label="@@form" label="Formulaire" fxFlex="100"
                                flex="50"></esomus-ng-select>
              <esomus-line-data *ngIf="!entityForm.get('form')" flex="50" fxFlex="100"
                                boldValue="Formulaire"
                                [textValue]="itemDescription.form.name"></esomus-line-data>
              <esomus-input *ngIf="isEquipment" [type]="fieldType.TEXT"
                            [control]="entityForm.get('model')"
                            i18n-label="@@model"
                            label="Modèle" fxFlex="100" flex="50"></esomus-input>
              <esomus-input *ngIf="isEquipment" [type]="fieldType.TEXT"
                            [control]="entityForm.get('type')"
                            i18n-label="@@type"
                            label="Type" fxFlex="100" flex="50"></esomus-input>
              <div fxFlex="225px">
                <mat-checkbox *ngIf="isEquipment || isBuilding"
                              [formControl]="entityForm.get('controllable')">
                  Contrôlable
                </mat-checkbox>
              </div>
              <div fxFlex="225px">
                <mat-checkbox *ngIf="isEquipment" [formControl]="entityForm.get('ceCertification')">
                  Certification CE
                </mat-checkbox>
              </div>
              <esomus-input *ngIf="isEquipment" [type]="fieldType.NUMBER"
                            [control]="entityForm.get('duration')"
                            i18n-label="@@duration"
                            label="Durée" fxFlex="100" flex="50"></esomus-input>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="itemDescription" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Risques et obligations
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-textarea [control]="entityForm.get('obligationRisk')"
                               i18n-label="@@obligationRisk"
                               label="Risque" flex="100" fxFlex="100"></esomus-textarea>
              <esomus-textarea [control]="entityForm.get('obligationEquipments')"
                               i18n-label="@@obligationEquipments"
                               label="Equipements" flex="100" fxFlex="100"></esomus-textarea>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-ng-select *ngIf="obligationOptions && entityForm.get('obligations')"
                                [options]="obligationOptions" [multiple]="true"
                                [control]="entityForm.get('obligations')"
                                i18n-label="@@obligations" label="Obligations" fxFlex="100"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="itemDescription && itemDescription.id && (itemDescription.category.form || itemDescription.form) && forms"
      expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Information complémentaire
        </mat-panel-title>
      </mat-expansion-panel-header>
      <esomus-form-component
        *ngIf="itemDescription && itemDescription.id && itemDescription.category.form"
        [formID]="itemDescription.category.form.id" [forms]="forms" [formsMapping]="formsMapping"
        [fetchDataURL]="getFetchDataURL(itemDescription.category.form.id)"></esomus-form-component>
      <esomus-form-component *ngIf="itemDescription && itemDescription.id && itemDescription.form"
                             [formID]="itemDescription.form.id" [forms]="forms"
                             [formsMapping]="formsMapping"
                             [fetchDataURL]="getFetchDataURL(itemDescription.form.id)"></esomus-form-component>
    </mat-expansion-panel>
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div class="btn-group-left" fxFlex="50">
        <esomus-delete-button
          *ngIf="itemDescription && itemDescription.id && itemDescription.active"
          [url]="getItemDescriptionDeleteURL()"
          [successUrl]="getItemDescriptionSuccessURL()"></esomus-delete-button>
        <esomus-enable-button
          *ngIf="itemDescription && itemDescription.id && !itemDescription.active"
          [url]="getItemDescriptionEnableURL()"
          [successUrl]="getItemDescriptionSuccessURL()"
        ></esomus-enable-button>
      </div>
      <div class="btn-group-right" fxFlex="50">
        <esomus-back-button></esomus-back-button>
        <esomus-submit (submit)="submit()"></esomus-submit>
      </div>
    </div>
  </mat-accordion>
</form>
