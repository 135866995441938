<mat-accordion multi="true" *ngIf="action">
  <esomus-equipment-panel [expanded]="false" [panelDisabled]="true"
                          [equipment]="getEquipment()"></esomus-equipment-panel>
  <esomus-anomaly-panel *ngIf="action.anomaly" [anomaly]="action.anomaly" ></esomus-anomaly-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Action
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div *ngIf="!action.closed && action.task === null" fxLayoutAlign="center center">
          <button mat-flat-button (click)="editAction()" color="success">
            <mat-icon>edit</mat-icon> Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="19" [itemID]="action.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img *ngIf="action.executionPicture" fxFlex="100" [picture]="action.executionPicture"></esomus-img>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data *ngIf="action.linkType === 3" fxFlex="100" flex="100" [boldValue]="'Origine action'"
                                  [textValue]="getTaskOrigin()" [textURL]="getTaskURL()"></esomus-line-data>
                <esomus-line-data *ngIf="action.linkType !== 3" fxFlex="100" flex="100" [boldValue]="'Origine action'"
                                  [textValue]="getOrigin()"></esomus-line-data>
                <esomus-line-data *ngIf="action.anomaly" fxFlex="100" flex="100"
                                  [boldValue]="'Element lié'"
                                  [textValue]="action.anomaly.item.label"></esomus-line-data>
                <esomus-line-data *ngIf="action.item && !action.anomaly" fxFlex="100" flex="100"
                                  [boldValue]="'Element lié'"
                                  [textValue]="action.item.label"></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Type"
                                  [textValue]="action.taskType.label"></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Description"
                                  [textValue]="action.description"></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Commentaire"
                                  [textValue]="action.comment"></esomus-line-data>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data fxFlex="100" flex="100"
                                  [boldValue]="'Date butoir'" [textType]="'date'"
                                  [textValue]="action.deadlineDate"></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Urgence"
                                  [textValue]="getEmergencyLabel()"></esomus-line-data>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="20" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-info-box fxFlex="100" [line1]="getStatus()" [date1]="action.executionDate" [color]="action.status === 0 ? 'warning' : 'success'"></esomus-info-box>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
          </div>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data fxFlex="100" flex="100" [boldValue]="action.closed ? 'Réalisé par' : 'Attribué à'"
                                  [textValue]="action.person ? action.person.fullName : null"></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Planifié pour le"
                                  [textValue]="action.plannedDate" textType="date"></esomus-line-data>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Réalisation
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div *ngIf="!action.closed" fxFlex="100" class="mb-10">
          <button mat-raised-button (click)="encode()" color="success">
            <mat-icon>edit</mat-icon>
            Encoder réalisation
          </button>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data fxFlex="100" flex="100"
                                  [boldValue]="'Réalisé le'" [textType]="'date'"
                                  [textValue]="action.executionDate"></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Commentaire"
                                  [textValue]="action.executionComment"></esomus-line-data>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                  <esomus-form-display *ngIf="action.form" [fetchDataURL]="getFetchDataURL(action.form.id)"></esomus-form-display>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
              <div fxLayout="row wrap" fxLayoutAlign="start">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [parent]="action" parentType="action"></esomus-document-table>
</mat-accordion>
