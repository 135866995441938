import {Pipe, PipeTransform} from '@angular/core';
import {RouteNameService} from '../../core/services/route-name.service';

@Pipe({
  name: 'path'
})
export class RouteNamePipe implements PipeTransform {

  constructor(
    private routeNameService: RouteNameService
  ) {
  }

  transform(routeName: string, params?: { [param: string]: string | number }): string {
    return this.routeNameService.path(routeName, params);
  }
}
