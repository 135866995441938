import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

const regexParam = /:[a-z]+/ig;

@Injectable({
  providedIn: 'root'
})
export class RouteNameService {

  private routeNameMap = {};
  private titles: TitleRegex[] = [];

  constructor(
    private router: Router,
    private titleService: Title,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  /**
   * Get the full path according to routeName
   */
  path(routeName: string, params?: { [param: string]: string | number }): string {
    let path = this.routeNameMap[routeName];
    if (!path) {
      this._createRouteName(this.router.config);
      path = this.routeNameMap[routeName];
      if (!path) {
        return '/';
      }

    }
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        path = path.replace(':' + param, params[param]);
      }
    }
    return path;
  }

  /**
   * Go to a route via its name
   */
  goTo(routeName, params: { [param: string]: string | number } = {}, queryParams: object = {}): void {
    this.router.navigate([this.path(routeName, params)], { queryParams });
  }

  /**
   * Set page title
   */
  setTitle(title: string) {
    this.titleService.setTitle(environment.appName + ' - ' + title);
  }

  private _createRouteName(routes, fullPath: string = '') {
    for (const route of routes) {
      this._createFullPath(route, fullPath);
    }
  }

  private _createFullPath(route, fullPath: string): void {
    if (route.path && route.path === '**') {
      return;
    }

    if (route.path.length) {
      fullPath += '/' + route.path;
    }

    if (!route.data || !route.data.routeName) {
      if (route._loadedConfig) {
        this._createRouteName(route._loadedConfig.routes, fullPath);
      } else if (route.children) {
        this._createRouteName(route.children, fullPath);
      }
    } else {
      this.routeNameMap[route.data.routeName] = fullPath;
      if (route.data.title) {
        const regex = fullPath.replace(regexParam, '[a-z0-9]+');
        const titleRegex = {
          regex: new RegExp(`^${regex}(\\?[\\w=&%]+)?$`, 'i'),
          title: route.data.title
        };
        this.titles.push(titleRegex);
      }
    }
  }
}

interface TitleRegex {
  regex: RegExp;
  title: string;
}
