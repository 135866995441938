import {Component, OnInit} from '@angular/core';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Entity} from '../../../core/models/entity.model';
import {UserService} from '../../../core/api/user.service';
import {User} from '../../../core/models/user.model';
import {UserHelper} from '../../../core/services/user-helper.service';

@Component({
  selector: 'esomus-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.sass']
})
export class UserListComponent implements OnInit {

  userTable: TableOptions;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.userTable = {
      columnDefs: [
        {
          name: this.i18n({value: 'Username', id: 'username'}),
          prop: 'username',
        },
        {
          name: this.i18n({value: 'Email', id: 'email'}),
          prop: 'email',
        },
        {
          name: this.i18n({value: 'Actif', id: 'active'}),
          prop: 'active', type: ColumnType.CHECKBOX
        },
        {
          name: this.i18n({value: 'Light', id: 'light'}),
          prop: 'light', type: ColumnType.CHECKBOX
        },
        {
          name: this.i18n({value: 'Langue', id: 'language'}),
          prop: 'language', valueCb: (item: User) => UserHelper.getLanguageLabel(item.language)
        },
        {
          name: this.i18n({value: 'Dernière connexion', id: 'lastLogin'}),
          prop: 'lastLogin', type: ColumnType.DATE
        },
      ],
      findDataCb: () => this.userService.findAll(),
      actions: {
        create: [
          {
            btnName: 'Ajouter un utilisateur',
            createCb: () => {
              this.routeNameService.goTo('user_add');
            }
          },
        ],
        readCb: (entity: Entity) => this.routeNameService.goTo('user_view', {id: entity.id}),
      }
    };
  }

}
