<div *ngIf="item.route; then route else function"></div>

<ng-template #function>
  <a class="sidenav-item" [class.open]="item.isOpen" matRipple (click)="item.route" fxLayout="row"
    fxLayoutAlign="start center" *ngIf="!item.subItems.length">

    <span class="name">{{ item.name }}</span>
    <span fxFlex></span>
    <mat-icon [class.parent-icon]="!item.parent" [class.sub-item-icon]="!!item.parent" *ngIf="item.icon">
      {{ item.icon }}</mat-icon>
    <!-- <div class="letter-icon" [class.parent-icon]="!item.hasParent()" [class.sub-item-icon]="item.hasParent()"
      *ngIf="!item.icon && item.hasParent()">{{ item.generateLetterIcon() }}</div> -->
    <!--<span class="badge fade-in-on-icon-sidenav" *ngIf="item.badge" [style.background-color]="item.badgeColor">{{ item.badge }}</span>-->
  </a>
</ng-template>

<ng-template #route>
  <a class="sidenav-item" [class.open]="item.isOpen" matRipple [routerLink]="item.route" routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}" fxLayout="row" fxLayoutAlign="start center" *ngIf="!item.subItems.length">

    <span class="name">{{ item.name }}</span>
    <span fxFlex></span>
    <mat-icon [class.parent-icon]="!item.parent" [class.sub-item-icon]="!!item.parent" *ngIf="item.icon">
      {{ item.icon }}</mat-icon>
    <!-- <div class="letter-icon" [class.parent-icon]="!item.hasParent()" [class.sub-item-icon]="item.hasParent()"
      *ngIf="!item.icon && item.hasParent()">{{ item.generateLetterIcon() }}</div> -->
  </a>
</ng-template>

<a class="sidenav-item" [class.open]="item.isOpen" matRipple (click)="toggleDropdown()" fxLayout="row"
  fxLayoutAlign="start center" *ngIf="item.subItems.length">

  <span class="name">{{ item.name }}</span>
  <span fxFlex></span>
  <mat-icon *ngIf="item.subItems.length" class="dropdown-indicator" [class.rotate]="item.isOpen">expand_more</mat-icon>
  <mat-icon [class.parent-icon]="!item.parent" [class.sub-item-icon]="!!item.parent">
    {{ item.icon }}</mat-icon>
  <!-- <div class="letter-icon" [class.parent-icon]="!item.hasParent()" [class.sub-item-icon]="item.hasParent()"
    *ngIf="!item.icon && item.hasParent()">
    {{ item.generateLetterIcon() }}</div> -->
</a>

<div class="sub-menu" [style.height]="getSubMenuHeight()">
  <esomus-sidenav-item class="sidenav-item-container" *ngFor="let subItem of item.subItems" [item]="subItem"></esomus-sidenav-item>
</div>
