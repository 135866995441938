import {Component, OnInit} from '@angular/core';
import {TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {ListComponentService} from '../../../core/api/list-component.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {ListComponent} from '../../../core/models/list-component.model';
import {ListValueService} from '../../../core/api/list-value.service';

@Component({
  selector: 'esomus-list-component',
  templateUrl: './list-component.component.html',
  styleUrls: ['./list-component.component.sass']
})
export class ListComponentComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  listComponent: ListComponent;
  valueTable: TableOptions;

  nbValues: number;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private listComponentService: ListComponentService,
    private listValueService: ListValueService,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      name: [null],
    });

    const listComponentID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    if (!isNaN(listComponentID)) {
      this.valueTable = {
        columnDefs: [
          {
            prop: 'value',
            name: this.i18n({value: 'Valeur', id: 'value'})
          },
        ],
        findDataCb: (searchData: Params) => this.listValueService.findAll(`/list_components/${listComponentID}`),
        actions: {
          create: [
            {
              btnName: this.i18n({value: 'Ajouter une valeur', id: 'btnAddList'}),
              createCb: () => this.routeNameService.goTo('value', {listComponentID})
            }
          ],
          updateCb: (entity: Entity) => {
            this.routeNameService.goTo('value_id', {listComponentID, id: entity.id});
            // this.userService.find(entity.id).subscribe((user: User) => {
            //   this.formService.updateForm(UsersComponent.userFormID, user);
            // });
          },
          // deleteCb: (entity: Entity) => this.userService.delete(entity.id)
        }
      };

      this._getForm(listComponentID);
    } else {
      this.listComponent = new ListComponent();
    }
  }

  private _getForm(listComponentID: number) {
    this.listComponentService.find(listComponentID)
      .subscribe((listComponent: ListComponent) => {
        this.listComponent = listComponent;
        FormHelper.initValues(this.listComponent, this.entityForm);
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }
    let entity = FormHelper.buildEntity(this.listComponent, this.entityForm, {}) as ListComponent;
    ((entity.id) ? this.listComponentService.put(entity) : this.listComponentService.post(entity))
      .subscribe((result: ListComponent) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        if (this.listComponent.id) {
          this.routeNameService.goTo('lists');
        } else {
          this.routeNameService.goTo('list_id', {id: result.id});
        }
      });

  }

  updateNbValues(length: number) {
    this.nbValues = length;
  }
}
