<ng-template #formModal>
  <form>
    <h3 mat-dialog-title>
      <span>Ajout d'un nouveau contact</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <esomus-ng-select *ngIf="contactOptions && contactForm.get('contact')"
                          [options]="contactOptions"
                          [control]="contactForm.get('contact')" i18n-label="@@contact"
                          label="Contact" [fxFlex]="100" flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="roleOptions && contactForm.get('role')"
                          [options]="roleOptions"
                          [control]="contactForm.get('role')" i18n-label="@@role"
                          label="Rôle" [fxFlex]="100" flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="companyOptions && contactForm.get('company')"
                          [options]="companyOptions"
                          [control]="contactForm.get('company')" i18n-label="@@company"
                          (valueChange)="updateSite()"
                          label="Entreprise" [fxFlex]="100" flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="siteOptions" [options]="siteOptions"
                          [control]="contactForm.get('site')" i18n-label="@@site"
                          label="Site" [fxFlex]="100" flex="100"></esomus-ng-select>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="submitContact()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeContact()">Annuler
      </button>
      <!--    <ng-container *ngTemplateOutlet="formFooter"></ng-container>-->
    </div>
  </form>
</ng-template>
