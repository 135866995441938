import {registerLocaleData} from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {NgModule, TRANSLATIONS} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

declare const require;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    {
      provide: TRANSLATIONS,
      useFactory: (locale) => {
        locale = locale || 'fr';
        return require(`raw-loader!../i18n/messages.${locale}.xlf`).default;
      }
    },
    I18n
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
