import { Component, OnInit } from '@angular/core';
import {ColumnSortDirection, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {AccidentTypeService} from '../../../core/api/accident-type.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-accident-type-list',
  templateUrl: './accident-type-list.component.html',
  styleUrls: ['./accident-type-list.component.sass']
})
export class AccidentTypeListComponent implements OnInit {
  accidentTypesTable: TableOptions;

  constructor(
    private accidentTypeService: AccidentTypeService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.accidentTypesTable = {
      columnDefs: [
        {
          prop: 'description', width: '50%',
          name: this.i18n({value: 'Description', id: 'description'})
        },
        {
          prop: 'group', width: '45%',
          name: this.i18n({value: 'Groupe', id: 'group'})
        },
      ],
      findDataCb: (searchData: Params) => this.accidentTypeService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un type d\'accident', id: 'btnAddAccidentType'}),
            createCb: () => this.routeName.goTo('accident_type_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('accident_type_id', {id: entity.id});
        },
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }
}
