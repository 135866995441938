<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxFlex="100" class="mb-10 ml-10">
          <button mat-raised-button (click)="createCompany()" color="success">
            <mat-icon>add</mat-icon> Nouvelle entreprise
          </button>
        </div>
        <esomus-image-box fxFlex="20%" *ngFor="let company of companies$|async" [url]="routeName.path('company_view', {id: company.id})" [imageSource]="company.picture" [text]="company.label" [comment]="company.comment"></esomus-image-box>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
