import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Item} from '../models/item.model';
import {environment} from '../../../environments/environment';
import {Params} from '@angular/router';

const crudPath: CRUDPath = {
  many: `/companies/`,
  single: (id: number) => `/companies/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends CRUD<Item>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  getAllSites(companyID: number, params?: Params) {
    return this.http.get<Array<Item>>(`${environment.api.url}${this.crudPath.single(companyID)}/sites`, params);
  }
}
