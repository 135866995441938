import {Action} from '../models/action.model';
import {Params} from '@angular/router';

export class TaskHelper {

  constructor() {
  }

  static getOrigin(action: Action) {
    switch (action.linkType) {
      case 0:
        return 'Spontanée';
      case 1:
        return 'Anomalie';
      case 2:
        return 'Accident';
      case 3:
        return 'Tâche';
      default:
        return null;
    }
  }

  static getOptions() {
    return [
      {id: 1, label: 'Faible'}, {id: 2, label: 'Moyen'}, {id: 3, label: 'Elevé'}
    ];
  }

  static getLabel(value: number) {
    if (value === null) {
      return null;
    }

    switch (value) {
      case 1:
        return 'Faible';
      case 2:
        return 'Moyen';
      case 3:
        return 'Elevé';
    }
  }

  static getSeverityOptions() {
    return [
      {id: 1, label: 'Basse'}, {id: 2, label: 'Haute'}
    ];
  }

  static getSeverityLabel(value: number) {
    if (value === null) {
      return null;
    }

    switch (value) {
      case 1:
        return 'Basse';
      case 2:
        return 'Haute';
    }
  }

  static getStatusOptions() {
    return [
      {id: 0, label: 'En cours'},
      {id: 1, label: 'Terminé'},
      {id: 2, label: 'Pas en ordre'},
      {id: 3, label: 'Pas faisable'},
      {id: 4, label: 'Annulée'},
    ];
  }

  static getStatusLabel(status: number) {
    switch (status) {
      case 0:
        return 'En cours';
      case 1:
        return 'Terminé';
      case 2:
        return 'Pas en ordre';
      case 3:
        return 'Pas faisable';
      case 4:
        return 'Annulée';
      default:
        return null;
    }
  }

  static getAnomalyStatusOptions() {
    return [
      {id: 0, label: 'En cours'},
      {id: 1, label: 'Terminé'},
      {id: 4, label: 'Annulée'},
    ];
  }

  static getAnomalyStatusLabel(status: number) {
    switch (status) {
      case 0:
        return 'En cours';
      case 1:
        return 'Terminé';
      case 4:
        return 'Annulée';
      default:
        return null;
    }
  }

  static getProgrammingFrequenceOptions() {
    return [
      {id: 0, label: 'Une seule fois'}, {id: 1, label: 'Jour'}, {id: 2, label: 'Semaine'},
      {id: 3, label: 'Mois'}, {id: 4, label: 'Année'}
    ];
  }

  static getProgrammingFrequenceLabel(frequence: number) {
    let value = this.getProgrammingFrequenceOptions().find((item: Params) => item.id === frequence);

    if (value !== null) {
      return value.label;
    }

    return null;
  }

  static getTaskStatusOptions() {
    return [
      {id: 0, label: 'En cours'}, {id: 1, label: 'En attente'},
      {id: 2, label: 'Terminé'}, {id: 3, label: 'Annulé'}
    ];
  }

  static getTaskStatusLabel(status: number) {
    let value = this.getTaskStatusOptions().find((item: Params) => item.id === status);

    if (value !== null) {
      return value.label;
    }

    return null;
  }
}
