import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {ItemSituation} from '../models/item-situation.model';
import {environment} from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/item_situations/`,
  single: (id: number) => `/item_situations/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ItemSituationService extends CRUD<ItemSituation>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllForContainerChild() {
    return this.http.get<Array<ItemSituation>>(`${environment.api.url}${this.crudPath.many}container`);
  }
}
