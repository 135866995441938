import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {AuthService} from '../../api/auth.service';
import {UserLogged} from '../../models/user-logged.model';
import {RouteNameService} from '../../services/route-name.service';

@Component({
  selector: 'esomus-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginFormSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private routerName: RouteNameService
  ) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (this.authService.getUser() !== null) {
      this.routerName.goTo('home');
      return;
    }
  }

  login(form: NgForm) {
    if (!form.submitted || this.loginForm.invalid) {
      return;
    }
    this.loginFormSubmitted = true;

    this.authService.login(
      this.loginForm.get('email').value,
      this.loginForm.get('password').value
    ).subscribe((user: UserLogged) => {
      this.loginFormSubmitted = false;
      this.routerName.goTo('home');
    }, () => {
      this.loginFormSubmitted = false;
    });
  }

}
