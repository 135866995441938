import {Component, Input, OnInit} from '@angular/core';
import {Anomaly} from '../../../core/models/anomaly.model';
import {RouteNameService} from '../../../core/services/route-name.service';
import {TaskHelper} from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-anomaly-panel',
  templateUrl: './anomaly-panel.component.html',
  styleUrls: ['./anomaly-panel.component.sass']
})
export class AnomalyPanelComponent implements OnInit {

  @Input() anomaly: Anomaly;
  @Input() expanded: boolean;
  @Input() fromAnomaly = false;

  constructor(
    private routeNameService: RouteNameService,
  ) { }

  ngOnInit() {
    if (this.expanded === null || this.expanded === undefined) {
      this.expanded = true;
    }
  }

  editAnomaly() {
    return this.routeNameService.goTo('anomaly_id', {id: this.anomaly.id});
  }

  getSeverityLabel() {
    return TaskHelper.getSeverityLabel(this.anomaly.severity);
  }

  getEmergencyLabel() {
    return TaskHelper.getLabel(this.anomaly.emergency);
  }

  getStatus() {
    return TaskHelper.getAnomalyStatusLabel(this.anomaly.status);
  }
}
