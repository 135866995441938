<mat-expansion-panel expanded="true" *ngIf="dashboard">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{dashboard.label}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxFlex="100" *ngIf="dashboard && dashboard.view" [id]="getDashboardID()"></div>
      <esomus-table fxFlex="100" *ngIf="options" [options]="options"></esomus-table>
    </div>
  </div>
</mat-expansion-panel>

<ng-template #headerTable>
  <ng-container *ngIf="headButtons && headButtons.length > 0">
    <ng-container *ngFor="let btn of headButtons">
      <button color="success" mat-flat-button (click)="headerButtonAction(btn)">
        <mat-icon>{{btn['icons']}}</mat-icon><span *ngIf="btn['label']">&nbsp;{{btn['label']}}</span>
      </button>
    </ng-container>
  </ng-container>
</ng-template>
