import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {ActivatedRoute, Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {FormBuilder} from '@angular/forms';
import {AddressService} from '../../../core/api/address.service';
import {Address} from '../../../core/models/address.model';
import {AddressHelper} from '../../../core/services/address-helper.service';

@Component({
  selector: 'esomus-address-panel',
  templateUrl: './address-panel.component.html',
  styleUrls: ['./address-panel.component.sass']
})
export class AddressPanelComponent implements OnInit {

  @Input() personID: number;

  tableEmitter: EventEmitter<any> = new EventEmitter<any>();
  showModal: boolean;
  currentAddressID: number;

  nbAddresses: number;
  addressTableOptions: TableOptions;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private addressService: AddressService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.nbAddresses = 0;

    this.addressTableOptions = {
      columnDefs: [
        {
          prop: 'type', valueCb: (entity: Address) => AddressHelper.getTypeLabel(entity.type),
          name: this.i18n({value: 'Type', id: 'type'})
        },
        {
          prop: 'description',
          name: this.i18n({value: 'Description', id: 'description'})
        },
        {
          prop: 'line1',
          name: this.i18n({value: 'Adresse', id: 'line1'})
        },
        {
          prop: 'locality',
          name: this.i18n({value: 'Ville', id: 'locality'})
        },
        {
          prop: 'zipCode',
          name: this.i18n({value: 'Code postal', id: 'zipCode'})
        },
        {
          prop: 'country',
          name: this.i18n({value: 'Pays', id: 'country'})
        },
        {
          prop: 'coordinates',
          name: this.i18n({value: 'GPS', id: 'coordinates'})
        },
        {
          prop: 'phone',
          name: this.i18n({value: 'Téléphone', id: 'phone'})
        },
        {
          prop: 'mail',
          name: this.i18n({value: 'Mail', id: 'mail'})
        },
      ],
      findDataCb: (searchData: Params) => this.addressService.findAllByPerson(this.personID),
      actions: {
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter une adresse', id: 'btnAddAddress' }),
            createCb: () => {
              this._openModal(null);
            }
          },
        ],
        updateCb: (entity: Entity) => {
          this._openModal(entity.id);
        },
      }
    };
  }

  _openModal(addressID: number) {
    this.showModal = false;
    this.cd.detectChanges();
    this.showModal = true;
    this.currentAddressID = addressID;
    this.cd.detectChanges();
  }

  updateNbAdresses(nbAddresses: number) {
    this.nbAddresses = nbAddresses;
  }
}
