import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {InputErrorComponent} from './components/input-error/input-error.component';
import {InputComponent} from './components/input/input.component';
import {SelectComponent} from './components/select/select.component';
import {SubmitComponent} from './components/submit/submit.component';
import {TextareaComponent} from './components/textarea/textarea.component';
import {FormBuilderComponent} from './form-builder.component';
import {NgSelectFormFieldControlDirective} from './components/ng-select/ng-select.directive';
import {NgSelectComponent} from './components/ng-select/ng-select.component';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {InputFileComponent} from './components/input-file/input-file.component';
import {TableComponent} from './components/table/table.component';
import {EsomusUiModule} from '../esomus-ui/esomus-ui.module';
import {AutoFocusDirective} from './auto-focus.directive';
import {AutoScrollDirective} from './auto-scroll.directive';

@NgModule({
  declarations: [
    FormBuilderComponent,
    InputComponent,
    InputFileComponent,
    InputErrorComponent,
    TextareaComponent,
    SubmitComponent,
    SelectComponent,
    NgSelectComponent,
    NgSelectFormFieldControlDirective,
    TableComponent,
    AutoFocusDirective,
    AutoScrollDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    FlexLayoutModule,
    NgSelectModule,
    MaterialFileInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    EsomusUiModule,
    MatTooltipModule
  ],
  exports: [
    FormBuilderComponent,
    InputComponent,
    TextareaComponent,
    SubmitComponent,
    TableComponent,
    NgSelectComponent,
    InputFileComponent,
    AutoScrollDirective,
    AutoFocusDirective
  ]
})
export class FormBuilderModule { }
