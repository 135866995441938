import {ChangeDetectorRef, Component, EventEmitter, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Category} from '../../../core/models/category.model';
import {Family} from '../../../core/models/family.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute, Params} from '@angular/router';
import {CategoryService} from '../../../core/api/category.service';
import {Entity} from '../../../core/models/entity.model';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {ColumnSortDirection, ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {FormsMap} from '../../../forms/components/form-component/form-component.component';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'esomus-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.sass']
})
export class CategoryViewComponent implements OnInit {
  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();

  family: Family;
  category: Category;
  private subURL: string;
  itemDescriptionsTable: TableOptions;

  formID: number;

  nbItems: number;
  forms: FormsMap;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private itemDescriptionService: ItemDescriptionService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const familyID = parseInt(this.activatedRoute.snapshot.paramMap.get('familyID'), 10);
    this.subURL = `/families/${familyID}`;
    const categoryID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    if (!isNaN(categoryID)) {
      this.itemDescriptionsTable = {
        columnDefs: [
          {
            prop: 'picture', type: ColumnType.PICTURE,
            name: this.i18n({value: 'Image', id: 'picture'})
          },
          {
            prop: 'label',
            name: this.i18n({value: 'Libellé', id: 'label'})
          },
          {
            prop: 'model',
            name: this.i18n({value: 'Modèle', id: 'model'})
          },
          {
            prop: 'type',
            name: this.i18n({value: 'Type', id: 'type'})
          },
          {
            prop: 'comment',
            name: this.i18n({value: 'Commentaire', id: 'comment'})
          },
        ],
        findDataCb: (searchData: Params) => {
          let params = {active: searchData.active !== undefined ? searchData.active : true};
          return this.itemDescriptionService.findByCategory(categoryID, params);
        },
        actions: {
          readCb: (entity: Entity) => {
            this.routeNameService.goTo('item_description_view', {id: entity.id});
          },
          create: [
            {
              btnName: this.i18n({value: 'Ajouter une fiche', id: 'btnAddItemDescription'}),
              createCb: () => this.routeNameService.goTo('item_description_add', {}, {familyID, categoryID: this.category.id})
            },
          ],
          columnWidth: '5%'
        },
        defaultSortActive: 'label',
        defaultSortDirection: ColumnSortDirection.ASC,
        headTemplate: this.headerTable
      };

      this._getCategory(categoryID);
    }
  }

  private _getCategory(categoryID: number) {
    this.categoryService.find(categoryID, this.subURL)
      .subscribe((category: Category) => {
        this.category = category;
        this.family = this.category.family as Family;

        if (this.category.form) {
          this.forms = {};
          this.formID = this.category.form.id;
        }

        this.cd.detectChanges();
      });
  }

  editCategory() {
    this.routeNameService.goTo('category_id', {id: this.category.id, familyID: this.family.id});
  }

  getFamilyURL() {
    return this.routeNameService.path('family_view', {id: this.family.id});
  }

  updateNbItems(nbItems: number) {
    this.nbItems = nbItems;
  }

  itemDescriptionChange(event: MatCheckboxChange) {
    this.search.emit({
      active: event.checked
    });
  }
}
