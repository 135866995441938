<mat-accordion multi="true" *ngIf="person">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{isPerson ? 'Personne' : (isCompany ? 'Société' : 'Groupe')}} : {{person.lastName}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayoutAlign="center center">
          <button mat-flat-button (click)="editPerson()" color="success">
            <mat-icon>edit</mat-icon> Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="23" [itemID]="person.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="person.picture"></esomus-img>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data fxFlex="50" boldValue="Nom"
                              [textValue]="person.lastName"></esomus-line-data>
            <esomus-line-data *ngIf="isPerson" fxFlex="50" boldValue="Prénom"
                              [textValue]="person.firstName"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="Numéro interne 1"
                              [textValue]="person.internalNumber1"></esomus-line-data>
            <esomus-line-data *ngIf="isPerson" fxFlex="50" boldValue="Numéro interne 2"
                              [textValue]="person.internalNumber2"></esomus-line-data>
            <esomus-line-data *ngIf="isCompany" fxFlex="50" boldValue="Numéro de TVA"
                              [textValue]="person.internalNumber2"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="Titre"
                              [textValue]="person.title"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="Initiale"
                              [textValue]="person.initial"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="Téléphone"
                              [textValue]="person.phone"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="Fax"
                              [textValue]="person.fax"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="GSM"
                              [textValue]="person.gsm"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="50" boldValue="Email"
                              [textValue]="person.mail"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Commentaire" boldFlex="25"
                              textFlex="75" [textValue]="person.comment"></esomus-line-data>
            <esomus-line-data *ngIf="!isGroup" fxFlex="100" boldValue="Interne" boldFlex="25"
                              textFlex="75"
                              [textValue]="person.intern ? 'Oui' : 'Non'"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="isPerson && person.personLocation" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Informations complémentaires
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
        <div fxLayout="column" fxFlex="50">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data fxFlex="100" [skipText]="true"
                                  boldValue="Lieu de travail"></esomus-line-data>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data fxFlex="100" boldValue="Entreprise"
                                  [textValue]="person.personLocation.company ? person.personLocation.company.name : null"
                ></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Site"
                                  [textValue]="person.personLocation.site ? person.personLocation.site.name : null"
                ></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Bâtiment"
                                  [textValue]="person.personLocation.building ? person.personLocation.building.name : null"
                ></esomus-line-data>
                <esomus-line-data fxFlex="100" boldValue="Local"
                                  [textValue]="person.personLocation.local ? person.personLocation.local.name : null"
                ></esomus-line-data>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50">
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <esomus-line-data fxFlex="100" [skipText]="true"
                                  boldValue="Formations / Permis"></esomus-line-data>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row wrap" fxLayoutAlign="start">
                <div fxFlex="100">
                  <ul class="m-5">
                    <li *ngFor="let obligation of person.obligations">
                      {{ obligation.label }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-equipment-table *ngIf="isPerson" label="Equipements personnels" parent="person"
                          [person]="person" [parentID]="person.id"></esomus-equipment-table>
  <mat-expansion-panel *ngIf="!isPerson" expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{isCompany ? 'Employés' : 'Membres'}} : {{nbMembers}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <esomus-table *ngIf="memberTableOptions" [options]="memberTableOptions"
                    [searchReceiver]="memberTableEmitter"
                    (getLength)="updateNbMembers($event)"></esomus-table>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="isPerson && person.personLocation" expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Sociétés : {{nbCompanies}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <esomus-table *ngIf="companyTableOptions" [options]="companyTableOptions"
                    (getLength)="updateNbCompanies($event)"></esomus-table>
    </div>
  </mat-expansion-panel>
  <esomus-address-panel [personID]="person.id"></esomus-address-panel>
</mat-accordion>

<ng-template #formModal>
  <form>
    <h3 mat-dialog-title>
      <span>Lier un employé</span>
    </h3>
    <div mat-dialog-content style="min-width: 480px;">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <esomus-ng-select *ngIf="memberOptions" [options]="memberOptions" minLength="3"
                          [control]="memberForm.get('member')" i18n-label="@@person"
                          label="Personne" fxFlex="100" flex="100"></esomus-ng-select>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="linkMember()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeMemberModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
