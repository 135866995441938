import {Component, OnInit} from '@angular/core';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {DocumentTypeService} from '../../../core/api/document-type.service';
import {DocumentType} from '../../../core/models/document-type.model';
import {DocumentTypeHelper} from '../../../core/services/document-type-helper.service';

@Component({
  selector: 'esomus-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.sass']
})
export class DocumentTypeListComponent implements OnInit {

  documentTypeTable: TableOptions;

  constructor(
    private documentTypeService: DocumentTypeService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) { }

  ngOnInit() {
    this.documentTypeTable = {
      columnDefs: [
        {
          prop: 'label', width: '30%',
          name: this.i18n({ value: 'Libellé', id: 'label' })
        },
        {
          prop: 'group', width: '15%',
          name: this.i18n({ value: 'Groupe', id: 'group' })
        },
        {
          prop: 'duration', width: '10%',
          name: this.i18n({ value: 'Durée', id: 'duration' })
        },
        {
          prop: 'legal', width: '10%', type: ColumnType.CHECKBOX,
          name: this.i18n({ value: 'Légal', id: 'legal' })
        },
        {
          prop: 'usageType', width: '10%', valueCb: (item: DocumentType) => DocumentTypeHelper.getUsageTypeLabel(item),
          name: this.i18n({ value: 'Type d\'usage', id: 'usageType' })
        },
      ],
      findDataCb: (searchData: Params) => this.documentTypeService.findAll(),
      actions: {
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter un type de document', id: 'btnAddDocumentForm' }),
            createCb: () => this.routeName.goTo('document_type_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('document_type_id', {id: entity.id});
        },
        columnWidth: '5%'
      },
    };
  }
}
