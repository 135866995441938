import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {Router} from '@angular/router';
import {ToastrMessageType, ToastrService} from '../../../../core/services/toastr.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'esomus-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.sass']
})
export class DeleteButtonComponent implements OnInit {
  @ViewChild('deleteModal', { static: true }) deleteModal: TemplateRef<any>;
  deleteModalRef: MatDialogRef<DeleteButtonComponent>;

  @Input() url: Observable<any>;
  @Input() successUrl: string;
  @Input() label: string;

  constructor(
    private i18n: I18n,
    private router: Router,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    if (!this.label) {
      this.label = this.i18n({value: 'Désactiver', id: 'disable'});
    }
  }

  click() {
    this.closeDeleteModal();
    this.url.subscribe(
      () => this.router.navigate([this.successUrl]),
      (response: Response) => {
        this.toastrService.open(ToastrMessageType.DELETE_ERROR);
      }
    );
  }

  closeDeleteModal() {
    this.deleteModalRef.close();
    this.deleteModalRef = null;
  }

  openDeleteModal() {
    this.deleteModalRef = this.dialog.open(this.deleteModal);
  }
}
