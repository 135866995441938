import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Item} from '../../../core/models/item.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {EquipmentService} from '../../../core/api/equipment.service';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {CategoryService} from '../../../core/api/category.service';
import {ItemStatusService} from '../../../core/api/item-status.service';
import {UserService} from '../../../core/api/user.service';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {ItemDataService} from '../../../core/api/item-data.service';

@Component({
  selector: 'esomus-equipment-view',
  templateUrl: './equipment-view.component.html',
  styleUrls: ['./equipment-view.component.sass']
})
export class EquipmentViewComponent implements OnInit {

  equipment: Item;

  isEquipment: boolean;
  isContainer: boolean;
  isVehicle: boolean;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private itemStatusService: ItemStatusService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private itemDataService: ItemDataService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      const equipmentID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

      this._getEquipment(equipmentID);
    });
  }

  private _getEquipment(id: number) {
    this.equipmentService.find(id).subscribe((equipment: Item) => {
      this.equipment = equipment;

      this.isContainer = this.equipment.itemType.value === 6;
      this.isVehicle = this.equipment.itemType.value === 7;
      this.isEquipment = this.equipment.itemType.value === 8;

      this.cd.detectChanges();
    });
  }


  getFamilyURL() {
    return this.routeNameService.path('family_view', {id: this.equipment.family.id});
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', {familyID: this.equipment.family.id, id: this.equipment.category.id});
  }

  getItemDescriptionURL() {
    return this.routeNameService.path('item_description_view', {id: this.equipment.itemDescription.id});
  }

  getLinkURL() {
    if (this.equipment.link) {
      return this.routeNameService.path('equipment_view', {id: this.equipment.link.id});
    } else {
      return null;
    }
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.equipment.id, formID);
  }
}
