import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BreadcrumbsModule} from './elastic-ui/breadcrumbs/breadcrumbs.module';
import {LayoutModule} from './elastic-ui/layout/layout.module';
import {FormBuilderModule} from './form-builder/form-builder.module';
import {MaterialModule} from './material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PipeModule} from './pipes/pipe.module';
import {EsomusUiModule} from './esomus-ui/esomus-ui.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormBuilderModule,
    PipeModule
  ],
  exports: [
    FlexLayoutModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    BreadcrumbsModule,
    FormBuilderModule,
    PipeModule,
    EsomusUiModule
    // COMPONENTS
  ]
})
export class SharedModule { }
