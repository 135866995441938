import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {DocumentTypeService} from '../../../core/api/document-type.service';
import {TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {Entity} from '../../../core/models/entity.model';
import {FormHelper} from '../../../core/services/form-helper.service';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {Document} from '../../../core/models/document.model';
import {DocumentService} from '../../../core/api/document.service';
import {Params} from '@angular/router';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {of} from 'rxjs';
import {CompanyService} from '../../../core/api/company.service';
import {SiteService} from '../../../core/api/site.service';
import {tap} from 'rxjs/operators';
import {DocumentType} from '../../../core/models/document-type.model';

@Component({
  selector: 'esomus-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.sass']
})
export class DocumentLinkComponent implements OnInit {

  @Input() parent: Entity;
  @Input() parentType: string;
  @Input() documentTableEmitter: EventEmitter<any>;

  @ViewChild('tableHeader', { static: true }) tableHeader: TemplateRef<any>;
  @ViewChild('searchModal', { static: true }) searchModal: TemplateRef<any>;
  @ViewChild('formModal', { static: true }) formModal: TemplateRef<any>;
  @ViewChild('companySiteFormModal', { static: true }) companySiteFormModal: TemplateRef<any>;

  fieldType = FieldType;

  searchModalRef: MatDialogRef<DocumentLinkComponent>;
  formModalRef: MatDialogRef<DocumentLinkComponent>;
  companySiteModalRef: MatDialogRef<DocumentLinkComponent>;

  searchForm: FormGroup;
  documentForm: FormGroup;
  companySiteForm: FormGroup;

  companyOptions: EntityOption;
  siteOptions: EntityOption;

  documentTypes: Array<DocumentType>;
  documentTypeOptions: EntityOption;
  documentTypeLabel: string;
  documentTableOptions: TableOptions;
  searchDone: boolean;
  searchedDocumentTypeID: number;

  pickCompanyAndSite: boolean;

  document: Document;

  documentSearchEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    private cd: ChangeDetectorRef,
    private companyService: CompanyService,
    private siteService: SiteService
  ) {
  }

  ngOnInit() {
    switch (this.parentType) {
      case 'itemDescription':
      case 'category':
        this.pickCompanyAndSite = true;
        break;
      default:
        this.pickCompanyAndSite = false;
    }

    this.searchDone = false;

    let type = null;
    let urlParams = {
      active: 'all'
    };
    switch (this.parentType) {
      case 'category':
      case 'item':
      case 'itemDescription':
        type = 1;
        urlParams['type'] = 'description';
        break;
      case 'anomaly':
      case 'action':
      case 'task':
      case 'accident':
      case 'closedAccident':
        type = 2;
        urlParams['type'] = 'activity';
        break;
    }

    this.documentTypeService.findAll('', urlParams).subscribe((data: Array<DocumentType>) => {
      this.documentTypes = data.filter((documentType: DocumentType) => documentType.usageType === type);
      this.documentTypeOptions = {
        get: () => of(
          this.documentTypes
        ), propName: 'label', autoSelect: true
      };
      this.cd.detectChanges();

      if (type === 2) {
        this.showDocumentModal();
      }
    });

    if (type === 2) {
      return;
    }

    this.searchModalRef = this.dialog.open(this.searchModal);

    this.searchForm = this.fb.group({
      documentType: [null]
    });

    this.documentTableOptions = {
      columnDefs: [
        {
          prop: null,
          name: null
        },
        {
          prop: 'documentType.label',
          name: this.i18n({value: 'Catégorie', id: 'category'})
        },
        {
          prop: 'label',
          name: this.i18n({value: 'Titre', id: 'title'})
        },
        {
          prop: 'empty1',
          name: this.i18n({value: 'M.à.J.', id: 'maj'})
        },
        {
          prop: 'empty2',
          name: this.i18n({value: 'Validité', id: 'validity'})
        },
      ],
      findDataCb: (searchData: Params) => {
        if (Object.keys(searchData).length === 0) {
          return of([]);
        } else {
          console.log(searchData);
          this.cd.detectChanges();
          searchData['list'] = 1;
          return this.documentService.search(searchData['documentType']).pipe(
            tap((data: Array<Document>) => {
              if (this.searchedDocumentTypeID !== null) {
                const docType = this.documentTypes.find((item: DocumentType) => {
                  return item.id === this.searchedDocumentTypeID;
                });
                if (docType !== null && !docType.active) {
                  this.searchDone = false;
                  // this.addDisabled = true;
                } else {
                  this.searchDone = true;
                }
              } else {
                this.searchDone = true;
              }
            })
          );
        }
      },
      actions: {
        readCb: (entity: Entity) => this.documentService.preview(entity.id),
        custom: [
          {
            icon: 'link',
            customCb: (entity: Entity) => this._companySiteHandler(entity)
          }
        ]
      },
      headTemplate: this.tableHeader
    };

    this.cd.detectChanges();
  }

  private _companySiteHandler(entity: Entity) {
    if (this.pickCompanyAndSite) {
      if (this.formModalRef) {
        this.formModalRef.close();
        this.formModalRef = null;
      }

      if (this.searchModalRef) {
        this.searchModalRef.close();
        this.searchModalRef = null;
      }

      this.document = entity as Document;

      this.companySiteForm = this.fb.group({
        company: [null],
        // site: this.fb.control({value: null, disabled: true})
      });
      this.companyOptions = {get: () => this.companyService.findAll(), propName: 'label'};
      this.cd.detectChanges();

      this.companySiteModalRef = this.dialog.open(this.companySiteFormModal);
    } else {
      this.documentService.linkParent(this.parent.id, entity.id, this.parentType).subscribe((doc: Document) => {
        this.documentTableEmitter.emit();
        this.searchModalRef.close();
        this.searchModalRef = null;
      });
    }
  }

  updateSite() {
    const companyID = this.companySiteForm.get('company').value;

    if (!companyID) {
      return;
    }

    this.companySiteForm.addControl('site', this.fb.control(null));
    this.siteOptions = {get: () => this.companyService.getAllSites(companyID), propName: 'label'};
    this.cd.detectChanges();
  }

  submitCompanySite() {
    const entity = FormHelper.buildEntity(new Entity(), this.companySiteForm, {});

    this.documentService.linkParent(this.parent.id, this.document.id, this.parentType, entity).subscribe(() => {
      this.documentTableEmitter.emit();
      this.companySiteModalRef.close();
      this.companySiteModalRef = null;
    });
  }

  closeCompanySite() {
    this.companySiteModalRef.close();
    this.companySiteModalRef = null;
  }

  submitSearch() {
    const entity = FormHelper.buildEntity(new Entity(), this.searchForm, {});
    const type = this.searchForm.get('documentType');
    if (type !== null) {
      const value = type.value;
      if (value !== null) {
        this.searchedDocumentTypeID = value;
      }
    }

    this.searchDone = false;
    this.cd.detectChanges();

    this.documentSearchEmitter.emit(entity);
  }

  closeSearch() {
    this.searchModalRef.close();
    this.searchModalRef = null;
  }

  showDocumentModal() {
    if (this.searchModalRef) {
      this.searchModalRef.close();
      this.searchModalRef = null;
    }

    this.formModalRef = this.dialog.open(this.formModal);
    this.document = new Document();
    this.documentForm = this.fb.group({
      uploadedFile: [null],
      label: [null],
      version: [null],
      documentType: [this.searchedDocumentTypeID]
    });

    const types = this.documentTypes.filter((item: DocumentType) => item.id === this.searchedDocumentTypeID);
    if (types.length === 1 && this.searchedDocumentTypeID) {
      this.documentTypeLabel = types[0].label;
    } else {

    }
  }

  submitDocument() {
    if (this.documentForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.document, this.documentForm, {
      uploadedFile: {type: FieldType.FILE, multiple: false}
    }) as Document;

    FormHelper.submitForm(
      this.cd,
      this.documentForm,
      this.documentService.postParent(entity, this.parent, this.parentType),
      (result: Document) => {
        if (this.pickCompanyAndSite) {
          this._companySiteHandler(result);
        } else {
          this.formModalRef.close();
          this.formModalRef = null;

          this.documentTableEmitter.emit();
        }
      }
    );
  }

  closeAdd() {
    this.formModalRef.close();
    this.formModalRef = null;
  }

  handleFileInput(files: FileList) {
    const label = this.documentForm.get('label').value;
    if (!label || label === '') {
      const file = files.item(0);
      this.documentForm.controls['label'].setValue(file.name.replace(/[.][a-zA-Z0-9]+$/, ''));
      this.cd.detectChanges();
    }
  }
}
