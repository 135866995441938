<form *ngIf="equipment">
  <mat-accordion multi="true" class="mat-edit">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Equipement: Edition
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-img fxFlex="100" [picture]="equipment.picture"></esomus-img>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
            </div>
          </div>
          <div fxLayout="column" fxFlex="70" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-line-data fxFlex="50" flex="100" boldValue="Contrôlable"
                                [textValue]="equipment.itemDescription.controllable ? 'Oui' : 'Non'"></esomus-line-data>
              <esomus-line-data fxFlex="50" flex="100" boldValue="Certification CE"
                                [textValue]="equipment.itemDescription.ceCertification ? 'Oui' : 'Non'"></esomus-line-data>
              <esomus-line-data fxFlex="50" flex="100" boldValue="Modèle"
                                [textValue]="equipment.itemDescription.model"></esomus-line-data>
              <esomus-line-data fxFlex="50" flex="100" boldValue="Type"
                                [textValue]="equipment.itemDescription.type"></esomus-line-data>
              <esomus-line-data fxFlex="50" flex="100" boldValue="Durée d'utilisation"
                                [textValue]="equipment.itemDescription.duration !== null ? (equipment.itemDescription.duration.toString() + ' mois') : null"></esomus-line-data>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@name" label="Nom" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-ng-select *ngIf="assignedToOptions" [options]="assignedToOptions"
                                [control]="entityForm.get('assignedTo')"
                                i18n-label="@@assignedTo" label="Attribué à" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('location')"
                            i18n-label="@@location" label="Localisation" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-ng-select *ngIf="itemSituationOptions" [options]="itemSituationOptions"
                                [control]="entityForm.get('situation')"
                                i18n-label="@@itemSituation" label="Situation" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!itemSituationOptions && equipment.situation" fxFlex="50"
                                flex="100" boldValue="Situation"
                                [textValue]="equipment.situation.label"></esomus-line-data>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true" *ngIf="equipment">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Information spécifique
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('serialNumber')"
                          i18n-label="@@serialNumber" label="N° de série" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('internalCode')"
                          i18n-label="@@internalCode" label="Code interne" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('expirationDate')"
                          i18n-label="@@expirationDate" label="Date de validité" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-ng-select *ngIf="linkOptions" [options]="linkOptions"
                              [control]="entityForm.get('link')"
                              i18n-label="@@link" label="Lié à" fxFlex="100"
                              flex="100"></esomus-ng-select>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('buildingDate')"
                          i18n-label="@@buildingDate" label="Date de construction" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('buyingDate')"
                          i18n-label="@@buyingDate" label="Date d'achat" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('serviceDate')"
                          i18n-label="@@serviceDate" label="Mise en service" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('lastUseDate')"
                          i18n-label="@@lastUseDate" label="Dernière utilisation" flex="100"
                          fxFlex="100"></esomus-input>
            <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('checkDate')"
                          i18n-label="@@checkDate" label="Dernier contrôle" flex="100"
                          fxFlex="100"></esomus-input>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="(equipment.category.form || equipment.itemDescription.form) && forms"
      expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Information complémentaire
        </mat-panel-title>
      </mat-expansion-panel-header>
      <esomus-form-component *ngIf="equipment.category.form" [formID]="equipment.category.form.id"
                             [forms]="forms" [formsMapping]="formsMapping"
                             [fetchDataURL]="getFetchDataURL(equipment.category.form.id)"></esomus-form-component>
      <esomus-form-component *ngIf="equipment.itemDescription.form"
                             [formID]="equipment.itemDescription.form.id" [forms]="forms"
                             [formsMapping]="formsMapping"
                             [fetchDataURL]="getFetchDataURL(equipment.itemDescription.form.id)"></esomus-form-component>
    </mat-expansion-panel>
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div class="btn-group-left" fxFlex="50">
        <esomus-delete-button *ngIf="equipment.active" label="Déclasser" [url]="getDeleteURL()"
                              [successUrl]="getSuccessURL()">
        </esomus-delete-button>
        <!--        <esomus-enable-button-->
        <!--          *ngIf="!equipment.active"-->
        <!--          [url]="getEnableURL()"-->
        <!--          [successUrl]="getSuccessURL()"-->
        <!--        ></esomus-enable-button>-->
      </div>
      <div class="btn-group-right" fxFlex="50">
        <esomus-back-button></esomus-back-button>
        <esomus-submit (submit)="submit()"></esomus-submit>
      </div>
    </div>
  </mat-accordion>
</form>
