import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Task} from '../models/task.model';
import {environment} from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/tasks/`,
  single: (id: number) => `/tasks/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class TaskService extends CRUD<Task>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllDefinition() {
    return this.http.get<Array<Task>>(`${environment.api.url}${this.crudPath.many}definition`);
  }

  generateAction(task: Task) {
    return this.http.post<Task>(`${environment.api.url}${this.crudPath.single(task.id)}/generate`, null);
  }

  postValidateActionByBuilding(taskID: number, buildingID: number) {
    return this.http.post<Task>(`${environment.api.url}${this.crudPath.single(taskID)}/validate-actions/building/${buildingID}`, null)
  }
}

