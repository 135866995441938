import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Data} from '../../../core/models/data.model';

@Component({
  selector: 'esomus-form-display',
  templateUrl: './form-display.component.html',
  styleUrls: ['./form-display.component.sass']
})
export class FormDisplayComponent implements OnInit {

  @Input() formID: number;
  @Input() fetchDataURL: Observable<any>;

  data: Array<Data>;

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.fetchDataURL) {
      this.fetchDataURL.subscribe((result: Array<Data>) => {
          this.data = result;

          for (let field of this.data) {
            switch (field.type) {
              case 'checkbox':
                field.realValue = field.value ? 'Oui' : 'Non';
                break;
            }
          }

          this.cd.detectChanges();
      });
    }
  }

}
