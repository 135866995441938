import {Component, OnInit} from '@angular/core';
import {ColumnSortDirection, ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {AccidentService} from '../../../core/api/accident.service';
import {Accident} from '../../../core/models/accident.model';
import {AccidentHelper} from '../../../core/services/accident-helper.service';

@Component({
  selector: 'esomus-accident-list',
  templateUrl: './accident-list.component.html',
  styleUrls: ['./accident-list.component.sass']
})
export class AccidentListComponent implements OnInit {
  accidentsTable: TableOptions;

  accidentDashboardParams: Params;

  constructor(
    private accidentService: AccidentService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.accidentDashboardParams = {};

    this.accidentsTable = {
      columnDefs: [
        {
          // name: this.i18n({value: 'Icone', id: 'icon'}),
          name: null,
          type: ColumnType.ICON, prop: 'icon', width: '5%'
        },
        {
          prop: 'type.description', width: '20%',
          name: this.i18n({value: 'Type d\'accident', id: 'accidentType'})
        },
        {
          prop: 'date', width: '10%', type: ColumnType.DATE,
          name: this.i18n({value: 'Date', id: 'date'})
        },
        {
          prop: 'description', width: '25%',
          name: this.i18n({value: 'Description', id: 'description'})
        },
        {
          prop: 'manager.fullName', width: '20%',
          name: this.i18n({value: 'manager', id: 'manager'})
        },
        {
          prop: 'status', width: '15%', valueCb: (item: Accident) => AccidentHelper.getAccidentStatusLabel(item.status),
          name: this.i18n({value: 'Statut', id: 'status'})
        },
      ],
      findDataCb: (searchData: Params) => this.accidentService.findAll('', {type: 'accident'}),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un incident', id: 'btnAddAccident'}),
            createCb: () => this.routeName.goTo('accident_add')
          }
        ],
        readCb: (entity: Entity) => {
          this.routeName.goTo('accident_view', {id: entity.id});
        },
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }
}
