import {Component, OnInit} from '@angular/core';
import {ColumnSortDirection, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {EffectService} from '../../../core/api/effect.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-effect-list',
  templateUrl: './effect-list.component.html',
  styleUrls: ['./effect-list.component.sass']
})
export class EffectListComponent implements OnInit {
  effectsTable: TableOptions;

  constructor(
    private effectService: EffectService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.effectsTable = {
      columnDefs: [
        {
          prop: 'description', width: '95%',
          name: this.i18n({value: 'Description', id: 'description'})
        },
      ],
      findDataCb: (searchData: Params) => this.effectService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un effet', id: 'btnAddEffect'}),
            createCb: () => this.routeName.goTo('effect_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('effect_id', {id: entity.id});
        },
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }
}
