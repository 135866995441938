import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {PersonService} from '../../../core/api/person.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {MatCheckboxChange} from '@angular/material';
import {AccidentPersonService} from '../../../core/api/accident-person.service';
import {AccidentPerson} from '../../../core/models/accident-person.model';
import {Accident} from '../../../core/models/accident.model';
import {AccidentService} from '../../../core/api/accident.service';
import {ImpactService} from '../../../core/api/impact.service';
import {ZoneService} from '../../../core/api/zone.service';
import {EffectService} from '../../../core/api/effect.service';
import {RoleService} from '../../../core/api/role.service';

@Component({
  selector: 'esomus-accident-person',
  templateUrl: './accident-person.component.html',
  styleUrls: ['./accident-person.component.sass']
})
export class AccidentPersonComponent implements OnInit {
  accidentID: number;
  accident: Accident;
  accidentPerson: AccidentPerson;

  entityForm: FormGroup;
  fieldType = FieldType;
  personOptions: EntityOption;
  impactOptions: EntityOption;
  roleOptions: EntityOption;
  zonesOptions: EntityOption;
  effectOptions: EntityOption;

  isWitness: boolean;
  isIntern: boolean;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentService: AccidentService,
    private accidentPersonService: AccidentPersonService,
    private personService: PersonService,
    private impactService: ImpactService,
    private zoneService: ZoneService,
    private effectService: EffectService,
    private roleService: RoleService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      witness: [],
      intern: [true],
      role: [null, [Validators.required]],
      comment: [null]
    });

    this.accidentID = parseInt(this.activatedRoute.snapshot.paramMap.get('accidentID'), 10);
    if (!isNaN(this.accidentID)) {
      this.accidentService.find(this.accidentID).subscribe((result: Accident) => {
        this.accident = result;
        const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

        if (!isNaN(id)) {
          this._getAccidentPerson(id);
        } else {
          this.accidentPerson = new AccidentPerson();
          this.accidentPerson.zones = [];
          this.isIntern = true;
          this.isWitness = false;
          this._init();
        }
      });
    }
  }

  private _init() {
    this.roleOptions = {propName: 'description', get: () => this.roleService.findAll(), autoSelect: true};

    this.impactOptions = {get: () => this.impactService.findAll(), propName: 'description', autoSelect: true};
    this.effectOptions = {get: () => this.effectService.findAll(), propName: 'description', autoSelect: true};
    this.zonesOptions = {get: () => this.zoneService.findAll(), propName: 'description', autoSelect: true};

    this._intern();
    this._witness();
  }

  private _intern() {
    if (this.entityForm.contains('person')) {
      this.entityForm.removeControl('person');
      this.personOptions = null;
      this.cd.detectChanges();
    }

    this.entityForm.addControl('person', this.fb.control(this.accidentPerson.person ? this.accidentPerson.person : null, [Validators.required]));
    this.personOptions = {
      propName: 'fullName',
      get: () => {
        if (this.isIntern) {
          return this.personService.getCompanyPersons(this.accident.location.company.id, {intern: this.isIntern});
        } else {
          return this.personService.findAll('', {companyID: -1, intern: this.isIntern});
        }
      },
      autoSelect: true
    };
    this.cd.detectChanges();
  }

  private _witness() {
    if (this.entityForm.contains('impact')) {
      this.entityForm.removeControl('impact');
    }
    if (this.entityForm.contains('days')) {
      this.entityForm.removeControl('days');
    }
    if (this.entityForm.contains('effect')) {
      this.entityForm.removeControl('effect');
    }
    if (this.entityForm.contains('zones')) {
      this.entityForm.removeControl('zones');
    }

    if (this.isWitness) {
      return;
    }

    this.entityForm.addControl('impact', this.fb.control(this.accidentPerson.impact ? this.accidentPerson.impact : null, [Validators.required]));
    this.entityForm.addControl('days', this.fb.control(this.accidentPerson.days ? this.accidentPerson.days : null, [Validators.required]));
    this.entityForm.addControl('effect', this.fb.control(this.accidentPerson.effect ? this.accidentPerson.effect : null, [Validators.required]));
    this.entityForm.addControl('zones', this.fb.control(this.accidentPerson.zones ? this.accidentPerson.zones : null, [Validators.required]));

    this.cd.detectChanges();
  }

  private _getAccidentPerson(id: number) {
    this.accidentPersonService.find(id).subscribe((result: AccidentPerson) => {
      this.accidentPerson = result;

      FormHelper.initValues(this.accidentPerson, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let types = {};
    if (this.entityForm.contains('zones')) {
      types['zones'] = {type: FieldType.SELECT, multiple: true};
    }

    const entity = FormHelper.buildEntity(this.accidentPerson, this.entityForm, types) as AccidentPerson;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.accidentPerson.id) ? this.accidentPersonService.putAccidentPerson(this.accidentID, entity) : this.accidentPersonService.postAccidentPerson(this.accidentID, entity),
      (result: AccidentPerson) => {
        this.toastrService.open((this.accidentPerson.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('accident_view', {id: this.accidentID});
      }
    );
  }

  getAccidentPersonDeleteURL() {
    return this.accidentPersonService.delete(this.accidentPerson.id);
  }

  getAccidentPersonSuccessURL() {
    return this.routeNameService.path('accident_view', {id: this.accidentID});
  }

  changeWitness(event: MatCheckboxChange) {
    this.isWitness = event.checked;
    this._witness();
    this.cd.detectChanges();
  }

  changeIntern(event: MatCheckboxChange) {
    this.isIntern = event.checked;
    this._intern();
    this.cd.detectChanges();
  }
}
