<mat-accordion multi="true" class="mat-edit" *ngIf="accident">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Accident: {{accident.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-dashboard-print-selector *ngIf="accident.id" screenID="37" [itemID]="accident.id">
        </esomus-dashboard-print-selector>
      </div>
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-line-data fxFlex="100" boldValue="Statut"
                                [textValue]="getAccidentStatusLabel()"></esomus-line-data>
              <esomus-ng-select *ngIf="stageOptions" [options]="stageOptions"
                                [control]="entityForm.get('stage')"
                                i18n-label="@@severity" label="Gravité" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!typeOptions" fxFlex="100" boldValue="Type d'accident"
                                [textValue]="accident.type.description"></esomus-line-data>
              <esomus-ng-select *ngIf="typeOptions" [options]="typeOptions"
                                [control]="entityForm.get('type')"
                                i18n-label="@@accidentType" label="Type d'accident" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!entityForm.get('date')" fxFlex="100" boldValue="Arrivé le"
                                [textValue]="accident.date" textType="date"></esomus-line-data>
              <esomus-input *ngIf="entityForm.get('date')" [type]="fieldType.DATE"
                            [control]="entityForm.get('date')"
                            i18n-label="@@accidentDate" label="Arrivé le" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-textarea [control]="entityForm.get('comment')"
                               i18n-label="@@comment" label="Commentaire" flex="100"
                               fxFlex="100"></esomus-textarea>
              <esomus-line-data *ngIf="!entityForm.get('onTheWayToWork')" fxFlex="100" boldValue="Sur le chemin du travail"
                                [boolValue]="accident.onTheWayToWork"></esomus-line-data>
              <mat-checkbox *ngIf="entityForm.get('onTheWayToWork')" (change)="changeOnTheWayToWork($event)"
                            [formControl]="entityForm.get('onTheWayToWork')" i18n-label="@@onTheWayToWork"
                            label="Sur le chemin du travail" fxFlex="100">Sur le chemin du travail
              </mat-checkbox>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-item-location-form *ngIf="!accident.id && !onTheWayToWork" type="accident"
                                         [companyEmitter]="companyEmitter"
                                         [form]="entityForm.get('location')"
                                         [companyRequired]="true" [siteRequired]="true"
                                         [buildingRequired]="true" fxFlex="100"
                                         [location]="accident.location"
                                         [companyAutoSelect]="true" [siteAutoSelect]="true"
                                         [buildingAutoSelect]="true"
              ></esomus-item-location-form>
              <esomus-item-location-form *ngIf="!accident.id && onTheWayToWork" type="accident"
                                         [companyEmitter]="companyEmitter"
                                         [form]="entityForm.get('location')"
                                         [companyRequired]="true"
                                         [siteDisabled]="true"
                                         [siteRequired]="false"
                                         [buildingRequired]="false" fxFlex="100"
                                         [location]="accident.location"
                                         [companyAutoSelect]="true" [siteAutoSelect]="false"
                                         [buildingAutoSelect]="false"
              ></esomus-item-location-form>
              <esomus-line-data *ngIf="accident.id" fxFlex="100" boldValue="Entreprise"
                                [textURL]="'company_view'|path:{id: accident.location.company ? accident.location.company.id : null}"
                                [textValue]="accident.location.company ? accident.location.company.name : null"></esomus-line-data>
              <esomus-line-data *ngIf="accident.id && !onTheWayToWork" fxFlex="100" boldValue="Site"
                                [textURL]="'site_view'|path:{id: accident.location.site ? accident.location.site.id : null}"
                                [textValue]="accident.location.site ? accident.location.site.name : null"></esomus-line-data>
              <esomus-line-data *ngIf="accident.id && !onTheWayToWork" fxFlex="100" boldValue="Bâtiment"
                                [textURL]="'building_view'|path:{id: accident.location.building ? accident.location.building.id : null}"
                                [textValue]="accident.location.building ? accident.location.building.name : null"></esomus-line-data>
              <esomus-line-data *ngIf="accident.id && !onTheWayToWork" fxFlex="100" boldValue="Local"
                                [textURL]="'local_view'|path:{id: accident.location.local ? accident.location.local.id : null}"
                                [textValue]="accident.location.local ? accident.location.local.name : null"></esomus-line-data>
              <esomus-input [type]="fieldType.TEXT"
                            [control]="entityForm.get('placeDescription')"
                            i18n-label="@@placeDescription" label="Où exactement" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="managerOptions" [options]="managerOptions"
                                [control]="entityForm.get('manager')"
                                i18n-label="@@manager" label="Gestionnaire" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="insurerOptions" [options]="insurerOptions"
                                [control]="entityForm.get('insurer')"
                                i18n-label="@@insurer" label="Assureur" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-input [type]="fieldType.TEXT"
                            [control]="entityForm.get('insurerReference')"
                            i18n-label="@@insurerReference" label="N° dossier" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
<!--                <esomus-delete-button *ngIf="accident.id && accident.active"-->
<!--                                      [url]="getAccidentDeleteURL()"-->
<!--                                      [successUrl]="getAccidentSuccessURL()">-->
<!--                </esomus-delete-button>-->
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
