import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ItemType} from '../../../core/models/item-type.model';
import {Action} from '../../../core/models/action.model';
import {Anomaly} from '../../../core/models/anomaly.model';
import {Item} from '../../../core/models/item.model';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ItemTypeService} from '../../../core/api/item-type.service';
import {CategoryService} from '../../../core/api/category.service';
import {FamilyService} from '../../../core/api/family.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {EquipmentService} from '../../../core/api/equipment.service';
import {AnomalyService} from '../../../core/api/anomaly.service';
import {AnomalyTypeService} from '../../../core/api/anomaly-type.service';
import {AnomalyDataService} from '../../../core/api/anomaly-data.service';
import {PersonService} from '../../../core/api/person.service';
import {FormService} from '../../../core/api/form.service';
import {ActionService} from '../../../core/api/action.service';
import {TaskTypeService} from '../../../core/api/task-type.service';
import {of} from 'rxjs';
import {TaskHelper} from '../../../core/services/task-helper.service';
import {FormHelper} from '../../../core/services/form-helper.service';

@Component({
  selector: 'esomus-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.sass']
})
export class ActionComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;

  action: Action;
  anomaly: Anomaly;
  equipment: Item;

  emergencyOptions: EntityOption;
  personOptions: EntityOption;

  formOptions: EntityOption;
  taskTypeOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private anomalyService: AnomalyService,
    private anomalyTypeService: AnomalyTypeService,
    private anomalyDataService: AnomalyDataService,
    private personService: PersonService,
    private formService: FormService,
    private actionService: ActionService,
    private taskTypeService: TaskTypeService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      form: [null],
      description: [null],
      comment: [null],
      emergency: [2],
      person: [null],
      deadlineDate: [null],
      plannedDate: [null]
    });

    this.emergencyOptions = {
      get: () => of(TaskHelper.getOptions()), propName: 'label'
    };
    this.personOptions = {get: () => this.personService.findAll(), propName: 'fullName'};
    this.formOptions = {get: () => this.formService.findAll(), propName: 'name'};

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAction(id);
    }
  }

  private _getAction(actionID: number) {
    this.actionService.find(actionID).subscribe((result: Action) => {
      this.action = result;

      FormHelper.initValues(this.action, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.action, this.entityForm, {
      deadlineDate: {type: FieldType.DATE},
      plannedDate: {type: FieldType.DATE}
    }) as Action;
    let submitUrl = null;
    if (this.action.anomaly) {
      submitUrl = this.actionService.putFromAnomaly(entity);
    } else if (this.action.task) {
      submitUrl = this.actionService.putFromAction(entity);
    } else if (this.action.item) {
      submitUrl = this.actionService.putFromEquipment(entity);
    }

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      submitUrl,
      (result: Action) => {
        this.routeNameService.goTo('action_view', {id: this.action.id});
      }
    );
  }

  getOrigin() {
    return TaskHelper.getOrigin(this.action);
  }
}
