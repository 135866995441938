<ng-template #searchModal>
  <h3 mat-dialog-title>
    <span>Recherche de document</span>
  </h3>
  <form>
    <div mat-dialog-content style="min-width: 640px;">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <esomus-table [searchReceiver]="documentSearchEmitter" fxFlex="100"
                      *ngIf="documentTableOptions" [options]="documentTableOptions"></esomus-table>
      </div>
    </div>
    <div mat-dialog-actions>
      <button *ngIf="searchDone" type="button" mat-stroked-button color="success"
              i18n="@@addDocument" (click)="showDocumentModal()">Ajouter un document
      </button>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeSearch()">Annuler
      </button>
    </div>
  </form>
</ng-template>

<ng-template #tableHeader>
  <esomus-ng-select *ngIf="documentTypeOptions" [options]="documentTypeOptions" (valueChange)="submitSearch()"
                    [control]="searchForm.get('documentType')" i18n-label="@@category"
                    label="Catégorie" fxFlex="100"></esomus-ng-select>
</ng-template>

<ng-template #formModal>
  <form>
    <h3 mat-dialog-title>
      <span>Ajout d'un nouveau document</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <esomus-input-file accept="image/*,application/pdf"
                           [control]="documentForm.controls['uploadedFile']"
                           i18n-label="@@file" label="Fichier" fxFlex="100"
                           flex="100"
                           (change)="handleFileInput($event.target.files)"></esomus-input-file>
        <esomus-line-data *ngIf="documentTypeLabel" flex="50" fxFlex="100"
                          boldValue="Catégorie" [textValue]="documentTypeLabel"
        ></esomus-line-data>
        <esomus-ng-select *ngIf="!documentTypeLabel && documentTypeOptions" [options]="documentTypeOptions"
                          [control]="documentForm.get('documentType')" i18n-label="@@category"
                          label="Catégorie" fxFlex="100"></esomus-ng-select>
        <esomus-input [type]="fieldType.TEXT" [control]="documentForm.get('label')"
                      i18n-label="@@label" label="Libellé" flex="100"
                      fxFlex="100"></esomus-input>
        <esomus-input [type]="fieldType.TEXT" [control]="documentForm.get('version')"
                      i18n-label="@@version" label="Version" flex="100"
                      fxFlex="50"></esomus-input>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="submitDocument()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeAdd()">Annuler</button>
      <!--    <ng-container *ngTemplateOutlet="formFooter"></ng-container>-->
    </div>
  </form>
</ng-template>

<ng-template #companySiteFormModal>
  <form>
    <h3 mat-dialog-title>
      <span>Choix d'une entreprise et d'un site</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap.gt-xs="10px">
        <esomus-ng-select *ngIf="companyOptions && companySiteForm.get('company')"
                          [options]="companyOptions"
                          [control]="companySiteForm.get('company')" i18n-label="@@company"
                          (valueChange)="updateSite()"
                          label="Entreprise" [fxFlex]="100" flex="100"></esomus-ng-select>
        <esomus-ng-select *ngIf="siteOptions" [options]="siteOptions"
                          [control]="companySiteForm.get('site')" i18n-label="@@site"
                          label="Site" [fxFlex]="100" flex="100"></esomus-ng-select>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="submitCompanySite()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeCompanySite()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
