<div class="info-box info-box-{{color}}" [fxFlex]="flex">
  <div fxFlex="100">
    <p class="info-box-text">
      {{line1}}
      <ng-container *ngIf="date1">
        <br>{{date1}}
      </ng-container>
    </p>
  </div>
</div>
