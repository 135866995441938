import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ItemType} from '../../../core/models/item-type.model';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {Item} from '../../../core/models/item.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ItemTypeService} from '../../../core/api/item-type.service';
import {CategoryService} from '../../../core/api/category.service';
import {FamilyService} from '../../../core/api/family.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {EquipmentService} from '../../../core/api/equipment.service';
import {of} from 'rxjs';
import {Entity} from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-equipment-add',
  templateUrl: './equipment-add.component.html',
  styleUrls: ['./equipment-add.component.sass']
})
export class EquipmentAddComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;
  itemTypes: Array<ItemType>;

  itemTypeOptions: EntityOption;
  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  itemDescriptionOptions: EntityOption;

  equipment: Item;
  parent: Item;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private itemDescriptionService: ItemDescriptionService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: []
    });

    const parentID = parseInt(this.activatedRoute.snapshot.paramMap.get('parentID'), 10);

    this._getParent(parentID);
  }

  private _getParent(parentID: number) {
    this.equipmentService.find(parentID).subscribe((parent: Item) => {
      this.parent = parent;
      this.equipment = new Item();
      this.equipment.parent = parent;

      const siteID = parseInt(this.activatedRoute.snapshot.queryParamMap.get('siteID'), 10);
      if (!isNaN(siteID)) {
        this.itemTypeService.findByValue(7).subscribe((result: ItemType) => {
          this.itemType = result;
          this.entityForm.addControl('itemType', this.fb.control(this.itemType.id));

          this.updateFamily();
        });
      } else {
        let observable = null;
        switch (this.parent.itemType.value) {
          case 6:
          case 7:
            observable = this.itemTypeService.findWithoutVehicule();
            break;

          default:
            observable = this.itemTypeService.findAllEquipments();
        }

        observable.subscribe((result: Array<ItemType>) => {
          this.itemTypes = result;

          this.itemTypeOptions = {get: () => of(this.itemTypes), propName: 'label'};
          this.entityForm.addControl('itemType', this.fb.control(null));

          this.cd.detectChanges();
        });
      }

      this.cd.detectChanges();
    });
  }

  submit() {
    const entity = FormHelper.buildEntity(this.equipment, this.entityForm, {}) as Item;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.equipmentService.postFromParent(entity, this.parent.id),
      (result: Entity) => {
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('equipment_id', {id: result.id});
      }
    );
  }

  changeItemType() {
    const itemValueID = this.entityForm.get('itemType').value;

    if (!itemValueID) {
      this.itemType = null;
    } else {
      this.itemType = this.itemTypes.filter((itemType: ItemType) => {
        return itemType.id === itemValueID;
      }).pop();

      this.itemTypeOptions = null;

      this.updateFamily();
    }
  }

  updateFamily() {
    if (this.entityForm.contains('family')) {
      this.entityForm.removeControl('family');
    }
    this.familyOptions = null;

    this.cd.detectChanges();
    this.entityForm.addControl('family', this.fb.control(null));
    this.familyOptions = {get: () => this.familyService.findAllByItemItem(this.itemType.value), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }

  updateCategory() {
    const familyID = this.entityForm.get('family').value;

    if (this.entityForm.contains('category')) {
      this.entityForm.removeControl('category');
    }
    this.categoryOptions = null;
    if (!familyID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('category', this.fb.control(null));
    this.categoryOptions = {get: () => this.categoryService.findAllByParent(familyID), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }

  updateItemCategory() {
    const categoryID = this.entityForm.get('category').value;

    if (this.entityForm.contains('itemDescription')) {
      this.entityForm.removeControl('itemDescription');
    }
    this.itemDescriptionOptions = null;
    if (!categoryID) {
      return;
    }
    this.cd.detectChanges();
    this.entityForm.addControl('itemDescription', this.fb.control(null));
    this.itemDescriptionOptions = {get: () => this.itemDescriptionService.findByCategory(categoryID), propName: 'label', autoSelect: true};
    this.cd.detectChanges();
  }
}
