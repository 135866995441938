import {Component, Input, OnInit} from '@angular/core';
import {SidenavService} from '../sidenav.service';
import {SidenavItem} from './sidenav-item.model';

@Component({
  selector: 'esomus-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss']
})
export class SidenavItemComponent implements OnInit {

  scrollbar: any;

  @Input() item: SidenavItem;
  currentlyOpen: SidenavItem[] = [];

  constructor(
    private sidenavService: SidenavService
  ) { }

  ngOnInit() {
  }

  toggleDropdown() {
    if (this.item.subItems.length) {
      this.sidenavService.toggleItem(this.item.id);
    }
  }

  // Receives the count of Sub Items and multiplies it with 48 (height of one SidenavItem) to set the height for animation.
  getSubMenuHeight(): string {
    return (this.getSubMenuItemsCount(this.item) * 48) + 'px';
  }

  // Counts the amount of Sub Items there is and returns the count.
  getSubMenuItemsCount(item: SidenavItem): number {
    let count = 0;

    if (item.subItems.length && item.isOpen) {
      count += item.subItems.length;

      item.subItems.forEach((subItem) => {
        count += this.getSubMenuItemsCount(subItem);
      });
    }

    return count;
  }
}
