import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Dashboard} from '../models/dashboard.model';
import {Params} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

const crudPath: CRUDPath = {
  many: `/dashboards/`,
  single: (id: number) => `/dashboards/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends CRUD<Dashboard>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findWithParams(id: number, params ?: Params): Observable<Dashboard> {
    return this.http.get<Dashboard>(`${environment.api.url}${this.crudPath.single(id)}`, params);
  }

  findByScreens(screenID: number, params?: Params): Observable<Array<Dashboard>> {
    return this.http.get<Array<Dashboard>>(`${environment.api.url}${crudPath.many}screens/${screenID}`, params);
  }

  findAllDashboards(): Observable<Array<Dashboard>> {
    return this.http.get<Array<Dashboard>>(`${environment.api.url}${this.crudPath.many}list`, {type: 'dashboard'});
  }

  findAllInformations(): Observable<Array<Dashboard>> {
    return this.http.get<Array<Dashboard>>(`${environment.api.url}${this.crudPath.many}information`, {type: 'information'});
  }
}
