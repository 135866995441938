import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {ListComponentService} from '../../../core/api/list-component.service';
import {ListValueService} from '../../../core/api/list-value.service';
import {ListComponent} from '../../../core/models/list-component.model';
import {ListValue} from '../../../core/models/list-value.model';

@Component({
  selector: 'esomus-list-value',
  templateUrl: './list-value.component.html',
  styleUrls: ['./list-value.component.sass']
})
export class ListValueComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;
  listComponent: ListComponent;
  listValue: ListValue;
  private subURL: string;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private listComponentService: ListComponentService,
    private listValueService: ListValueService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.entityForm = this.fb.group({
      value: [null],
    });

    const listComponentID = parseInt(this.activatedRoute.snapshot.paramMap.get('listComponentID'), 10);
    this.subURL = `/list_components/${listComponentID}`;
    const listValueID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
    if (!isNaN(listValueID)) {
      this._getValue(listValueID);
    } else {
      this.listValue = new ListValue();
      this.listValue.listComponent = listComponentID;
    }
  }

  private _getValue(listValueID: number) {
    this.listValueService.find(listValueID, this.subURL)
      .subscribe((listValue: ListValue) => {
        this.listValue = listValue;
        FormHelper.initValues(this.listValue, this.entityForm);
        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }
    let entity = FormHelper.buildEntity(this.listValue, this.entityForm, {
    }) as ListValue;
    ((entity.id) ? this.listValueService.put(entity, this.subURL) : this.listValueService.post(entity, this.subURL))
      .subscribe((result: ListValue) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        let listComponentID;
        if (this.listValue.id) {
          listComponentID = (this.listValue.listComponent as ListComponent).id;
        } else {
          listComponentID = parseInt(this.listValue.listComponent.toString(), 10);
        }

        this.routeNameService.goTo('list_id', {id: listComponentID});
      });

  }
}
