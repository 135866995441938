<form>
  <mat-accordion multi="true" class="mat-edit" *ngIf="action">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Action: Création
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-line-data fxFlex="50" flex="100" [boldValue]="'Origine action'"
                                [textValue]="getOrigin()"></esomus-line-data>
              <esomus-line-data *ngIf="action.anomaly" fxFlex="50" flex="100"
                                [boldValue]="'Date butoir'" [textType]="'date'"
                                [textValue]="action.deadlineDate"></esomus-line-data>
              <esomus-input *ngIf="!action.anomaly && !action.task" [type]="fieldType.DATE"
                            [control]="entityForm.get('deadlineDate')"
                            i18n-label="@@deadlineDate" label="Date butoir" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-line-data *ngIf="action.anomaly" fxFlex="100" flex="50"
                                [boldValue]="'Element lié'"
                                [textValue]="action.anomaly.item.label"></esomus-line-data>
              <esomus-line-data *ngIf="action.item" fxFlex="100" flex="50"
                                [boldValue]="'Element lié'"
                                [textValue]="action.item.label"></esomus-line-data>
              <esomus-ng-select *ngIf="entityForm.get('taskType')" [options]="taskTypeOptions"
                                [control]="entityForm.get('taskType')"
                                i18n-label="@@taskType" label="Type" fxFlex="100"
                                flex="50"></esomus-ng-select>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="50"
                            [autofocus]="true"
                            fxFlex="100"></esomus-input>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
              <esomus-ng-select *ngIf="emergencyOptions" [options]="emergencyOptions"
                                [control]="entityForm.get('emergency')"
                                i18n-label="@@emergency" label="Urgence"
                                [fxFlex]="100" flex="50"></esomus-ng-select>
              <esomus-ng-select *ngIf="personOptions" [options]="personOptions"
                                [control]="entityForm.get('person')"
                                i18n-label="@@assignedTo" label="Attribué à" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="entityForm.get('form')" [options]="formOptions"
                                [control]="entityForm.get('form')"
                                i18n-label="@@form" label="Formulaire" fxFlex="100"
                                flex="50"></esomus-ng-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</form>
