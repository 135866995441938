<mat-accordion multi="true" class="mat-edit" *ngIf="accidentPerson">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Victime / Témoin: {{accidentPerson.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" [fxFlex]="100/3">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <mat-checkbox *ngIf="entityForm.contains('witness')" (change)="changeWitness($event)"
                            [formControl]="entityForm.get('witness')" i18n-label="@@witness"
                            label="Témoin" fxFlex="100">Témoin
              </mat-checkbox>
              <esomus-ng-select *ngIf="personOptions && this.entityForm.contains('person')"
                                [options]="personOptions"
                                [control]="entityForm.get('person')"
                                i18n-label="@@person" label="Personne" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="!isWitness && impactOptions && entityForm.contains('impact')"
                                [options]="impactOptions"
                                [control]="entityForm.get('impact')"
                                i18n-label="@@impact" label="Impact" fxFlex="100"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
          <div fxLayout="column" [fxFlex]="100/3">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <mat-checkbox *ngIf="entityForm.contains('intern')" (change)="changeIntern($event)"
                            [formControl]="entityForm.get('intern')" i18n-label="@@intern"
                            label="Interne" fxFlex="100">Interne
              </mat-checkbox>
              <esomus-ng-select *ngIf="roleOptions && this.entityForm.contains('role')"
                                [options]="roleOptions"
                                [control]="entityForm.get('role')"
                                i18n-label="@@function" label="Fonction" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-input *ngIf="!isWitness && entityForm.contains('days')"
                            [type]="fieldType.NUMBER"
                            [control]="entityForm.get('days')"
                            i18n-label="@@days" label="Jours" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
          <div fxLayout="column" [fxFlex]="100/3">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-ng-select *ngIf="effectOptions && this.entityForm.contains('effect')"
                                [options]="effectOptions"
                                [control]="entityForm.get('effect')"
                                i18n-label="@@effect" label="Effet" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="zonesOptions && this.entityForm.contains('zones')"
                                [options]="zonesOptions"
                                [control]="entityForm.get('zones')" [multiple]="true"
                                i18n-label="@@zones" label="Organes" fxFlex="100"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
          <div fxLayout="column" [fxFlex]="100 * 2 / 3">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-textarea *ngIf="entityForm.contains('comment')"
                               [control]="entityForm.get('comment')"
                               i18n-label="@@details" label="Détails" flex="100"
                               fxFlex="100"></esomus-textarea>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <!--                <esomus-delete-button *ngIf="accident.id && accident.active"-->
                <!--                                      [url]="getAccidentDeleteURL()"-->
                <!--                                      [successUrl]="getAccidentSuccessURL()">-->
                <!--                </esomus-delete-button>-->
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
