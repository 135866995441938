import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {Item} from '../../../core/models/item.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {LocalService} from '../../../core/api/local.service';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {CategoryService} from '../../../core/api/category.service';
import {ItemStatusService} from '../../../core/api/item-status.service';
import {UserService} from '../../../core/api/user.service';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {EquipmentService} from '../../../core/api/equipment.service';
import {ItemDataService} from '../../../core/api/item-data.service';

@Component({
  selector: 'esomus-local-view',
  templateUrl: './local-view.component.html',
  styleUrls: ['./local-view.component.sass']
})
export class LocalViewComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  local: Item;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private localService: LocalService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private itemStatusService: ItemStatusService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private itemDataService: ItemDataService,
  ) {
  }

  ngOnInit() {
    const localID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getLocal(localID);
  }

  private _getLocal(id: number) {
    this.localService.find(id).subscribe((local: Item) => {
      this.local = local;

      this.cd.detectChanges();
    });
  }

  editLocal() {
    this.routeNameService.goTo('local_id', {id: this.local.id});
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', {familyID: this.local.family.id, id: this.local.category.id});
  }

  getItemDescriptionURL() {
    return this.routeNameService.path('item_description_view', {id: this.local.itemDescription.id});
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.local.id, formID);
  }
}
