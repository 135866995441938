import {Provider} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {SidenavItemConfig} from './sidenav-item/sidenav-item.model';

export type SidenavMenu = SidenavItemConfig[];

export const MENU_SIDENAV = 'menu_token';

export const menuProvider: Provider = {
  provide: MENU_SIDENAV,
  deps: [I18n],
  useFactory: (i18n: I18n): SidenavMenu => [
    // =====================================================================================================
    // == DASHBOARD
    // =====================================================================================================
    {
      name: i18n({value: 'Dashboard', id: 'dashboard'}),
      icon: 'speed',
      routeName: 'dashboard_view',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == INFORMATION
    // =====================================================================================================
    {
      name: i18n({value: 'Information', id: 'information'}),
      icon: 'notification_important',
      routeName: 'dashboard_information',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == INFORMATION
    // =====================================================================================================
    {
      name: i18n({value: 'Entreprises', id: 'companies'}),
      icon: 'apartment',
      routeName: 'companies',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == FAMILY
    // =====================================================================================================
    {
      name: i18n({value: 'Familles', id: 'families'}),
      icon: 'folder',
      routeName: 'families',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == PERSON
    // =====================================================================================================
    {
      name: i18n({value: 'Personnes', id: 'persons'}),
      icon: 'perm_contact_calendar',
      routeName: 'persons',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == TASK
    // =====================================================================================================
    {
      name: i18n({value: 'Tâches', id: 'tasks'}),
      icon: 'list',
      routeName: 'tasks',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == PROGRAMMING
    // =====================================================================================================
    {
      name: i18n({value: 'Programmation', id: 'programmings'}),
      icon: 'calendar_today',
      routeName: 'programmings',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == ACCIDENT
    // =====================================================================================================
    {
      name: i18n({value: 'Accidents', id: 'accidents'}),
      icon: 'warning',
      routeName: 'accidents',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == PARAMETERS
    // =====================================================================================================
    {
      name: i18n({value: 'Paramètres', id: 'params'}),
      icon: 'settings',
      subItems: [
        {
          name: i18n({value: 'Listes', id: 'lists'}),
          icon: 'menu',
          routeName: 'lists',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Formulaires', id: 'forms'}),
          icon: 'assignment',
          routeName: 'forms',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Types de document', id: 'documentType'}),
          icon: 'description',
          routeName: 'document_types',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Gestion documentaire', id: 'documentManagement'}),
          icon: 'library_books',
          routeName: 'documents',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Utilisateurs', id: 'users'}),
          icon: 'account_circle',
          routeName: 'users',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Types d\'anomalie', id: 'anomalyType'}),
          icon: 'error',
          routeName: 'anomaly_types',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Types de tâche', id: 'taskType'}),
          icon: 'list',
          routeName: 'task_types',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Types d\'obligation', id: 'obligationType'}),
          icon: 'bookmarks',
          routeName: 'obligation_types',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Obligations', id: 'obligations'}),
          icon: 'class',
          routeName: 'obligations',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Roles', id: 'roles'}),
          icon: 'supervised_user_circle',
          routeName: 'roles',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Types d\'accident', id: 'accidentType'}),
          icon: 'warning',
          routeName: 'accident_types',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Impact', id: 'impact'}),
          icon: 'healing',
          routeName: 'impacts',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Zone', id: 'zone'}),
          icon: 'accessibility',
          routeName: 'zones',
          subItems: [],
          position: null
        },
        {
          name: i18n({value: 'Effet', id: 'effect'}),
          icon: 'flare',
          routeName: 'effects',
          subItems: [],
          position: null
        },
      ],
      position: null
    }
  ]
};
