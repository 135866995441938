import {ChangeDetectorRef, Component, EventEmitter, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {ActivatedRoute, Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RouteNameService} from '../../../core/services/route-name.service';
import {DocumentService} from '../../../core/api/document.service';
import {of} from 'rxjs';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {DocumentTypeService} from '../../../core/api/document-type.service';
import {DocumentTypeHelper} from '../../../core/services/document-type-helper.service';
import {Document} from '../../../core/models/document.model';

@Component({
  selector: 'esomus-document-management',
  templateUrl: './document-management.component.html',
  styleUrls: ['./document-management.component.sass']
})
export class DocumentManagementComponent implements OnInit {
  documentsTable: TableOptions;
  documentTableEmitter: EventEmitter<any> = new EventEmitter<any>();

  currentDocumentTypeID: number;

  @ViewChild('tableHeader', { static: true }) tableHeader: TemplateRef<any>;
  searchForm: FormGroup;
  documentTypeOptions: EntityOption;

  currentDocumentID: number;
  currentDocumentActive: boolean;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private cd: ChangeDetectorRef,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
  ) {
  }

  ngOnInit() {
    this.currentDocumentActive = true;

    this.searchForm = this.fb.group({
      documentType: [null]
    });

    this.documentTypeOptions = {
      get: () => this.documentTypeService.findAll('', {type: 'description'}), propName: 'label'
    };
    this.documentsTable = {
      columnDefs: [
        {
          prop: 'documentType.group', width: '10%',
          name: this.i18n({value: 'Groupe', id: 'group'})
        },
        {
          prop: 'documentType.label', width: '20%',
          name: this.i18n({value: 'Catégorie', id: 'category'})
        },
        {
          prop: 'label', width: '25%',
          name: this.i18n({value: 'Libellé', id: 'label'})
        },
        {
          prop: 'updatedAt', width: '10%', type: ColumnType.DATE,
          name: this.i18n({value: 'MàJ', id: 'updatedAt'})
        },
        {
          prop: 'version', width: '10%',
          name: this.i18n({value: 'Version', id: 'version'})
        },
        {
          prop: 'documentType.unique', width: '5%', type: ColumnType.CHECKBOX,
          name: this.i18n({value: 'Unique', id: 'unique'})
        },
        {
          prop: 'documentType.legal', width: '5%', type: ColumnType.CHECKBOX,
          name: this.i18n({value: 'Légal', id: 'legal'})
        },
        {
          prop: 'documentType.type', width: '5%', valueCb: (doc: Document) => DocumentTypeHelper.getTypeLabel(doc.documentType),
          name: this.i18n({value: 'Type', id: 'type'})
        },
        {
          prop: 'documentType.duration', width: '5%',
          name: this.i18n({value: 'Durée', id: 'duration'})
        },
      ],
      findDataCb: (searchData: Params) => {
        this.currentDocumentID = null;
        let params = {};
        if (!this.currentDocumentActive) {
          params['active'] = false;
        }
        return this.currentDocumentTypeID ? this.documentService.management(this.currentDocumentTypeID, params) : of([]);
      },
      actions: {
        readCb: (entity: Entity) => this.documentService.preview(entity.id),
        custom: [
          {
            icon: 'refresh',
            customCb: (entity: Entity) => {
              this.currentDocumentID = null;
              this.cd.detectChanges();
              this.currentDocumentID = entity.id;
              this.cd.detectChanges();
            }
          },
        ],
        columnWidth: '5%',
      },
      headTemplate: this.tableHeader
    };
  }

  submitSearch() {
    this.currentDocumentTypeID = parseInt(this.searchForm.get('documentType').value, 10);

    this.documentTableEmitter.emit();
    this.cd.detectChanges();
  }

  documentActiveChange(event: MatCheckboxChange) {
    this.currentDocumentActive = event.checked;

    this.documentTableEmitter.emit();
    this.cd.detectChanges();
  }
}
