import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType, FieldType as FormFieldType} from '../../../shared/form-builder/form-builder.component';
import {Programming} from '../../../core/models/programming.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {ProgrammingService} from '../../../core/api/programming.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {of} from 'rxjs';
import {TaskService} from '../../../core/api/task.service';
import {PersonService} from '../../../core/api/person.service';
import {TaskHelper} from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-programming',
  templateUrl: './programming.component.html',
  styleUrls: ['./programming.component.sass']
})
export class ProgrammingComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FormFieldType;
  programming: Programming;

  personOptions: EntityOption;
  unitOptions: EntityOption;
  taskOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private programmingService: ProgrammingService,
    private taskService: TaskService,
    private personService: PersonService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((routeParams) => {
      this.entityForm = this.fb.group({
        description: [null],
        pivotDate: [null],
        unit: [null],
        frequence: [null],
        forceDay: [null],
        anticipation: [null],
        task: [null],
        person: [null]
      });

      this.unitOptions = {
        get: () => of(TaskHelper.getProgrammingFrequenceOptions()), propName: 'label'
      };
      this.taskOptions = {get: () => this.taskService.findAllDefinition(), propName: 'description'};
      this.personOptions = {get: () => this.personService.findAll(), propName: 'fullName'};

      const programmingID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
      if (!isNaN(programmingID)) {
        this._getProgramming(programmingID);
      } else {
        this.programming = new Programming();
      }
    });
  }

  private _getProgramming(programmingID: number) {
    this.programmingService.find(programmingID)
      .subscribe((programming: Programming) => {
        this.programming = programming;

        FormHelper.initValues(this.programming, this.entityForm);

        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.programming, this.entityForm, {
      pivotDate: {type: FieldType.DATE}
    }) as Programming;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.programmingService.put(entity) : this.programmingService.post(entity)),
      (result: Programming) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        if (this.programming.id) {
          this.routeNameService.goTo('programming_view', {id: result.id});
        } else {
          this.routeNameService.goTo('programming_view', {id: result.id});
        }
      }
    );
  }
}
