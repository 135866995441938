import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ObligationType} from '../../../core/models/obligation-type.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {ObligationTypeService} from '../../../core/api/obligation-type.service';
import {FormHelper} from '../../../core/services/form-helper.service';

@Component({
  selector: 'esomus-obligation-type',
  templateUrl: './obligation-type.component.html',
  styleUrls: ['./obligation-type.component.sass']
})
export class ObligationTypeComponent implements OnInit {
  obligationType: ObligationType;

  entityForm: FormGroup;
  fieldType = FieldType;
  formOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private obligationTypeService: ObligationTypeService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      label: [null],
    });

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getObligationType(id);
    } else {
      this.obligationType = new ObligationType();
    }
  }

  private _getObligationType(id: number) {
    this.obligationTypeService.find(id).subscribe((result: ObligationType) => {
      this.obligationType = result;

      FormHelper.initValues(this.obligationType, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.obligationType, this.entityForm, {}) as ObligationType;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.obligationType.id) ? this.obligationTypeService.put(entity) : this.obligationTypeService.post(entity),
      (result: ObligationType) => {
        this.toastrService.open((this.obligationType.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('obligation_types');
      }
    );
  }

  getObligationTypeDeleteURL() {
    return this.obligationTypeService.delete(this.obligationType.id);
  }

  getObligationTypeSuccessURL() {
    return this.routeNameService.path('obligation_types');
  }
}
