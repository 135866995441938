import {Component, OnInit} from '@angular/core';
import {TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ProgrammingService} from '../../../core/api/programming.service';
import {Entity} from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-programming-list',
  templateUrl: './programming-list.component.html',
  styleUrls: ['./programming-list.component.sass']
})
export class ProgrammingListComponent implements OnInit {

  programmingTable: TableOptions;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private programmingService: ProgrammingService,
  ) {
  }

  ngOnInit() {
    this.programmingTable = {
      columnDefs: [
        {
          name: this.i18n({value: 'Description', id: 'description'}),
          prop: 'description',
        },
      ],
      findDataCb: () => this.programmingService.findAll(),
      actions: {
        create: [
          {
            btnName: 'Ajouter une programmation',
            createCb: () => {
              this.routeNameService.goTo('programming_add');
            }
          },
        ],
        readCb: (entity: Entity) => this.routeNameService.goTo('programming_view', {id: entity.id}),
      }
    };
  }

}
