import {Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {CRUDType} from 'src/app/core/api/crud';
import {FormControlCheck} from '../form-builder.component';

export abstract class FormField implements OnChanges {

  @Input() label: string;
  @Input() value: any;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() visible: boolean;
  @Input() control: FormControl|AbstractControl;
  @Input() flex = 'initial';
  @Input() autofocus = false;

  constructor() {
    this.required = false;
    this.disabled = false;
    this.visible = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required) {
      if (this.required) {
        this.control.setValidators([Validators.required]);
      } else {
        this.control.clearValidators();
      }
    }
  }
}

/**
 * Field options
 * crud: override disabled & required on crud rights
 */
export class FormFieldOptions {
  label?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  visible?: boolean;
  value?: any;
  /**
   * Subscribe to valueChanges of FormControl
   */
  valueChangeCb?: (value: any, isVisible: FormControlCheck) => void;
  crud?: CRUDField;
}

/**
 * Allows you to add constraints depending on the status of the form: CREATE or UPDATE entity
 * For instance : {required: [CRUDType.CREATE]}, the field will be required only for CREATE mode and not UPDATE
 */
export class CRUDField {
  rights?: CRUDType[];
  required?: CRUDType[];
  disabled?: CRUDType[];
}
