<div class="sidenav" fxLayout="column">

  <div class="header" fxLayout="row" fxLayoutAlign="start center">

    <img class="logo" alt="logo" src="assets/img/esomus-logo.png">
    <span fxFlex></span>

    <div class="actions" fxLayout="row" fxLayoutAlign="start center">
      <button class="toggle" type="button" mat-icon-button (click)="toggleSidenavCollapse()" *ngIf="collapsed">
        <mat-icon>radio_button_unchecked</mat-icon>
      </button>
      <button class="toggle" type="button" mat-icon-button (click)="toggleSidenavCollapse()" *ngIf="!collapsed">
        <mat-icon>radio_button_checked</mat-icon>
      </button>
    </div>
  </div>

  <esomus-scrollbar class="menu" fxFlex>

    <!-- <div class="heading" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="name">Navigation</div>
    </div> -->

    <div class="sidenav-item-holder" *ngFor="let item of sidenavItems">
      <esomus-sidenav-item class="sidenav-item-container" [item]="item">
      </esomus-sidenav-item>
    </div>

  </esomus-scrollbar>

</div>
