import {Entity} from './entity.model';
import {Form} from './form.model';
import {Person} from './person.model';
import {TaskType} from './task-type.model';
import {TaskScope} from './task-scope.model';

export class Task extends Entity {
  person: Person;
  taskType: TaskType;
  description: string;
  definition: boolean;
  generated: boolean;
  status: number;
  comment: string;
  emergency: number;
  deadlineDate: string;
  executionDate: string;
  form: Form;
  taskScopes: Array<TaskScope>;

  nbActions: number;
}
