import {Component, EventEmitter, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColumnType, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {TaskTypeService} from '../../../core/api/task-type.service';
import {FormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'esomus-task-type-list',
  templateUrl: './task-type-list.component.html',
  styleUrls: ['./task-type-list.component.sass']
})
export class TaskTypeListComponent implements OnInit {
  taskTypeTable: TableOptions;

  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();

  entityForm: FormGroup;

  constructor(
    private taskTypeService: TaskTypeService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.taskTypeTable = {
      columnDefs: [
        {
          prop: 'label',
          name: this.i18n({value: 'Libellé', id: 'label'})
        },
        {
          prop: 'anomaly', type: ColumnType.CHECKBOX,
          name: this.i18n({value: 'Anomalie', id: 'anomaly'})
        },
      ],
      findDataCb: (searchData: Params) => {
        if (searchData['anomaly']) {
          return this.taskTypeService.findAllAnomaly();
        } else {
          return this.taskTypeService.findAllStd();
        }
      },
      actions: {
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un type de tâche', id: 'btnAddTaskForm'}),
            createCb: () => this.routeName.goTo('task_type_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('task_type_id', {id: entity.id});
        },
      },
      headTemplate: this.headerTable
    };
  }

  anomalyChange(event: MatCheckboxChange) {
    this.search.emit({
        anomaly: event.checked
    });
  }
}
