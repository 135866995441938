import {ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ItemType} from '../../../core/models/item-type.model';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {Item} from '../../../core/models/item.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ItemTypeService} from '../../../core/api/item-type.service';
import {CategoryService} from '../../../core/api/category.service';
import {FamilyService} from '../../../core/api/family.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {EquipmentService} from '../../../core/api/equipment.service';
import {ItemDescriptionService} from '../../../core/api/item-description.service';
import {of} from 'rxjs';
import {FormHelper} from '../../../core/services/form-helper.service';
import {Entity} from '../../../core/models/entity.model';
import {Person} from '../../../core/models/person.model';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'esomus-equipment-create-modal',
  templateUrl: './equipment-create-modal.component.html',
  styleUrls: ['./equipment-create-modal.component.scss']
})
export class EquipmentCreateModalComponent implements OnInit {
  @ViewChild('formModal', { static: true }) formModal: TemplateRef<any>;
  formModalRef: MatDialogRef<EquipmentCreateModalComponent>;

  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;
  itemTypes: Array<ItemType>;

  itemTypeOptions: EntityOption;
  familyOptions: EntityOption;
  categoryOptions: EntityOption;
  itemDescriptionOptions: EntityOption;

  equipment: Item;
  @Input() assignedTo: Person;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private itemDescriptionService: ItemDescriptionService
  ) {
  }

  ngOnInit() {
    this.equipment = new Item();

    this.entityForm = this.fb.group({
      description: [],
      itemType: [null, [Validators.required]],
    });

    if (this.assignedTo) {
      this.equipment.assignedTo = this.assignedTo;
    }

    this.itemTypeService.findAllEquipments().subscribe((result: Array<ItemType>) => {
      this.itemTypes = result;

      this.itemTypeOptions = {get: () => of(this.itemTypes), propName: 'label'};
      this.entityForm.addControl('itemType', this.fb.control(null));

      this.cd.detectChanges();
    });

    this.formModalRef = this.dialog.open(this.formModal);
  }

  changeItemType() {
    const itemValueID = this.entityForm.get('itemType').value;

    if (this.entityForm.contains('family')) {
      this.entityForm.removeControl('family');
    }
    this.familyOptions = null;

    this.cd.detectChanges();

    if (!itemValueID) {
      this.itemType = null;
    } else {
      this.itemType = this.itemTypes.find((itemType: ItemType) => {
        return itemType.id === itemValueID;
      });
    }

    this.updateFamily();
    this.cd.detectChanges();
  }

  updateFamily() {
    if (this.entityForm.contains('category')) {
      this.entityForm.removeControl('category');
    }
    this.categoryOptions = null;
    this.cd.detectChanges();

    if (!this.itemType) {
      this.updateCategory();
      return;
    }

    this.cd.detectChanges();

    this.entityForm.addControl('family', this.fb.control(null, [Validators.required]));
    this.familyOptions = {get: () => this.familyService.findAllByItemItem(this.itemType.value), propName: 'label', autoSelect: true};

    this.cd.detectChanges();

    this.updateCategory();
  }

  updateCategory() {
    if (this.entityForm.contains('itemDescription')) {
      this.entityForm.removeControl('itemDescription');
    }
    this.itemDescriptionOptions = null;
    this.cd.detectChanges();

    const familyID = this.entityForm.get('family') ? this.entityForm.get('family').value : null;
    if (!familyID) {
      this.updateItemCategory();
      return;
    }

    this.cd.detectChanges();

    this.entityForm.addControl('category', this.fb.control(null, [Validators.required]));
    this.categoryOptions = {get: () => this.categoryService.findAllByParent(familyID), propName: 'label', autoSelect: true};

    this.cd.detectChanges();

    this.updateItemCategory();
  }

  updateItemCategory() {
    const categoryID = this.entityForm.get('category') ? this.entityForm.get('category').value : null;

    if (!categoryID) {
      return;
    }

    this.cd.detectChanges();

    this.entityForm.addControl('itemDescription', this.fb.control(null, [Validators.required]));
    this.itemDescriptionOptions = {get: () => this.itemDescriptionService.findByCategory(categoryID), propName: 'label', autoSelect: true};

    this.cd.detectChanges();
  }

  confirm() {
    this._submit();
  }

  closeModal() {
    this.formModalRef.close();
    this.formModalRef = null;
  }

  private _submit() {
    if (this.entityForm.invalid) {
      return;
    }

    this.closeModal();

    const entity = FormHelper.buildEntity(this.equipment, this.entityForm, {}) as Item;

    let parent = null;
    let params = {};

    if (this.assignedTo) {
      parent = this.assignedTo;
      params = {parentType: 'person'};
    }

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.equipmentService.postFromParent(entity, parent.id, params),
      (result: Entity) => {
        this.toastrService.open(ToastrMessageType.CREATE);
        this.routeNameService.goTo('equipment_id', {id: result.id});
      }
    );
  }
}
