import {Component, Input, OnInit} from '@angular/core';
import {ToastrMessageType, ToastrService} from '../../../../core/services/toastr.service';
import {Observable} from 'rxjs';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {Router} from '@angular/router';

@Component({
  selector: 'esomus-enable-button',
  templateUrl: './enable-button.component.html',
  styleUrls: ['./enable-button.component.sass']
})
export class EnableButtonComponent implements OnInit {

  @Input() url: Observable<any>;
  @Input() successUrl: string;
  @Input() label: string;

  constructor(
    private i18n: I18n,
    private router: Router,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    if (!this.label) {
      this.label = 'Réactiver';
    }
  }

  click() {
    this.url.subscribe(
      () => this.router.navigate([this.successUrl]),
      (response: Response) => {
        this.toastrService.open(ToastrMessageType.DELETE_ERROR);
      }
    );
  }
}
