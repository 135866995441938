import {Component, Input, OnInit} from '@angular/core';
import {Item} from '../../../../core/models/item.model';

@Component({
  selector: 'esomus-line-membership',
  templateUrl: './line-membership.component.html',
  styleUrls: ['./line-membership.component.sass']
})
export class LineMembershipComponent implements OnInit {

  @Input() item: Item;
  @Input() showAll: boolean;

  constructor() {
  }

  ngOnInit() {
  }
}
