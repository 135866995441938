import {Entity} from './entity.model';
import {Person} from './person.model';
import {Task} from './task.model';

export class TaskAssignment extends Entity {
  task: Task;
  person: Person;
  comment: string;
  startDate: string;
  endDate: string;
}
