import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Programming} from '../models/programming.model';

const crudPath: CRUDPath = {
  many: `/programmings/`,
  single: (id: number) => `/programmings/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ProgrammingService extends CRUD<Programming>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
