import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Impact} from '../models/impact.model';

const crudPath: CRUDPath = {
  many: `/impacts/`,
  single: (id: number) => `/impacts/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ImpactService extends CRUD<Impact> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }
}
