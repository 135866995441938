import {Component, OnInit} from '@angular/core';
import {ColumnSortDirection, TableOptions} from '../../../shared/form-builder/components/table/table.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Params} from '@angular/router';
import {Entity} from '../../../core/models/entity.model';
import {ObligationService} from '../../../core/api/obligation.service';

@Component({
  selector: 'esomus-obligation-view',
  templateUrl: './obligation-view.component.html',
  styleUrls: ['./obligation-view.component.sass']
})
export class ObligationViewComponent implements OnInit {

  obligationsTable: TableOptions;

  constructor(
    private obligationService: ObligationService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.obligationsTable = {
      columnDefs: [
        {
          prop: 'label', width: '45%',
          name: this.i18n({value: 'Libellé', id: 'label'})
        },
        {
          prop: 'obligationType.label', width: '25%',
          name: this.i18n({value: 'Type', id: 'type'})
        },
        {
          prop: 'category.label', width: '25%',
          name: this.i18n({value: 'Catégorie', id: 'category'})
        },
      ],
      findDataCb: (searchData: Params) => this.obligationService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter une obligation', id: 'btnAddObligation'}),
            createCb: () => this.routeName.goTo('obligation_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('obligation_id', {id: entity.id});
        },
      },
      defaultSortActive: 'label',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }

}
