import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Family} from '../../../core/models/family.model';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {RouteNameService} from '../../../core/services/route-name.service';
import {Category} from '../../../core/models/category.model';
import {CategoryService} from '../../../core/api/category.service';

@Component({
  selector: 'esomus-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.sass'],
})
export class CategoryListComponent implements OnInit {

  @Input() family: Family;
  categories: Category[];

  constructor(
    private i18n: I18n,
    private routeName: RouteNameService,
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    this._getCategories();
  }

  private _getCategories() {
    this.categoryService.findAllByParent(this.family.id).subscribe((result: Category[]) => {
      this.categories = result;
      this.cd.detectChanges();
    });
  }

  createCategory() {
    this.routeName.goTo('category_add', {familyID: this.family.id});
  }
}
