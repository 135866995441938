import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Logger} from './logger.service';
import {ToastrMessageType, ToastrService} from './toastr.service';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
  ) {
  }

  /**
   * GET
   * @param url string
   * @param params? Params
   */
  get<K>(url: string, params?: Params): Observable<K> {
    if (params) {
      return this.http.get<K>(url, {params})
        .pipe(
          tap((result: K) => {
            Logger.log(`====================`);
            Logger.log(`GET ${url} : `, result);
            Logger.log(`Params : `, params);
          }),
          catchError(this._handleError)
        );
    }
    return this.http.get<K>(url)
      .pipe(
        tap((result: K) => {
          Logger.log(`====================`);
          Logger.log(`GET ${url} : `, result);
        }),
        catchError(this._handleError)
      );
  }

  /**
   * GET Blob file
   */
  getBlob(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        tap((result: HttpResponse<Blob>) => {
          Logger.log(`====================`);
          Logger.log(`GET ${url} : File Type => `, result.type);
        }),
        catchError(this._handleError)
      );
  }

  /**
   * POST
   * @param url string
   * @param body any
   * @param params Params
   */
  post<K>(url: string, body: any, params?: Params): Observable<K> {
    return this.http.post<K>(url, body, {params})
      .pipe(
        tap((result: K) => {
          Logger.log(`====================`);
          Logger.log(`POST ${url} : `, result);
          Logger.log(`BODY : `, body);
        }),
        catchError(this._handleError)
      );
  }

  /**
   * PUT
   * @param url string
   * @param body any
   */
  put<K>(url: string, body: any): Observable<K> {
    return this.http.post<K>(url, body)
      .pipe(
        tap((result: K) => {
          Logger.log(`====================`);
          Logger.log(`PUT ${url} : `, result);
          Logger.log(`BODY : `, body);
        }),
        catchError(this._handleError)
      );
  }

  /**
   * DELETE
   * @param url string
   */
  delete<K>(url: string): Observable<K> {
    return this.http.delete<K>(url)
      .pipe(
        tap(() => {
          Logger.log(`====================`);
          Logger.log(`DELETE ${url} : OK`);
        }),
        catchError(this._handleError)
      );
  }

  private _handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 403:
        this.toastrService.open(ToastrMessageType.ACCESS_DENIED);
        break;
    }

    if (error.error) {
      Logger.error(error.error.message || error.message || error.statusText);
      return throwError(error.error);
    }
    return throwError(error.message || error.statusText);
  }
}
