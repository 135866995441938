<div *ngIf="screenID" fxLayoutGap="5px" fxLayoutAlign="center center">
  <esomus-ng-select *ngIf="printOptions" [options]="printOptions"
                    [control]="entityForm.get('print')" fxFlex
                    (valueChange)="triggerButton()" label="Rapport d'impression"
  ></esomus-ng-select>
  <div>
    <button *ngIf="showBtn" type="button" color="primary" mat-flat-button
            (click)="updatePrint()"><mat-icon>print</mat-icon>
    </button>
  </div>
</div>
