import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {FileInput} from 'ngx-material-file-input';
import {Observable, Subscription} from 'rxjs';
import {CRUDType} from 'src/app/core/api/crud';
import {Entity} from 'src/app/core/models/entity.model';
import {FormEvent, FormEventType, FormService} from 'src/app/shared/form-builder/services/form.service';
import {FormFieldOptions} from './components/form-field';
import {FileField} from './components/input-file/input-file.component';
import {InputField} from './components/input/input.component';
import {EntityField} from './components/ng-select/ng-select.component';
import {SelectField} from './components/select/select.component';
import {TextareaField} from './components/textarea/textarea.component';
import {Logger} from 'src/app/core/services/logger.service';

@Component({
  selector: 'esomus-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.sass']
})
export class FormBuilderComponent implements OnInit, OnDestroy {

  @Input() formTitle: string;
  @Input() formTitleIcon: string;
  /**
   * Default: true, if modal = false
   */
  @Input() visible: boolean;
  /**
   * Default: false
   */
  @Input() modal: boolean;
  @Input() options: FormOptions;
  @Output() submit: EventEmitter<FormSubmissionEvent>;

  @ViewChild('formModal', { static: true }) formModal: TemplateRef<any>;

  form: FormGroup;
  formSubmitted: boolean;
  modalRef: MatDialogRef<FormBuilderComponent>;

  crudType: CRUDType;

  isRequired: FormControlCheck;
  isVisible: FormControlCheck;

  private subscriptions: Subscription;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private i18n: I18n,
  ) {
    this.submit = new EventEmitter<FormSubmissionEvent>();
    this.subscriptions = new Subscription();
    this.modal = false;
    this.isRequired = {};
    this.isVisible = {};
  }

  ngOnInit() {
    if (!this.options) {
      Logger.error('The options for FormBuilderComponent are undefined.');
      return;
    }
    //
    // == FIELDS : Create FormGroup
    this.form = this.fb.group({});
    this._switchCRUD(CRUDType.CREATE);
    const fields = this.options.fields;
    for (const field of fields) {

      this.isVisible[field.name] = true;
      if (field.visible === false) {
        this.isVisible[field.name] = false;
      }

      // = Create FormControl
      this.form.addControl(field.name, this.fb.control((field.value) ? field.value : null));
      // = EVENT : Value Changes
      if (field.valueChangeCb) {
        this.subscriptions.add(this.form.get(field.name).valueChanges.subscribe((value: any) => {
          field.valueChangeCb(value, this.isVisible);
        }));
      }
    }

    //
    // == Default Form VISIBILITY
    if (typeof this.visible !== 'boolean' && !this.modal) {
      this.visible = true;
    }

    //
    // == Default SUBMIT IS VISIBLE
    if (typeof this.options.submitIsVisible !== 'boolean') {
      this.options.submitIsVisible = true;
    }

    //
    // == Default MODE
    if (!this.options.mode) {
      this.options.mode = FormModeType.CRUD;
    }

    // if CRUD, then add ID property
    if (this.options.mode === FormModeType.CRUD) {
      this.form.addControl('id', this.fb.control(null));
    }

    if (!this.formTitleIcon && this.options.mode === FormModeType.SEARCH) {
      this.formTitleIcon = 'search';
    }

    //
    // == Default SUBMIT LABEL
    if (!this.options.submitLabel) {
      if (!this.options.mode || this.options.mode === FormModeType.SEARCH) {
        this.options.submitLabel = this.i18n({ value: 'Rechercher', id: 'search' });
      }
    }

    //
    // == FORM SERVICE : Events
    this.formService.catchForm(this.options.id).subscribe((event: FormEvent) => {
      switch (event.eventType) {
        case FormEventType.CREATE:
          this.resetForm(true);
          this._switchCRUD(CRUDType.CREATE);
          break;
        case FormEventType.UPDATE:
          this.resetForm(true);
          this._switchCRUD(CRUDType.UPDATE);
          if (event.data) {
            for (const name in this.form.controls) {
              if (this.form.controls.hasOwnProperty(name) && event.data.hasOwnProperty(name)) {
                if (event.data[name] && typeof event.data[name] === 'object' && event.data[name].hasOwnProperty('id')) {
                  this.form.controls[name].setValue(event.data[name].id);
                } else {
                  this.form.controls[name].setValue(event.data[name]);
                }
              }
            }
          }
          break;
        case FormEventType.REQUEST_DATA:
          if (!this.visible) {
            // Form not visible => empty Entity (data hidden)
            this.formService.sendFormData(this.options.id, new Entity());
            return;
          }
          if (!this._isFormValid()) {
            // Not valid => null
            this.formService.sendFormData(this.options.id, null);
            return;
          }
          let data = this._createEntity();
          this.formService.sendFormData(this.options.id, data);
          break;
        case FormEventType.RESPONSE_ERROR:
          this.formSubmitted = false;
          // Add custom errors
          if (event.data.errors && event.data.errors.children) {
            const errors = event.data.errors.children;
            for (const property in errors) {
              if (errors.hasOwnProperty(property)) {
                const error = errors[property];
                if (error.hasOwnProperty('errors') && Array.isArray(error.errors)) {
                  this.form.controls[property].setErrors({
                    custom: error.errors[0]
                  });
                }
              }
            }
          }
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe valueChanges events
    this.subscriptions.unsubscribe();
  }

  resetForm(isVisible: boolean = false) {
    this.form.reset();
    this.formSubmitted = false;
    const fields = this.options.fields;
    for (const field of fields) {
      this.form.get(field.name).setValue((field.value) ? field.value : null);
    }
    if (this.options.mode === FormModeType.SEARCH) {
      this.formService.sendFormData(this.options.id);
    }

    if (isVisible && this.modal) {
      this.modalRef = this.dialog.open(this.formModal);
    } else if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }
    this.visible = isVisible;
  }

  submitForm(form: NgForm) {
    if (!form.submitted) {
      return;
    }

    if (!this._isFormValid()) {
      return;
    }
    let data = this._createEntity();

    this.formSubmitted = true;

    // if CRUD mode, then POST or PUT entity
    if (this.options.mode !== FormModeType.SEARCH && this.options.submitCb && typeof this.options.submitCb === 'function') {
      this.options.submitCb(data, this.crudType).subscribe(() => {
        this.formService.toastrSuccess(this.crudType);
        this.formService.sendFormData(this.options.id, data);
        this.resetForm();
      }, (err) => {
        this.formService.sendFormError(this.options.id, err);
      });

    } else { // if SEARCH mode
      this.formService.sendFormData(this.options.id, data);
      this.formSubmitted = false;
    }
  }

  private _switchCRUD(crudType: CRUDType) {
    this.crudType = crudType;
    for (const field of this.options.fields) {

      this.isRequired[field.name] = field.required === true;
      if (field.crud && field.crud.required) {
        if (field.crud.required.includes(this.crudType)) {
          this.isRequired[field.name] = true;
        }
      }
    }
  }

  /**
   * Data validation
   */
  private _isFormValid(): boolean {
    // Form invalid OR custom validator
    if (this.form.invalid || this.options.validatorFn && typeof this.options.validatorFn === 'function'
      && !this.options.validatorFn(this.form)) {
      return false;
    }
    return true;
  }

  /**
   * Create Entity with the form data
   */
  private _createEntity(): Entity {
    let controls = this.form.controls;
    let entity = new Entity();

    if (this.options.mode === FormModeType.CRUD) {
      entity.id = controls['id'].value;
    }

    for (let field of this.options.fields) {
      const value = controls[field.name].value;
      let data = entity;

      if (controls.hasOwnProperty(field.name) && value !== null && value !== '' && value !== -1) {

        //
        // == Create property
        const properties = field.name.split('.');

        for (let i = 0; i < properties.length; i++) {
          const property = properties[i];
          if (data[property] === undefined && i + 1 < properties.length) {
            data[property] = {};
            data = data[property];
          }

          if (i + 1 === properties.length) {
            // Add value in property
            switch (field.type) {
              case FieldType.DATE:
                data[property] = new Date(value);
                break;
              case FieldType.FILE:
                const fileInput: FileInput = value;
                if (field.multiple) {
                  data[property] = fileInput.files;
                } else if (fileInput.files.length) {
                  data[property] = fileInput.files[0];
                }
                break;
              default:
                data[property] = value;
            }
          }
        }
      }
    }
    return entity;
  }
}

/**
 * Main options
 */
export class FormOptions {
  /**
   * form id to subscribe for the form service
   */
  id: string;
  /**
   * form mode: CRUD (default) or SEARCH form (Search form has clear button & submits through FormService for a DataTable)
   */
  mode?: FormModeType;
  /**
   * if submitLabel is empty => CRUD Mode, default label is "Sauver", else "Rechercher"
   */
  submitLabel?: string;
  /**
   * Default: true, Toggle visibility for 'submit' button
   */
  submitIsVisible?: boolean;
  /**
   * list of fields & their configuration
   */
  fields: (SelectField | InputField | EntityField | CheckboxField | HiddenField | TextareaField | FileField)[];
  /**
   * Function called before the submission of the form
   */
  validatorFn?: (group: FormGroup) => boolean;
  /**
   * Observable subscribed to submit the form
   * - crudType: is CREATE or UPDATE form ?
   */
  submitCb?: (entity: Entity, crudType: CRUDType) => Observable<any>;
}

/**
 * <input type="checkbox">
 */
export class CheckboxField extends FormFieldOptions {
  type: FieldType.CHECKBOX;
}

/**
 * <input type="hidden">
 */
export class HiddenField extends FormFieldOptions {
  type: FieldType.HIDDEN;
}


//
// == EVENTS
//

export class FormSubmissionEvent {
  controls: FormControl[];
  data: any;
}

//
// == ENUM
//

export enum FieldType {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  SELECT = 'select',
  ENTITY = 'entity',
  CHECKBOX = 'checkbox',
  HIDDEN = 'hidden',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  FILE = 'file',
  TEXTAREA = 'textarea',
  GROUP = 'group',
}

export enum FormModeType {
  SEARCH = 'search_mode',
  CRUD = 'crud_mode'
}

export class FormControlCheck {
  [name: string]: boolean;
}
