import {Entity} from './entity.model';

export class Address extends Entity {
  line1: string;
  locality: string;
  zipCode: string;
  coordinates: string;
  country: string;

  type: number;
  description: string;
  phone: string;
  mail: string;
}
