import {Component, Input, OnInit} from '@angular/core';
import {FieldType} from '../../form-builder.component';
import {FormField, FormFieldOptions} from '../form-field';

@Component({
  selector: 'esomus-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends FormField implements OnInit {

  @Input() rows: number;
  @Input() cols: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!this.rows) {
      this.rows = 5;
    }
    if (!this.cols) {
      this.cols = 20;
    }
  }
}

export class TextareaField extends FormFieldOptions {
  type: FieldType.TEXTAREA;
  rows?: number;
  cols?: number;
}

