import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {Family} from '../models/family.model';
import {environment} from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/families/`,
  single: (id: number) => `/families/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class FamilyService extends CRUD<Family>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllByItemItem(itemTypeID: number) {
    return this.http.get<Array<Family>>(`${environment.api.url}${this.crudPath.many}item_types/${itemTypeID}`);
  }
}
