<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <esomus-table [options]="personTable"></esomus-table>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #personTypeCell let-row>
  <mat-icon *ngIf="row.type === 0">person</mat-icon>
  <mat-icon *ngIf="row.type === 1">business_center</mat-icon>
  <mat-icon *ngIf="row.type === 2">people</mat-icon>
</ng-template>
