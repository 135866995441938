import {Entity} from './entity.model';
import {LightItem} from './light-item.model';
import {DocumentType} from './document-type.model';

export class Document extends Entity {
  mainId: number;
  date: string;
  results: string;
  origin: number;
  label: string;
  version: string;
  uploadedFile: File;
  documentType: DocumentType;

  company: LightItem;
  site: LightItem;
}
