import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ToastrMessageType, ToastrService} from '../../../core/services/toastr.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {ImpactService} from '../../../core/api/impact.service';
import {Impact} from '../../../core/models/impact.model';
import {AccidentHelper} from '../../../core/services/accident-helper.service';
import {of} from 'rxjs';

@Component({
  selector: 'esomus-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.sass']
})
export class ImpactComponent implements OnInit {
  impact: Impact;

  entityForm: FormGroup;
  fieldType = FieldType;
  severityOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private impactService: ImpactService,
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [null, [Validators.required, Validators.maxLength(30)]],
      severity: [null, [Validators.required]]
    });

    this.severityOptions = {get: () => of(AccidentHelper.getSeverityOptions()), propName: 'label'};

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getImpact(id);
    } else {
      this.impact = new Impact();
    }
  }

  private _getImpact(id: number) {
    this.impactService.find(id).subscribe((result: Impact) => {
      this.impact = result;

      FormHelper.initValues(this.impact, this.entityForm);
      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.impact, this.entityForm, {}) as Impact;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      (this.impact.id) ? this.impactService.put(entity) : this.impactService.post(entity),
      (result: Impact) => {
        this.toastrService.open((this.impact.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('impacts');
      }
    );
  }

  getImpactDeleteURL() {
    return this.impactService.delete(this.impact.id);
  }

  getImpactSuccessURL() {
    return this.routeNameService.path('impacts');
  }
}
