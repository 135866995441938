<mat-accordion multi="true" *ngIf="company">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Entreprise : {{company.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayoutAlign="center center">
          <button mat-flat-button (click)="editCompany()" color="success">
            <mat-icon>edit</mat-icon> Editer
          </button>
        </div>
        <esomus-dashboard-print-selector screenID="1" [itemID]="company.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="company.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50" textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="company.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data fxFlex="100" boldValue="Libellé"
                              [textValue]="company.label"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="TVA"
                              [textValue]="company.internalCode"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Date création" textType="date"
                              [textValue]="company.serviceDate"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Date achat" textType="date"
                              [textValue]="company.buyingDate"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Commentaire"
                              [textValue]="company.comment"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Catégorie"
                              [textValue]="company.category.label"
                              [textURL]="getCategoryURL()"></esomus-line-data>
            <esomus-line-data fxFlex="100" boldValue="Fiche"
                              [textValue]="company.itemDescription.label"
                              [textURL]="getItemDescriptionURL()"></esomus-line-data>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Site : {{nbSites}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="sitesTable" (getLength)="updateNbSites($event)"
                  [options]="sitesTable"></esomus-table>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="company.category.form || company.itemDescription.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="company.category.form" [fetchDataURL]="getFetchDataURL(company.category.form.id)"></esomus-form-display>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="company.itemDescription.form" [fetchDataURL]="getFetchDataURL(company.itemDescription.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [parent]="company" parentType="item"></esomus-document-table>
  <esomus-contact-table [parent]="company" [parentType]="'item'"></esomus-contact-table>
</mat-accordion>
