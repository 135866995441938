import {Injectable} from '@angular/core';
import {CRUD, CRUDPath} from './crud';
import {HttpWrapperService} from '../services/http-wrapper.service';
import {FormField} from '../models/form-field.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

const crudPath: CRUDPath = {
  many: `/form_fields/`,
  single: (id: number) => `/form_fields/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class FormFieldService extends CRUD<FormField> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  swap(formID: number, aID: number, bID: number): Observable<any> {
    const body = new FormData();
    body.append('aID', aID.toString());
    body.append('bID', bID.toString());

    return this.http.post<any>(`${environment.api.url}/forms/${formID}/field_type/0${this.crudPath.many}swap`, body);
  }

  findByFormID(formID: number): Observable<Array<FormField>> {
    return this.findAll(`/forms/${formID}/field_type/0`);
  }
}
