import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FieldType} from '../../../shared/form-builder/form-builder.component';
import {ItemType} from '../../../core/models/item-type.model';
import {Action} from '../../../core/models/action.model';
import {EntityOption} from '../../../shared/form-builder/components/ng-select/ng-select.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ActivatedRoute} from '@angular/router';
import {RouteNameService} from '../../../core/services/route-name.service';
import {ItemTypeService} from '../../../core/api/item-type.service';
import {CategoryService} from '../../../core/api/category.service';
import {FamilyService} from '../../../core/api/family.service';
import {ToastrService} from '../../../core/services/toastr.service';
import {EquipmentService} from '../../../core/api/equipment.service';
import {AnomalyService} from '../../../core/api/anomaly.service';
import {AnomalyTypeService} from '../../../core/api/anomaly-type.service';
import {AnomalyDataService} from '../../../core/api/anomaly-data.service';
import {PersonService} from '../../../core/api/person.service';
import {FormService} from '../../../core/api/form.service';
import {ActionService} from '../../../core/api/action.service';
import {TaskTypeService} from '../../../core/api/task-type.service';
import {of} from 'rxjs';
import {TaskHelper} from '../../../core/services/task-helper.service';
import {FormHelper} from '../../../core/services/form-helper.service';
import {FormsMap, FormsMappingMap} from '../../../forms/components/form-component/form-component.component';
import {ActionDataService} from '../../../core/api/action-data.service';
import {Entity} from '../../../core/models/entity.model';

@Component({
  selector: 'esomus-action-realisation',
  templateUrl: './action-realisation.component.html',
  styleUrls: ['./action-realisation.component.sass']
})
export class ActionRealisationComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;
  itemType: ItemType;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];
  nbForms: number;

  action: Action;

  statusOptions: EntityOption;
  personOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private routeNameService: RouteNameService,
    private itemTypeService: ItemTypeService,
    private categoryService: CategoryService,
    private familyService: FamilyService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private anomalyService: AnomalyService,
    private anomalyTypeService: AnomalyTypeService,
    private anomalyDataService: AnomalyDataService,
    private personService: PersonService,
    private formService: FormService,
    private actionService: ActionService,
    private taskTypeService: TaskTypeService,
    private actionDataService: ActionDataService
  ) {
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      executionComment: [null],
      status: [null],
      executionDate: [null],
      person: [null],
      'executionPicture.upload': [null]
    });

    this.statusOptions = {
      get: () => of(TaskHelper.getStatusOptions().filter((item: Entity) => item.id !== 0)), propName: 'label'
    };
    this.personOptions = {get: () => this.personService.findAll(), propName: 'fullName'};

    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(id)) {
      this._getAction(id);
    }
  }

  private _getAction(actionID: number) {
    this.actionService.find(actionID).subscribe((result: Action) => {
      this.action = result;

      if (this.action.form || this.action.form) {
        this.forms = {};
        this.formsMapping = {};
      }

      if (this.action.executionDate === null) {
        this.action.executionDate = (new Date()).toString();
      }

      FormHelper.initValues(this.action, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    this.nbForms = 1;
    this.formsValidation = [];

    for (const key in this.forms) {
      this.nbForms++;
      if (this.forms[key].invalid) {
        return;
      }
    }

    for (const key in this.formsMapping) {
      const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});
      FormHelper.submitForm(
        this.cd,
        this.forms[key],
        this.actionDataService.putData(entityData, this.action.id, parseInt(key.substr(5), 10)),
        () => {
          this.formsValidation.push(true);
          this._handleFormsSubmit();
        }
      );
    }

    const entity = FormHelper.buildEntity(this.action, this.entityForm, {
      executionDate: {type: FieldType.DATE},
      'executionPicture.upload': {type: FieldType.FILE, multiple: false}
    }) as Action;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.actionService.putRealisation(entity),
      () => {
        this.formsValidation.push(true);
        this._handleFormsSubmit();
      }
    );
  }

  private _handleFormsSubmit() {
    if (this.formsValidation.length === this.nbForms) {
      if (this.action.task) {
        this.routeNameService.goTo('task_view', {id: this.action.task.id});
      } else {
        this.routeNameService.goTo('action_view', {id: this.action.id});
      }
    }
  }

  getFetchDataURL(formID: number) {
    return this.actionDataService.getData(this.action.id, formID);
  }

  getOrigin() {
    return TaskHelper.getOrigin(this.action);
  }

  getEmergency() {
    return TaskHelper.getLabel(this.action.emergency);
  }
}
