import {Entity} from './entity.model';

export class FormField extends Entity {
  fieldID: number;
  groupID: number;
  fieldTypeID: number;
  name: string;
  code: string;
  required: boolean;
  listID: number;
  listComponent: number;
  selectData: any;
  sequence: number;

  fullName: string;

  fieldTypeName: string;

  min: number;
  max: number;

  bold: boolean;
  bigFont: boolean;

  options: object;
}
